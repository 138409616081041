/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import PropTypes, { InferProps } from 'prop-types';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { SortingState } from '@tanstack/react-table';

import { ControlledTable } from '~/components/shared/table';
import { SEARCH } from '~/constants/filterKeysConstants';
import { clearFilters, setFilter } from '~/ducks/admin/attrsFilters';
import {
  fetchAttrValues,
  getAttrValues,
  getAttrValuesLoaded,
  getAttrValuesPageCount,
  getAttrValuesTotalRecords,
} from '~/ducks/admin/attrValues';
import { getAttrValuesFilters, getAttrValuesFiltersForRequest } from '~/ducks/admin/attrValuesFilters';
import { useModel } from '~/lib/hooks';
import { AttrValue } from '~/models';
import { useProfileContext } from '~/services/profile';

import attributeValuesTableColumns from './attributeValuesTableColumns';

function AttributeValuesTable({ ...props }: Props) {
  const profileSvc: any = useProfileContext();
  const [pagingFilters, setPagingFilters] = useState({});
  const [tableProps, setTableProps] = useState({ setSorting: (_sort: SortingState) => {} });
  const debouncedFetchAttrValues = useCallback(
    _.debounce((params: any) => props.fetchAttrValues(params), 50),
    []
  );
  const values = useModel(AttrValue, props.values);

  useEffect(() => {
    debouncedFetchAttrValues({
      ...pagingFilters,
      ...props.filtersForRequest,
      include: 'client,attr,name',
    });
  }, [pagingFilters, props.filtersForRequest]);

  const handlePagingFiltersChange = useCallback(
    (newPagingFilters: { page: number; pageSize: number; sortBy: string }) => {
      setPagingFilters(newPagingFilters);
    },
    []
  );

  const handleEditAttrValue = (attrValue: AttrValue) => {
    props.history.push(`/attributes/values/${attrValue.id}/edit`);
  };

  const columns = useMemo(
    () =>
      attributeValuesTableColumns({
        profileSvc,
        onEdit: handleEditAttrValue,
      }),
    []
  );

  const defaultSortBy = [{ id: 'name', desc: false }];
  const searchValue: any = props.filters[SEARCH as keyof typeof props.filters];

  useEffect(() => {
    const sortBy = searchValue ? [{ id: SEARCH, desc: true }] : defaultSortBy;

    tableProps.setSorting(sortBy);
  }, [searchValue]);

  // can we get generic from columns?
  return (
    <ControlledTable<AttrValue>
      data={values}
      defaultSortBy={'name asc'}
      loading={!props.loaded}
      onInitialize={setTableProps}
      columns={columns}
      filters={props.filters}
      onPagingFiltersChange={handlePagingFiltersChange}
      pageCount={props.pageCount}
    />
  );
}

AttributeValuesTable.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  fetchAttrValues: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
  filtersForRequest: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  loaded: PropTypes.bool.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  setFilter: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  totalRecords: PropTypes.number.isRequired,
  values: PropTypes.instanceOf(Array),
};

type Props = InferProps<typeof AttributeValuesTable.propTypes> & RouteComponentProps;

const mapStateToProps = (state: any) => ({
  values: getAttrValues(state),
  loaded: getAttrValuesLoaded(state),
  pageCount: getAttrValuesPageCount(state),
  filters: getAttrValuesFilters(state),
  filtersForRequest: getAttrValuesFiltersForRequest(state),
  totalRecords: getAttrValuesTotalRecords(state),
});

const mapDispatchToProps = {
  fetchAttrValues,
  clearFilters,
  setFilter,
};

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(AttributeValuesTable);
