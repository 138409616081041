/* eslint-disable react/prop-types */
import React from 'react';

const OlioOLogo = ({ fill, size }) => {
  return (
    <svg width={ size } height={ size } viewBox={ `0 0 ${ size } ${ size }` } version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Branding/Logomark'>
          <mask id='olio-mask' fill='white'>
            <path d='M17.9390769,35.1456923 C7.68876923,35.1456923 0,27.4583077 0,17.5735385 C0,7.68876923 7.68876923,0 17.9390769,0 C28.1893846,0 35.8781538,7.68876923 35.8781538,17.5735385 C35.8781538,27.4583077 28.1907692,35.1456923 17.9390769,35.1456923 Z M17.9390769,24.1629231 C21.2344615,24.1629231 24.1629231,21.2344615 24.1629231,17.5735385 C24.1629231,13.9126154 21.2344615,10.9827692 17.9390769,10.9827692 C14.6436923,10.9827692 11.7152308,13.9126154 11.7152308,17.5735385 C11.7152308,21.2344615 14.6436923,24.1629231 17.9390769,24.1629231 L17.9390769,24.1629231 Z' id='olio-path' />
          </mask>
          <g id='Mask' fillRule='nonzero' />
          <g id='Mixin/Fill/Primary' mask='url(#olio-mask)' fill={ fill }>
            <rect id='Box' x='0' y='0' width={ size } height={ size } />
          </g>
        </g>
        <g id='Section-Labels' transform='translate(-247.000000, -4298.000000)' />
      </g>
    </svg>
  );
};

export default OlioOLogo;
