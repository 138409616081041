import { immerable } from 'immer';

const defaults = {
  links: { next: null, prev: null },
  meta: { totalRecords: 0, totalPages: 0 }
};

export default class PaginationState {
  [immerable] = true;

  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.links = opts.links;
    this.meta = opts.meta;
  }
}
