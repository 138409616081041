import type { ReducedConfigs } from '~/components/clients/configureCareOptions';
import { createAsyncThunk } from '~/lib';
import { clientGroupTypesApi } from '~/services/api';

export const sliceName = 'admin/client_group_types';

export type ClientGroupTypeParams = {
  id: string;
  config: ReducedConfigs;
};

export const updateClientGroupType = createAsyncThunk(
  `${sliceName}/update`,
  async ({ id, ...params }: ClientGroupTypeParams) => {
    const res = await clientGroupTypesApi.update.invoke(id, params);

    return res.data;
  }
);
