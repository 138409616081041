import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { StandardModal } from '~/components/shared/modal';
import { Body, H3 } from '~/components/shared/typography';
import { capitalize } from '~/helpers/capitalize';

function ConfirmationModal({ onCancel, onConfirm, show, subText, actionText, objType }) {
  const text = `${capitalize(actionText)} ${capitalize(objType)}`;

  return (
    <StandardModal colorTheme='danger' title={text} show={show} onCancel={onCancel}>
      <Content>
        <H3>
          Are you sure you want to {actionText} this {objType}?
        </H3>
        {subText && <Paragraph>{subText}</Paragraph>}
      </Content>
      <Actions>
        <Button color='transparent' text='Cancel' onClick={onCancel} />
        <Button color='danger' text={text} onClick={onConfirm} data-cy='confirmButton' />
      </Actions>
    </StandardModal>
  );
}

ConfirmationModal.propTypes = {
  actionText: PropTypes.string.isRequired,
  objType: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool,
  subText: PropTypes.string,
};

export default ConfirmationModal;

const Content = styled.div`
  margin: 26px 0 50px;
  text-align: center;
`;

const Paragraph = styled(Body)`
  display: block;
`;

const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;
