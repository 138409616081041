import { useMemo } from 'react';

const useModel = (Class, data) => {
  const results = useMemo(() => {
    if (Array.isArray(data)) {
      return data.map((item) => new Class(item));
    }

    return new Class(data);
  }, [data]);

  return results;
};

export default useModel;
