import { createAsyncThunk } from '~/lib';
import { Notification } from '~/models/userPreferences';
import { notificationPreferencesApi } from '~/services/api';

const cancellableFetchNotificationPreferences = notificationPreferencesApi.fetch.cancellable();

const SLICE_NAME = 'notificationPreferences';

export const fetchNotificationPreferences = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (params: any) => {
    const res = await cancellableFetchNotificationPreferences(params);

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Notification,
  } as any
);

export const updateNotificationPreference = createAsyncThunk(
  `${SLICE_NAME}/update`,
  async (params: any) => {
    const { id, ...rest } = params;

    const res = await notificationPreferencesApi.update.invoke(id, rest);

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Notification,
  } as any
);

export const createNotificationPreference = createAsyncThunk(
  `${SLICE_NAME}/create`,
  async (params: any) => {
    const res = await notificationPreferencesApi.create.invoke(params);

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Notification,
  } as any
);
