/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  ACTIVE,
  ALL,
  ALL_OPTION,
  ATTR_DISPLAY_NAME,
  CLIENT,
  MAPPED_OLIO_FIELD,
  ONLY_INACTIVE,
  SEARCH,
} from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

export const initialState = {
  [ATTR_DISPLAY_NAME]: { displayName: ALL },
  [CLIENT]: ALL_OPTION,
  [MAPPED_OLIO_FIELD]: ALL_OPTION,
  [ONLY_INACTIVE]: false,
  [SEARCH]: '',
};

const sliceName = 'admin/attrValues/filters';

const attrValuesFiltersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFilter: (state: any, action: any) => {
      const { filterType, value } = action.payload;

      state[filterType] = value;
    },
    clearFilters: (state) => ({ ...initialState, [SEARCH]: state[SEARCH] }),
  },
  extraReducers: (builder) => {
    builder.addCase(rootPathnameChanged, () => initialState);
  },
});

export const getAttrValuesFilters = (state: any) => state[sliceName];

export const getAttrValuesFiltersForRequest = createSelector(getAttrValuesFilters, (attrValuesFilters) => {
  return Object.entries(attrValuesFilters)
    .filter(([filterKey, filter]: any) => {
      switch (filterKey) {
        case ONLY_INACTIVE:
        case SEARCH:
          return filter;
        case ATTR_DISPLAY_NAME:
          return filter.displayName && filter.displayName !== ALL;
        default:
          return filter.id;
      }
    })
    .reduce((acc, [filterKey, filter]: any) => {
      switch (filterKey) {
        case ONLY_INACTIVE:
          // If the checkbox is checked, that means retun records where
          // active=false
          return { ...acc, [ACTIVE]: `${!filter}` };
        case SEARCH:
          return { ...acc, [filterKey]: `${filter}` };
        case ATTR_DISPLAY_NAME:
          return { ...acc, [filterKey]: filter.displayName };
        default:
          return { ...acc, [filterKey]: `${filter.id}` };
      }
    }, {});
});

export const { clearFilters, setFilter } = attrValuesFiltersSlice.actions;

export default attrValuesFiltersSlice;
