import React, { Fragment } from 'react';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, FormHeader, FormSection, Input, InputGroup, SectionHeader, Select } from '~/components/shared/form';
import JSONEditor from '~/components/shared/form/JSONEditor';
import { ArrowIcon } from '~/components/shared/svg';
import { OWNER_CLIENT_TYPES } from '~/constants/clientTypes';
import { DELIMITER_OPTS, PATIENT_MAPPINGS } from '~/constants/importConfigurations';
import { fetchClients } from '~/ducks/admin/clients';
import { getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';

function ImportConfigurationForm(props) {
  const { dirty, isValid, isSubmitting, onCancel, values } = props;

  const clientsAsyncOptions = useAsyncOptions(fetchClients, {
    params: {
      sortBy: 'name asc',
      hasImportConfiguration: false,
      clientType: OWNER_CLIENT_TYPES,
    },
  });

  return (
    <Form>
      <FormHeader title={values.id ? 'Edit Import Configuration' : 'Add Import Configuration'} />
      <FormSection>
        <InputGroup
          {...clientsAsyncOptions}
          name='client'
          label='Client'
          placeholder='Client'
          disabled={values.id}
          getOptionLabel={getName}
          getOptionValue={getId}
          component={Select}
        />
      </FormSection>

      <FormSection>
        <SectionHeader>File Format</SectionHeader>
        <InputGroup label='Delimiter' name='config.delimiter' options={DELIMITER_OPTS} component={Select} />
        <InputGroup name='config.dateFormat' label='Date Format' placeholder='Date Format' component={Input} />
      </FormSection>

      <FormSection>
        <SectionHeader>Header Mapping</SectionHeader>
        <FieldArray
          name='config.properties'
          render={() => (
            <Fragment>
              {PATIENT_MAPPINGS.map(({ key, label }, index) => (
                <MappingRow key={index}>
                  <StyledInputGroup name={`config.properties[${key}]`} label='Client Header' component={Input} />
                  <IconContainer>
                    <ArrowIcon />
                  </IconContainer>
                  <StyledInputGroup name={key} label='Olio Field' component={Input} value={label} disabled />
                </MappingRow>
              ))}
            </Fragment>
          )}
        />
      </FormSection>

      <ButtonGroup>
        <Button color='transparent' text='Cancel' onClick={onCancel} />
        <Button type='submit' disabled={!dirty || !isValid || isSubmitting} text='Submit' />
      </ButtonGroup>

      <StyledFormSection>
        <SectionHeader>Raw Config</SectionHeader>
        <InputGroup component={StyledJSONEditor} disabled name='rawConfig' />
      </StyledFormSection>
    </Form>
  );
}

ImportConfigurationForm.propTypes = {
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  values: PropTypes.instanceOf(Object),
};

export default ImportConfigurationForm;

const MappingRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledInputGroup = styled(InputGroup)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
`;

const StyledJSONEditor = styled(JSONEditor)`
  max-height: inherit;
`;

const StyledFormSection = styled(FormSection)`
  margin-top: 48px;
`;
