import { Api } from './core';

const url = '/admin/discharge_reasons';
const { create, fetch, fetchById, update } = new Api({ url });

export default {
  create,
  fetch,
  fetchById,
  update,
};
