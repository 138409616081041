/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useField from '~/lib/hooks/useField';

function Input(props) {
  const { disabled, hasError, type, onChange, onBlur, placeholder } = props;

  const [field, meta, helpers] = useField(props);
  const { name, value } = field;

  const handleChange = (e) => {
    helpers.setValue(e.target.value);
    onChange(e, name, helpers.setValue);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
    onBlur(value, name);
  };

  const showError = hasError || Boolean(meta.error && typeof meta.error === 'string' && meta.touched);

  return (
    <StyledInput
      {...field}
      {...props}
      type={type}
      hasError={showError}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
    />
  );
}

Input.propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  inputStyles: PropTypes.instanceOf(Object),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

const noop = () => {};

Input.defaultProps = {
  disabled: false,
  hasError: false,
  inputStyles: {},
  onBlur: noop,
  onChange: noop,
  placeholder: 'Type...',
  type: 'text',
};

export default Input;

const getBorderColor = ({ theme, hasError, disabled }) => {
  let borderColor = theme.colors.black25;

  if (hasError) {
    borderColor = theme.colors.accentRed;
  }

  if (disabled) {
    borderColor = 'transparent';
  }

  return borderColor;
};

export const StyledInput = styled.input`
  box-sizing: border-box;
  border: 1px solid ${getBorderColor};
  border-radius: 3px;
  padding: 8px;
  font-size: ${({ theme }) => theme.textStyles.bodySmall.fontSize};
  line-height: ${({ theme }) => theme.textStyles.bodySmall.lineHeight};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.black10 : theme.colors.white)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};
  outline: none;
  width: 100%;
  height: ${({ inputStyles }) => inputStyles?.height || 'initial'};
  min-height: ${({ inputStyles }) => inputStyles?.minHeight || '40px'};

  &::placeholder {
    color: ${({ theme }) => theme.colors.black25};
  }

  &:focus {
    border-color: ${({ theme, hasError }) => (hasError ? theme.colors.accentRed : theme.colors.primaryBlue)};
  }
`;
