import styled from 'styled-components';

const InlineInputGroups = styled.div`
  flex: 1;
  flex-basis: 100%;
  display: flex;
  align-items: flex-end;
  & > * {
    flex: 1;
    margin-right: 9px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default InlineInputGroups;
