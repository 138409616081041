import React from 'react';

import LoadingSpinnerAroundComponent from './loadingSpinnerAroundComponent';
import LoadingSpinnerIndividual from './loadingSpinnerIndividual';

const LoadingSpinner = (props) => {
  if (props.children) {
    return <LoadingSpinnerAroundComponent {...props} />;
  }

  return <LoadingSpinnerIndividual {...props} />;
};

export default LoadingSpinner;
