import GroupType from '~/models/GroupType';

import Client from './Client';
import { UserPreferenceFactory } from './userPreferences';

const defaults = {
  id: '',
  active: true,
  client: null,
  credential: '',
  email: '',
  flags: [],
  groupType: null,
  allowedGroupNames: '',
  enabledProviderTypeNames: '',
  groups: [],
  isAdmin: false,
  name: '',
  role: null,
  permissions: {},
  lastLogin: '',
  actingAsAcute: null,
  actingClient: null,
  actingClientId: null,
  selectedGroups: [],
  preferences: [],
  scopes: { type: '', ids: [] },
  enabledProviderTypes: [],
  selectedProviderTypes: [],
};

export default class User {
  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.active = opts.active;
    this.client = opts.client ? new Client(opts.client) : opts.client;
    this.credential = opts.credential;
    this.email = opts.email;
    this.flags = opts.flags;
    this.groupType = opts.groupType;
    this.allowedGroupNames = opts.allowedGroupNames;
    this.enabledProviderTypeNames = opts.enabledProviderTypeNames;
    this.groups = opts.groups;
    this.isAdmin = opts.isAdmin || this.#isAdmin();
    this.name = opts.name;
    this.role = opts.role;
    this.permissions = opts.permissions;
    this.lastLogin = opts.lastLogin;
    this.actingAsAcute = opts.actingAsAcute;
    this.actingClient = this.#buildActingClient(opts);
    this.actingClientId = opts.actingClientId;
    this.selectedGroups = opts.selectedGroups;
    this.preferences = opts.preferences.map((preference) => UserPreferenceFactory.get(preference));
    this.scopes = opts.scopes;
    this.enabledProviderTypes = opts.enabledProviderTypes?.map((providerType) => new GroupType(providerType));
    this.selectedProviderTypes = opts.selectedProviderTypes?.map((providerType) => new GroupType(providerType));
  }

  hasFlag(flag) {
    return this.flags.includes(flag);
  }

  get fullName() {
    const { credential, name } = this;

    if (credential && credential.title) return `${name}, ${credential.title}`;

    return name;
  }

  get notificationPreferences() {
    return this.preferences?.find((pref) => pref.isNotification);
  }

  serialize() {
    const params = {
      id: this.id,
      active: this.active,
      clientId: this.client?.id,
      credentialId: this.credential?.id || '',
      email: this.email,
      groupType: this.groupType,
      groupIds: this.selectedGroups?.map((loc) => loc.id) || [],
      name: this.name,
      roleId: this.role?.id,
      selectedProviderTypeIds: (this.selectedProviderTypes || []).map((provider) => provider.id),
    };

    if (!this.actingClient || this.client?.id === this.actingClient.id) {
      params.actingClientId = null;
    }

    return params;
  }

  #buildActingClient(opts) {
    let actingClient = defaults.actingClient;

    if (opts.actingClient?.id) {
      actingClient = new Client(opts.actingClient);
    } else if (opts.client) {
      actingClient = new Client(opts.client);
    }

    return actingClient;
  }

  #isAdmin() {
    return this.client?.isAdmin;
  }
}
