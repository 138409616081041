import colors from '~/styles/theme/colors';

const colorMap = {
  primary: {
    header: {
      background: colors.black05,
      font: colors.primaryBlue,
      closeButton: colors.black75,
    },
  },
  primaryRed: {
    header: {
      background: colors.black05,
      font: colors.accentRed,
      closeButton: colors.black75,
    },
  },
  danger: {
    header: {
      background: colors.accentRed,
      font: colors.white,
      closeButton: colors.white,
    },
  },
  info: {
    header: {
      background: colors.black75,
      font: colors.white,
      closeButton: colors.white,
    },
  },
};

export type ModalHeaderVariants = keyof typeof colorMap;

export const convertColorThemeToColors = ({ colorTheme }: { colorTheme: ModalHeaderVariants }) => {
  return { colors: colorMap[colorTheme] || colorMap.primary };
};
