import React from 'react';
import PropTypes from 'prop-types';

const CompletedStatusIcon = ({ size = 16 }) => {
  return (
    <svg width={ size } height={ size } viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="view-status-details-import-history" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="ImportDetailsStatus" transform="translate(-239.000000, -284.000000)" fill="#61BD15" fillRule="nonzero">
          <g id="c-check" transform="translate(239.000000, 284.000000)">
            <path d="M8,0 C3.5815,0 0,3.5815 0,8 C0,12.4185 3.5815,16 8,16 C12.4185,16 16,12.4185 16,8 C15.987,3.587 12.413,0.013 8,0 Z M11.8535,5.8535 L6.8535,10.8535 C6.756,10.951 6.628,11 6.5,11 C6.372,11 6.244,10.951 6.1465,10.8535 L4.1465,8.8535 C3.951,8.658 3.951,8.342 4.1465,8.1465 C4.342,7.951 4.658,7.951 4.8535,8.1465 L6.5,9.793 L11.1465,5.1465 C11.342,4.951 11.658,4.951 11.8535,5.1465 C12.049,5.342 12.049,5.658 11.8535,5.8535 Z" id="Shape"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

CompletedStatusIcon.propTypes = {
  size: PropTypes.number
};

export default CompletedStatusIcon;
