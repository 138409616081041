import React from 'react';

import { Row } from '@tanstack/react-table';

import { FlyoutMenu, FlyoutMenuIcon, FlyoutMenuItem } from '~/components/shared/FlyoutMenu';
import { DeactivateIcon, PencilIcon } from '~/components/shared/svg';
import { BodySmall } from '~/components/shared/typography';
import { Client } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import { colors } from '~/styles/theme';

export const { adminClientEdit, adminClientGroupTypeView, adminCollaborationEdit } = PERMISSIONS;
export const permissions = [adminClientEdit, adminClientGroupTypeView, adminCollaborationEdit];

type Props = {
  onEdit: () => void;
  onArchive: () => void;
  row: Row<Client>;
};

function DischargeReasonsFlyoutMenu(props: Props) {
  const { onEdit, onArchive, row } = props;

  const profileSvc: any = useProfileContext();

  return (
    <FlyoutMenu data={row.original}>
      <FlyoutMenuItem onClick={onEdit} visible={profileSvc.has(PERMISSIONS.adminDischargeReasonEdit)}>
        <FlyoutMenuIcon>
          <PencilIcon size={18} />
        </FlyoutMenuIcon>
        Edit
      </FlyoutMenuItem>
      <FlyoutMenuItem onClick={onArchive} visible={profileSvc.has(PERMISSIONS.adminDischargeReasonEdit)}>
        <FlyoutMenuIcon>
          <DeactivateIcon size={18} />
        </FlyoutMenuIcon>
        <BodySmall color={colors.accentRed}>Archive</BodySmall>
      </FlyoutMenuItem>
    </FlyoutMenu>
  );
}

export default DischargeReasonsFlyoutMenu;
