import { Api } from './core';

export const url = '/me/notification_preferences';

const { fetch, create, update } = new Api({ url });

export default {
  fetch,
  create,
  update,
};
