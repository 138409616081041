import React from 'react';
import PropTypes from 'prop-types';

import theme from '~/styles/theme';

const CircleCheckIcon = ({ fill, secondaryFill, size, className }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox='0 0 24 24'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <path
          d='M12,0 C5.372583,0 0,5.372583 0,12 C0,18.627417 5.372583,24 12,24 C18.627417,24 24,18.627417 24,12 C23.9807773,5.38056166 18.6194383,0.0192227178 12,0 Z M10,17.414 L4.586,12 L6,10.586 L10,14.586 L18,6.586 L19.414,8 L10,17.414 Z'
          id='circle-check-path-1'
        />
      </defs>
      <g id='circle-check-icon-blue' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g>
          <mask id='mask-2' fill={secondaryFill}>
            <use xlinkHref='#circle-check-path-1' />
          </mask>
          <use id='Mask' fill={fill} xlinkHref='#circle-check-path-1' />
        </g>
      </g>
    </svg>
  );
};

CircleCheckIcon.defaultProps = {
  fill: theme.colors.primaryBlue,
  secondaryFill: theme.colors.white,
  size: 24,
};

CircleCheckIcon.propTypes = {
  fill: PropTypes.string,
  secondaryFill: PropTypes.string,
  size: PropTypes.number,
};

export default CircleCheckIcon;
