import React, { useMemo } from 'react';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';

import {
  ErrorMessage as Error,
  FormSection,
  InputGroup,
  SectionHeader,
  Select
} from '~/components/shared/form';

import Question from './Question';
import { questionTypeOptionsForTemplateType } from './questionsConfigurations';

function QuestionsSection() {
  const {
    setFieldValue,
    validateForm,
    values
  } = useFormikContext();

  const questionTypeOptions = useMemo(() => (
    questionTypeOptionsForTemplateType(values.type.value)
  ), [values.type]);

  const QUESTION_TYPE_COLOR = '#2684FF';

  const newQuestion = (item) => {
    return {
      kind: item.value,
      text: item.value === 'status' ? 'Is the patient discharge plan on track?' : '',
      config: {},
      key: new Date().toISOString()
    };
  };

  const addQuestion = () => {
    return (item) => {
      setFieldValue('questionType', null);
      values.questions.push(newQuestion(item));
      validateForm();
    };
  };

  return (
    <FormSection>
      <SectionHeader>Template Questions</SectionHeader>
      <InputGroup name='questionType'
        data-cy='questionType'
        options={ questionTypeOptions }
        placeholder='Add Question'
        component={ Select }
        autoSelectSingleOption={ false }
        styles={ {
          control: {
            borderColor: QUESTION_TYPE_COLOR,
            '&:hover': { borderColor: QUESTION_TYPE_COLOR }
          },
          placeholder: { color: QUESTION_TYPE_COLOR },
          dropdownIndicator: { color: QUESTION_TYPE_COLOR }
        } }
        onChange={ addQuestion() } />
      <ErrorMessage name={ 'questions' } component={ Error } />
      <FieldArray name='questions'
        render={ (helpers) => (
          values.questions.map((question, index) => (
            <Question key={ question.id || question.key }
              question={ question }
              index={ index }
              onRemove={ helpers.remove } />
          ))
        ) } />
    </FormSection>
  );
}

export default QuestionsSection;
