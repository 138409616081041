import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { StandardModal } from '~/components/shared/modal';
import { ACUTE_CLIENT_TYPES } from '~/constants/clientTypes';
import { updateCollaboration } from '~/ducks/admin/collaborations';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { Client, Collaboration } from '~/models';
import { adminClientsApi } from '~/services/api';

import CollaborationForm from './CollaborationForm';

function CollaborationModal(props) {
  const { client, onCancel, show } = props;

  const collaboration = useMemo(() => new Collaboration(client), [client]);
  const [clients, setClients] = useState([]);

  const fetchClients = async () => {
    const params = {
      clientType: ACUTE_CLIENT_TYPES.join(','),
      'id.not': client.id,
      pageSize: 1000,
      sortBy: 'name',
    };

    const res = await adminClientsApi.fetch.invoke(params);

    setClients(res.data.data.map((item) => new Client(item)));
  };

  useEffect(() => {
    if (show) fetchClients();
  }, [collaboration, show]);

  const handleSubmit = (formValues, { setErrors, setSubmitting }) => {
    const data = Collaboration.fromFormValues(formValues).serialize();

    props
      .updateCollaboration(data)
      .then(unwrapResult)
      .then(() => {
        props.addToast({ text: 'Collaborations successfully updated' });
        onCancel();
      })
      .catch((e) => {
        if (e?.response?.data?.errors?.client) {
          setErrors({ collaborators: e?.response?.data?.errors?.client.join(', ') });
        } else {
          props.addToast({ text: 'There was an error updating the collaborations' });
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <StandardModal show={show} title='Client Collaborations' onCancel={onCancel} disableBackdropClick>
      <CollaborationForm
        {...props}
        clients={clients}
        collaboration={collaboration}
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </StandardModal>
  );
}

CollaborationModal.propTypes = {
  addToast: PropTypes.func.isRequired,
  client: PropTypes.oneOfType([PropTypes.instanceOf(Client), PropTypes.instanceOf(Collaboration)]),
  onCancel: PropTypes.func.isRequired,
  show: PropTypes.bool,
  updateCollaboration: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addToast,
  updateCollaboration,
};

export default connect(null, mapDispatchToProps)(CollaborationModal);
