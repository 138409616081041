import { useCallback, useState } from 'react';

function useSwitch(initialState = false) {
  const [state, setState] = useState(initialState);

  const turnOn = useCallback(() => setState(true), []);
  const turnOff = useCallback(() => setState(false), []);
  const toggle = useCallback(() => setState(!state), [state]);

  return { state, toggle, turnOn, turnOff };
}

export default useSwitch;
