import { useEffect, useRef } from 'react';

function useInterval(callback, options = {}) {
  const { delay = 100, condition = true } = options;
  const savedCallback = useRef();

  if (!callback) {
    throw new Error('useInterval expects a callback function');
  }

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() =>  {
    const tick = () => savedCallback.current();

    if (condition && delay !== null) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }

    return () => {};
  }, [delay, condition]);
}

export default useInterval;
