import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchPermissions } from '~/ducks/admin/permissions';
import { fetchUser, updateUser } from '~/ducks/admin/users';
import { useThunk } from '~/lib/hooks';

import Permissions from '../../permissions/Permissions';

const UserPermissionsContainer = (props) => {
  const userId = props.match.params.id;
  const { data: user, setData: setUser } = useThunk(fetchUser, [userId], {
    condition: Boolean(userId),
    params: { id: userId, include: 'client,role' },
  });

  const { data: permissions } = useThunk(fetchPermissions, []);

  const handleFormSubmit = async (values) => {
    const updatedUser = {
      id: userId,
      permissions: values,
      include: 'role',
    };

    return props.updateUser(updatedUser);
  };

  const handleFormSubmitSuccess = (updatedUser) => setUser(updatedUser);
  const subTitle = `${user.client?.name} — ${user.role?.name}`;

  return (
    <Permissions
      pageTitle={user.name}
      onFormSubmit={handleFormSubmit}
      onFormSubmitSuccess={handleFormSubmitSuccess}
      pageSubtitle={subTitle}
      permissions={permissions}
      user={user}
    />
  );
};

UserPermissionsContainer.propTypes = {
  fetchUser: PropTypes.func,
  updateUser: PropTypes.func,
};

const mapDispatchToProps = {
  fetchUser,
  updateUser,
};

export default connect(null, mapDispatchToProps)(UserPermissionsContainer);
