
import { EPISODE, EPISODE_TYPE_ROUTE, PLAN_TYPE, PLAN_TYPE_ROUTE } from '~/constants/classifications';

export function classificationTitle(type) {
  switch (type) {
    case PLAN_TYPE:
      return 'Plan Type';
    case EPISODE:
      return 'Episode Type';
    default:
      return 'Classification';
  }
}

export const classificationPageTitle = (type) => {
  return classificationTitle(type).concat('s');
};

export function classificationTypeFromRoute(type) {
  switch (type) {
    case PLAN_TYPE_ROUTE:
      return PLAN_TYPE;
    case EPISODE_TYPE_ROUTE:
      return EPISODE;
    default:
      return 'classification';
  }
}
