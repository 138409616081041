/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector, createSlice } from '@reduxjs/toolkit';

import { ACTIVE, ALL_OPTION, CLIENT, MAPPED_OLIO_FIELD, ONLY_INACTIVE, SEARCH } from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

export const initialState = {
  [CLIENT]: ALL_OPTION,
  [MAPPED_OLIO_FIELD]: ALL_OPTION,
  [ONLY_INACTIVE]: false,
  [SEARCH]: '',
};

const sliceName = 'admin/attrs/filters';

const attrsFiltersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFilter: (state: any, action: any) => {
      const { filterType, value } = action.payload;

      state[filterType] = value;
    },
    clearFilters: (state) => ({ ...initialState, [SEARCH]: state[SEARCH] }),
  },
  extraReducers: (builder) => {
    builder.addCase(rootPathnameChanged, () => initialState);
  },
});

export const getAttrsFilters = (state: any) => state[sliceName];

export const getAttrsFiltersForRequest = createSelector(getAttrsFilters, (attrsFilters) => {
  return Object.entries(attrsFilters)
    .filter(([filterKey, filter]: any) => {
      switch (filterKey) {
        case ONLY_INACTIVE:
        case SEARCH:
          return filter;
        default:
          return filter.id;
      }
    })
    .reduce((acc, [filterKey, filter]: any) => {
      switch (filterKey) {
        case ONLY_INACTIVE:
          // If the checkbox is checked, that means retun records where
          // active=false
          return { ...acc, [ACTIVE]: `${!filter}` };
        case SEARCH:
          return { ...acc, [filterKey]: `${filter}` };
        default:
          return { ...acc, [filterKey]: `${filter.id}` };
      }
    }, {});
});

export const { clearFilters, setFilter } = attrsFiltersSlice.actions;

export default attrsFiltersSlice;
