export enum ImportStatus {
  PENDING = 'pending',
  RUNNING = 'running',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  ERRORED = 'errored'
}

export enum ImportAction {
  CANCEL = 'cancel',
  RESTART = 'restart',
  RUN = 'run'
}

export const ALLOWED_STATUS_ACTIONS = new Map<ImportStatus, ImportAction[]>([
  [ImportStatus.CANCELED, [ImportAction.RESTART]],
  [ImportStatus.COMPLETED, [ImportAction.RESTART]],
  [ImportStatus.ERRORED, [ImportAction.RESTART]],
  [ImportStatus.PAUSED, [ImportAction.CANCEL, ImportAction.RUN]],
  [ImportStatus.PENDING, [ImportAction.CANCEL]],
  [ImportStatus.RUNNING, [ImportAction.CANCEL]]
]);
