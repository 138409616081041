import theme from '~/styles/theme';

import { customStyles as baseCustomStyles, handleOverride } from '../Select';

const { colors, textStyles } = theme;

export const customStyles = ({ multiValue = {}, multiValueLabel = {}, indicatorsContainer = {}, ...rest } = {}) => ({
  ...baseCustomStyles(rest),
  indicatorsContainer: (provided, state) => ({
    display: state.isDisabled ? 'none' : provided.display,
    ...handleOverride(provided, state, indicatorsContainer),
  }),
  multiValue: (provided) => ({
    ...provided,
    borderRadius: 3,
    backgroundColor: colors.black10,
    ...multiValue,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    ...textStyles.base,
    ...textStyles.label,
    ...(state.isDisabled ? { paddingRight: 6 } : {}),
    ...multiValueLabel,
  }),
  multiValueRemove: (provided, state) => {
    return state.isDisabled ? { ...provided, display: 'none' } : provided;
  },
});
