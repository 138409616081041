import usePreviousWithReset from './usePreviousWithReset';

const usePrevious = (value) => {
  // eslint-disable-next-line no-unused-vars
  const [previous, _reset] = usePreviousWithReset(value);

  return previous;
};

export default usePrevious;
