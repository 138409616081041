import theme from '~/styles/theme';

import { customStyles as baseCustomStyles } from '../MultiSelect/customStyles';

const { colors } = theme;

export const customStyles = ({ menu = {}, placeholder = {}, ...rest } = {}) => ({
  ...baseCustomStyles(rest),
  menu: (provided) => ({
    ...provided,
    minWidth: '240px',
    ...menu,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.black,
    ...placeholder,
  }),
});
