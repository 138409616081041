
import { addToast } from '~/ducks/toasts';
import { createAsyncThunk } from '~/lib';
import { Import } from '~/models/imports';
import { adminImportsApi } from '~/services/api';

export const sliceName = 'admin/imports';

const cancellableFetchImports = adminImportsApi.fetch.cancellable();

export const fetchImports = createAsyncThunk(
  `${sliceName}/fetch`,
  async (params) => {
    const res = await cancellableFetchImports(params);

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Import
  }
);

export const fetchImport = createAsyncThunk(
  `${sliceName}/fetchById`,
  async ({ id, ...params }) => {
    const { data } = await adminImportsApi.fetchById.invoke(id, params);

    return data;
  },
  {
    modelClass: Import
  }
);

export const updateImport = createAsyncThunk(
  `${sliceName}/update`,
  async ({ id, ...params }, { dispatch }) => {
    const { data } = await adminImportsApi.update.invoke(id, params)
      .catch((e) => {
        let text = e.response?.data?.error || `Failed to update import:\n${e}`;

        if (e.response.status === 403) {
          text = 'You do not have permission to perform this action';
        }

        dispatch(addToast({ text }));

        throw e;
      });

    return data;
  }
);
