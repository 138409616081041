export const TIMEZONES = [
  // The value should be a valid IANA/Olsen timezone identifier
  // IANA/Olsen: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  // General US timezones: https://www.timeanddate.com/time/zone/usa
  {
    label: 'Alaska Time',
    value: 'America/Juneau'
  },
  {
    label: 'Arizona Time',
    value: 'America/Phoenix'
  },
  {
    label: 'Central Time',
    value: 'America/Chicago'
  },
  {
    label: 'Eastern Time',
    value: 'America/New_York'
  },
  {
    label: 'Hawaii Time',
    value: 'Pacific/Honolulu'
  },
  {
    label: 'Mountain Time',
    value: 'America/Denver'
  },
  {
    label: 'Pacific Time',
    value: 'America/Los_Angeles'
  }
];
