import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import {
  clearFilters,
  getGroupTypesFilters,
  getGroupTypesFiltersForRequest,
  GroupTypeFiltersState,
  setFilters,
} from '~/ducks/admin/groupTypesFilters';
import { useThunk } from '~/lib/hooks';
import { GroupType } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';
import { BaseIndexParams } from '~/types';

import { Button } from '../shared/buttons';
import { MainPage } from '../shared/pageLayout';
import { PlusIcon } from '../shared/svg';
import { ControlledTable, CountMessage } from '../shared/table';

import GroupTypeFilters from './GroupTypeFilters';
import groupTypesTableColumns from './groupTypesTableColumns';

function GroupTypes(props: Props) {
  const profileSvc: any = useProfileContext();
  const canAddValue = profileSvc.has(PERMISSIONS.adminGroupTypeCreate);

  const [queryMeta, setQueryMeta] = useState({ totalPages: 0, totalRecords: 0 });
  const [pagingFilters, setPagingFilters] = useState<BaseIndexParams>({
    page: 1,
    pageSize: 10,
    sortBy: 'name asc',
  });

  const { data: groupTypes, loaded } = useThunk(fetchGroupTypes, [props.filtersForRequest, pagingFilters], {
    onSuccess: (payload) => {
      setQueryMeta({
        totalPages: payload?.meta?.totalPages,
        totalRecords: payload?.meta?.totalRecords,
      });
    },
    params: { ...props.filtersForRequest, ...pagingFilters },
    debounce: 50,
  });

  const handleEdit = (groupType: GroupType) => {
    props.history.push(`/group-types/${groupType.id}/edit`);
  };

  const handlePagingFiltersChange = useCallback((newPagingFilters: BaseIndexParams) => {
    setPagingFilters(newPagingFilters);
  }, []);

  return (
    <MainPage
      title='Group Types'
      rightContent={
        canAddValue && (
          <Button
            onClick={() => props.history.push('/group-types/new')}
            icon={<PlusIcon size={14} fill={colors.white} />}
            text='Add Group Type'
          />
        )
      }
      middleContent={<CountMessage count={queryMeta.totalRecords} countType='result' />}>
      <GroupTypeFilters filters={props.filters} setFilters={props.setFilters} />

      <ControlledTable
        data={groupTypes}
        defaultSortBy={'name asc'}
        loading={!loaded}
        columns={groupTypesTableColumns({ profileSvc, onEdit: handleEdit })}
        filters={props.filters}
        onPagingFiltersChange={handlePagingFiltersChange}
        pageCount={queryMeta.totalPages}
      />
    </MainPage>
  );
}

type Props = {
  filters: GroupTypeFiltersState;
  filtersForRequest: Record<string, unknown>;
  setFilters: typeof setFilters;
  clearFilters: () => void;
} & RouteComponentProps;

const mapStateToProps = (state: any) => ({
  filters: getGroupTypesFilters(state),
  filtersForRequest: getGroupTypesFiltersForRequest(state),
});

const mapDispatchToProps = {
  setFilters,
  clearFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupTypes);
