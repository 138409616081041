import React from 'react';

function Logo(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='79' height='24' fill='none' {...props}>
      <path
        fill='#fff'
        d='M16.991.898C15.491.302 13.856 0 12.132 0 10.41 0 8.774.302 7.275.898a11.402 11.402 0 0 0-3.86 2.52C1.213 5.608 0 8.657 0 12c0 3.343 1.213 6.39 3.415 8.583a11.4 11.4 0 0 0 3.86 2.519c1.5.596 3.134.898 4.857.898 1.724 0 3.359-.302 4.86-.898 1.469-.583 2.769-1.43 3.864-2.518 2.207-2.194 3.423-5.242 3.423-8.584 0-3.342-1.215-6.39-3.423-8.584A11.427 11.427 0 0 0 16.991.898Zm-4.859 16.79c-1.577 0-2.937-.537-3.93-1.551C7.19 15.104 6.655 13.673 6.655 12c0-1.673.535-3.104 1.547-4.137.993-1.014 2.353-1.55 3.93-1.55 1.583 0 2.944.536 3.936 1.55 1.008 1.03 1.541 2.462 1.541 4.137 0 1.675-.533 3.106-1.541 4.137-.992 1.014-2.353 1.55-3.936 1.55ZM32.582.388h-6.476v23.223h17.529v-6.208H32.582V.388ZM52.028.388h-6.476v23.223h6.476V.389ZM74.633 3.417A11.427 11.427 0 0 0 70.77.897C69.268.303 67.633 0 65.909 0c-1.723 0-3.357.302-4.857.898a11.403 11.403 0 0 0-3.86 2.52C54.99 5.608 53.777 8.657 53.777 12c0 3.343 1.213 6.39 3.415 8.583a11.4 11.4 0 0 0 3.86 2.519c1.5.596 3.134.898 4.857.898 1.724 0 3.359-.302 4.86-.898 1.47-.583 2.77-1.43 3.864-2.518 2.208-2.194 3.424-5.242 3.424-8.584 0-3.342-1.216-6.39-3.424-8.584Zm-8.724 14.27c-1.577 0-2.936-.536-3.93-1.55-1.012-1.033-1.547-2.464-1.547-4.137 0-1.673.535-3.104 1.547-4.137.994-1.014 2.353-1.55 3.93-1.55 1.583 0 2.944.536 3.936 1.55 1.008 1.03 1.541 2.462 1.541 4.137 0 1.675-.533 3.106-1.541 4.137-.992 1.014-2.353 1.55-3.936 1.55Z'
      />
    </svg>
  );
}

export default Logo;
