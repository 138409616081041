import React from 'react';
import styled from 'styled-components';

import CircleSpinner from '~/components/shared/CircleSpinner';

function LoadingOverlay() {
  return (
    <StyledLoadingOverlay>
      <StyledSpinnerContainer>
        <CircleSpinner margin={'auto auto'} />
      </StyledSpinnerContainer>
    </StyledLoadingOverlay>
  );
}

export default LoadingOverlay;

const StyledLoadingOverlay = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
`;

const StyledSpinnerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.black25};
  border-radius: 3px;
  display: flex;
  height: 100px;
  width: 100px;
`;
