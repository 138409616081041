import React from 'react';

import { DeactivatedUserIcon } from '~/components/shared/svg';
import { BodyBold } from '~/components/shared/typography';

import { Body, Container, Link } from './shared';

function InactiveUserError() {
  return (
    <Container>
      <DeactivatedUserIcon />
      <Body>
        <BodyBold as='span' fontSize='20px'>
          {'Your account is currently deactivated.'}
        </BodyBold>
        &nbsp;
        {'For help with reactivation, please reach out to '}
        <Link href='mailto:support@olio.health'>support@olio.health</Link>
      </Body>
    </Container>
  );
}

export default InactiveUserError;
