import client from './client';

const BASE_URL = 'admin/flags';

export default {
  fetchFlag(name) {
    return client.get(`${BASE_URL}/${name}`);
  },

  fetchFlags() {
    return client.get(BASE_URL);
  },

  updateFlag(flag) {
    return client.patch(`${BASE_URL}/${flag.name}`, flag);
  }
};
