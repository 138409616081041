import React, { createContext, useContext, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { NotificationPreferencesFormValues } from '~/components/preferences/notifications/NotificationPreferencesFormWrapper';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import TabFilters from '~/components/shared/TabFilters';
import { TabType } from '~/components/shared/TabFilters/TabFilter';
import { withSaveChangesModal } from '~/components/shared/withSaveChangesModal';
import { User } from '~/models';

export const SelectedPreferenceTab = createContext('');

export type Props = {
  children: React.ReactNode;
  onTabClick: (tab: string) => void;
  onTabChanged: (tab: string) => void;
  subtitle: string;
  title: string;
  user: User;
};

export default function NotificationPreferencesPage(props: Props) {
  const { children, onTabClick, onTabChanged, subtitle, title, user } = props;

  const {
    dirty,
    isSubmitting,
    isValid,
    resetForm,
    status: { disabled },
    submitForm,
  } = useFormikContext<NotificationPreferencesFormValues>();

  const selectedTab = useContext(SelectedPreferenceTab);
  const [showModalFunc, setShowModalFunc] = useState(() => () => undefined);
  const discardDisabled = useMemo(() => disabled || !dirty || isSubmitting, [dirty, isSubmitting]);
  const saveDisabled = useMemo(() => disabled || !dirty || isSubmitting || !isValid, [dirty, isSubmitting, isValid]);
  const handleDiscardClick = () => showModalFunc();
  const handleBindShowModal = (func: () => undefined) => {
    setShowModalFunc(() => func);
  };

  const buttonGroup = (
    <ButtonGroup>
      <Button color='transparent' disabled={saveDisabled} onClick={handleDiscardClick} text='Discard Changes' />
      <Button
        data-cy='saveNotificationPreferencesButton'
        disabled={discardDisabled}
        onClick={submitForm}
        text='Save Changes'
      />
    </ButtonGroup>
  );

  const MainPageWithSaveChangesModal = useMemo(() => withSaveChangesModal(MainPage), []);

  const providerTabs: TabType[] = useMemo(() => {
    return user.enabledProviderTypes.map((type) => ({
      label: type.displayName,
      value: type.id,
    }));
  }, [user.enabledProviderTypes]);

  return (
    <MainPageWithSaveChangesModal
      title={title}
      bindShowModal={handleBindShowModal}
      headerWhite
      historyBlockCondition={dirty || isSubmitting}
      onSaveChangesConfirm={resetForm}
      rightContent={buttonGroup}
      subtitle={subtitle}
      headerContent={
        <TabFiltersContainer>
          <TabFilters
            tabs={providerTabs}
            dataCy='providerTabs'
            onTabChanged={onTabChanged}
            onTabClick={onTabClick}
            selectedTab={providerTabs.find((tab: TabType) => tab.value === selectedTab) || providerTabs[0]}
          />
        </TabFiltersContainer>
      }>
      {React.Children.map(
        children,
        (child) =>
          child &&
          React.cloneElement(child, {
            selectedTab,
          })
      )}
    </MainPageWithSaveChangesModal>
  );
}

const TabFiltersContainer = styled.div`
  margin-top: 24px;
`;
