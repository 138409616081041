import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { unwrapResult } from '@reduxjs/toolkit';

import { fetchExport } from '~/ducks/exports';
import { fetchJobProcess } from '~/ducks/jobProcesses';
import { addToast } from '~/ducks/toasts';
import useInterval from '~/lib/hooks/useInterval';
import { JobProcess } from '~/models';

export default function useExportDownload({ exportFn }) {
  const [loading, setLoading] = useState(false);
  const [jobProcess, setJobProcess] = useState();
  const dispatch = useDispatch();

  useInterval(
    () => {
      dispatch(fetchJobProcess(jobProcess?.id))
        .then(unwrapResult)
        .then(updateJobProcess)
        .catch(() => {
          handleError();
          reset();
        });
    },
    {
      delay: 2000,
      condition: Boolean(jobProcess && !jobProcess.isFinished),
    }
  );

  useEffect(() => {
    if (jobProcess?.hasError) {
      handleError();
    } else if (jobProcess?.isCompleted) {
      dispatch(fetchExport(jobProcess.context.exportId))
        .then(unwrapResult)
        .then(openExport)
        .catch(handleError)
        .finally(reset);
    }
  }, [jobProcess]);

  const downloadExport = () => {
    setLoading(true);

    exportFn()
      .then(unwrapResult)
      .then(updateJobProcess)
      .catch(() => {
        handleError();
        reset();
      });
  };

  const openExport = ({ url }) => {
    if (url) {
      window.open(url, '_blank');
    }
  };

  const handleError = useCallback(() => {
    dispatch(addToast({ text: 'Something went wrong with the export. Please try again.' }));
  }, []);

  const reset = useCallback(() => {
    setLoading(false);
    setJobProcess(undefined);
  }, []);

  const updateJobProcess = useCallback((data) => setJobProcess(new JobProcess(data)), []);

  return { loading, downloadExport };
}
