import { Api } from './core';

const {
  create,
  fetch,
  fetchById,
  update
} = new Api({ url: 'admin/question_templates' });

export default {
  create,
  fetch,
  fetchById,
  update
};
