export const DISCHARGE = 'discharge';
export const PROGRESS = 'progress';

export const QUESTION_TEMPLATE_TYPES = [DISCHARGE, PROGRESS];

export const TEMPLATE_TYPE_OPTIONS = [
  {
    label: 'Progress Template',
    value: PROGRESS
  },
  {
    label: 'Discharge Template',
    value: DISCHARGE
  }
];
