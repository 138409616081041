import React from 'react';
import styled from 'styled-components';

import colors from '~/styles/theme/colors';

type ContentProps = {
  children: React.ReactNode;
};

function Content({ children }: ContentProps) {
  return <StyledContent>{children}</StyledContent>;
}

export default Content;

const StyledContent = styled.div`
  padding: 24px 40px 40px;
  background-color: ${colors.white};
`;
