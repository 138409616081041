import axios from 'axios';

import { authService } from '../../services/auth';

async function onBeforeRequest(config) {
  const { accessToken } = await authService.renewSession();

  config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
}

function onResponseError(err) {
  if (err.response && err.response.status === 401) {
    authService.logout();
  } else {
    throw err;
  }
}

const client = axios.create();

client.defaults.baseURL = import.meta.env.API_URL;
client.defaults.headers = {
  Accepts: 'application/json',
  'Content-Type': 'application/json',
};

client.interceptors.request.use(onBeforeRequest);
client.interceptors.response.use(null, onResponseError);

export default client;
