import styled from 'styled-components';

import { center } from '~/styles/mixins';

const FlyoutMenuIcon = styled.div`
  height: ${({ height }) => height || 24}px;
  margin-right: 8px;
  width: ${({ width }) => width || 24}px;
  ${center()};
`;

export default FlyoutMenuIcon;
