import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';

import menuIcon from '~/assets/images/menu.png';
import menuIconHorizontal from '~/assets/images/menu-horizontal.svg';

export const HORIZONTAL = 'horizontal';
export const VERTICAL = 'vertical';

const isHorizontal = (iconOrientation) => iconOrientation === HORIZONTAL;
const FlyoutMenu = ({ children, data, iconOrientation }) => {
  const [showFlyoutMenu, setShowFlyoutMenu] = useState(false);

  const handleMenuClick = () => {
    setShowFlyoutMenu(!showFlyoutMenu);
  };

  const handleHideFlyoutMenu = () => {
    setShowFlyoutMenu(false);
  };

  return (
    /* Need the div so menu items position correctly */
    <div data-cy={'flyoutMenu'}>
      <Icon
        onClick={handleMenuClick}
        iconOrientation={iconOrientation}
        src={isHorizontal(iconOrientation) ? menuIconHorizontal : menuIcon}
      />

      {showFlyoutMenu && (
        <OutsideClickHandler onOutsideClick={handleHideFlyoutMenu}>
          <FlyoutMenuItems
            iconOrientation={iconOrientation}
            onMouseLeave={handleHideFlyoutMenu}
            data-cy={'flyoutMenuItems'}>
            {React.Children.map(
              children,
              (child) =>
                child &&
                React.cloneElement(child, {
                  onClick: () => {
                    child.props.onClick?.(data);
                    handleHideFlyoutMenu();
                  },
                })
            )}
          </FlyoutMenuItems>
        </OutsideClickHandler>
      )}
    </div>
  );
};

FlyoutMenu.propTypes = {
  data: PropTypes.instanceOf(Object),
  iconOrientation: PropTypes.oneOf([HORIZONTAL, VERTICAL]),
};

FlyoutMenu.defaultProps = {
  iconOrientation: VERTICAL,
};

export default FlyoutMenu;

const Icon = styled.img`
  cursor: pointer;
  width: 12px;
`;

const FlyoutMenuItems = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-radius: 3px;
  box-shadow: 0px 6px 16px -8px ${({ theme }) => theme.colors.black25};
  position: absolute;
  right: ${({ iconOrientation }) => (isHorizontal(iconOrientation) ? '5px' : '10px')};
  text-align: left;
  z-index: 100;

  & > * {
    padding: 12px;
  }

  & > *:first-child {
    margin-top: 0px;
  }

  & > *:last-child {
    margin-bottom: 0px;
  }
`;
