import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import useField from '~/lib/hooks/useField';

import InfiniteScrollWrapper from '../InfiniteScrollWrapper';
import { DropdownIndicator } from '../Select';
import { customTheme } from '../Select';

import Option from './CheckboxMultiSelectOption';
import Placeholder from './CheckboxMultiSelectPlaceholder';
import { customStyles } from './customStyles';

function CheckboxMultiSelect(props) {
  const { components, disabled, hasError, menuPlacement, onBlur, onChange, styles } = props;

  const [field, meta, helpers] = useField(props);
  const { name } = field;
  const [inputValue, setInputValue] = useState('');

  const handleChange = (val) => {
    helpers.setValue(val);
    onChange(val, name, helpers.setValue);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
    onBlur(name);
  };

  const handleInputChange = (val, { action }) => {
    if (action !== 'set-value') {
      setInputValue(val);

      return val;
    }

    return inputValue;
  };

  const showError = hasError || Boolean(meta.error && typeof meta.error === 'string' && meta.touched);

  return (
    <InfiniteScrollWrapper
      {...field}
      {...props}
      closeMenuOnSelect={false}
      component={ReactSelect}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      inputValue={inputValue}
      isMulti
      isClearable
      isDisabled={disabled}
      hasError={showError}
      onChange={handleChange}
      onBlur={handleBlur}
      onInputChange={handleInputChange}
      theme={customTheme}
      styles={customStyles(styles)}
      classNamePrefix={'react-select'}
      components={{ DropdownIndicator, Option, Placeholder, ...components }}
      menuPlacement={menuPlacement}
    />
  );
}

CheckboxMultiSelect.propTypes = {
  components: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  hasError: PropTypes.bool,
  menuPlacement: PropTypes.string,
  meta: PropTypes.instanceOf(Object),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string])).isRequired,
  styles: PropTypes.instanceOf(Object),
};

const noop = () => {};

CheckboxMultiSelect.defaultProps = {
  components: {},
  disabled: false,
  getOptionLabel: ({ label }) => label,
  getOptionValue: ({ value }) => value,
  hasError: false,
  menuPlacement: 'auto',
  onBlur: noop,
  onChange: noop,
};

export default CheckboxMultiSelect;
