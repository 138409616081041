import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FormSubtitle from './FormSubtitle';
import FormTitle from './FormTitle';

function FormHeader({ title, subtitle }) {
  return (
    <TitleContainer>
      {title && <FormTitle>{title}</FormTitle>}
      {subtitle && <FormSubtitle>{subtitle}</FormSubtitle>}
    </TitleContainer>
  );
}

FormHeader.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default FormHeader;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-basis: 100%;
  margin-bottom: 36px;
`;
