const defaults = {
  id: '',
  description: '',
  title: ''
};

export default class Credential {
  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.description = opts.description;
    this.title = opts.title;
  }
}
