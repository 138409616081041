import { useEffect, useRef } from 'react';

import { isDeepEqual } from '~/lib';

// `useDeepEffect` will deeply compare two objects to see if they're the same, and
// run the `effectFunc` if they are not.
//
// Why?: `useEffect` performs a shallow comparison on objects, merely checking to see if the
// value changed (primitive types) or the reference changed (objects). In the case of the latter,
// it would cause the effect function to be executed when the ref changes, but the props values
// are the same.
//
// Credit: https://medium.com/better-programming/how-to-use-the-react-hook-usedeepeffect-815818c0ad9d
const useDeepEffect = (effectFunc, deps = [], initializePrevDeps = false) => {
  const prevDeps = initializePrevDeps ? useRef(deps) : useRef(null);

  useEffect(() => {
    const isSame = !!(
      prevDeps.current &&
      prevDeps.current.every((obj, index) => {
        return isDeepEqual(obj, deps[index]);
      })
    );

    if (!isSame) {
      effectFunc();
    }

    prevDeps.current = deps;
  }, deps);
};

export default useDeepEffect;
