import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { addToast } from '~/ducks/toasts';
import { createAsyncThunk } from '~/lib';
import { User } from '~/models';
import { Notification } from '~/models/userPreferences';
import { adminUsersApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from '../api';
import PaginationState from '../PaginationState';

const SLICE_NAME = 'admin/users';

export type FetchNotificationPreferencesByIdParams = {
  id: string;
  include?: string;
};

const cancellableFetchUsers = adminUsersApi.fetch.cancellable();
const cancellableFetchNotificationPreferencesById = adminUsersApi.fetchNotificationPreferencesById.cancellable();

export const fetchUsers = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (params: any) => {
    const res = cancellableFetchUsers(params).then((arg) => arg as any);

    return (await res).data;
  },
  {
    defaultValue: [],
    modelClass: User,
  } as any
);

export const fetchNotificationPreferencesById = createAsyncThunk(
  `${SLICE_NAME}/fetchNotificationPreferencesById`,
  async (params: FetchNotificationPreferencesByIdParams) => {
    const res = cancellableFetchNotificationPreferencesById(params).then((arg) => arg as any);

    return (await res).data;
  },
  {
    defaultValue: [],
    modelClass: Notification,
  } as any
);

export const fetchUser = createAsyncThunk(
  `${SLICE_NAME}/fetchById`,
  async ({ id, ...params }: any) => {
    const res = await adminUsersApi.fetchById.invoke(id, params);

    return res.data;
  },
  {
    modelClass: User,
  } as any
);

export const deleteUser = createAsyncThunk(`${SLICE_NAME}/delete`, async (userId: string) => {
  const res = await adminUsersApi.delete.invoke(userId);

  return res.data;
});

export const createUser = createAsyncThunk(`${SLICE_NAME}/create`, async (userId: string, { dispatch }: any) => {
  const res = await adminUsersApi.create.invoke(userId).catch((e) => {
    const errorMessage = e?.response?.data?.error || 'There was an error creating the user. Please try again.';

    dispatch(addToast({ text: errorMessage }));
    throw e;
  });

  return res.data;
});

export const updateUser = createAsyncThunk(`${SLICE_NAME}/update`, async (user: Partial<User>, { dispatch }: any) => {
  const res = await adminUsersApi.update.invoke(user.id, user).catch((e) => {
    const errorMessage = e?.response?.data?.error || 'There was an error updating the user. Please try again.';

    dispatch(addToast({ text: errorMessage }));
    throw e;
  });

  return res.data;
});

export const reactivateUser = createAsyncThunk(`${SLICE_NAME}/reactivate`, async (params: any, { dispatch }: any) => {
  const res = await adminUsersApi.reactivate.invoke(params).catch((e) => {
    const errorMessage = e?.response?.data?.message || 'There was an error reactivating the user. Please try again.';

    dispatch(addToast({ text: errorMessage }));
    throw e;
  });

  return res.data;
});

export const usersAdapter = createEntityAdapter();

export const initialState = usersAdapter.getInitialState({
  ids: [],
  entities: {},
  pagination: new PaginationState(),
});

const usersSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearUsers: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.fulfilled, (state: any, { payload: { links, meta, data } }) => {
        state.pagination = { links, meta };
        usersAdapter.setAll(state, data);
      })
      .addCase(reactivateUser.fulfilled, usersAdapter.upsertOne)
      .addCase(deleteUser.fulfilled, usersAdapter.upsertOne);
  },
});

export const { clearUsers } = usersSlice.actions;

const getUsersState = (state: any) => state[SLICE_NAME];

export const { selectAll: getUsers } = usersAdapter.getSelectors(getUsersState);

export const getUsersLoaded = createApiHasStatusSelector(fetchUsers, [API_STATES.complete, API_STATES.failed]);

export const getUsersPageCount = (state: any) => getUsersState(state).pagination.meta.totalPages;
export const getUsersTotalRecords = (state: any) => getUsersState(state).pagination.meta.totalRecords;

export default usersSlice;
