import { createAsyncThunk } from '~/lib';
import { exportsApi } from '~/services/api';

const sliceName = 'exports';

export const fetchExport = createAsyncThunk(`${sliceName}/fetchById`, async (id) => {
  const { data } = await exportsApi.fetchById(id);

  return data;
});
