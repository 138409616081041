import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '~/lib';
import { Client } from '~/models';
import { adminClientsApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from '../api';

import { updateCollaboration } from './collaborations';

const SLICE_NAME = 'admin/clients';

const getClientsState = (state) => state[SLICE_NAME];

const cancellableFetchClients = adminClientsApi.fetch.cancellable();

export const fetchClients = createAsyncThunk(
  'admin/clients/fetch',
  async (params) => {
    const defaults = { pageSize: 1000, sortBy: 'name' };
    const res = await cancellableFetchClients({ ...defaults, ...params });

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Client,
  }
);

export const fetchClient = createAsyncThunk(
  'admin/clients/fetchById',
  async ({ id, ...params }) => {
    const res = await adminClientsApi.fetchById.invoke(id, params);

    return res.data;
  },
  {
    modelClass: Client,
  }
);

export const createClient = createAsyncThunk('admin/clients/create', async (newClient) => {
  const res = await adminClientsApi.create.invoke(newClient);

  return res.data;
});

export const updateClient = createAsyncThunk('admin/clients/update', async ({ id, ...params }) => {
  const res = await adminClientsApi.update.invoke(id, params);

  return res.data;
});

const clientsAdapter = createEntityAdapter();

const initialPaginationState = {
  links: {
    next: null,
    prev: null,
  },
  meta: {
    totalRecords: 0,
    totalPages: 0,
  },
};

const initialState = clientsAdapter.getInitialState({
  pagination: initialPaginationState,
});

const clientsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearClients: () => initialState,
  },
  extraReducers: {
    [fetchClients.fulfilled]: (state, { payload: { links, meta, data } }) => {
      state.pagination = { links, meta };
      clientsAdapter.upsertMany(state, data);
    },
    [fetchClient.fulfilled]: clientsAdapter.upsertOne,
    [createClient.fulfilled]: clientsAdapter.addOne,
    [updateClient.fulfilled]: (state, { payload }) => {
      const { id, ...rest } = payload;

      clientsAdapter.updateOne(state, { id, changes: { ...rest } });
    },
    [updateCollaboration.fulfilled]: (state, { payload }) => {
      const { ownerId: id, collaboratorIds } = payload;

      clientsAdapter.updateOne(state, { id, changes: { collaboratorIds } });
    },
  },
});

export const { clearClients } = clientsSlice.actions;

export const getClientsLoaded = createApiHasStatusSelector(fetchClients, [API_STATES.complete, API_STATES.failed]);

export const { selectAll: getClients, selectById: getClient } = clientsAdapter.getSelectors(getClientsState);

export const getClientsPageCount = (state) => getClientsState(state).pagination.meta.totalPages;

export default clientsSlice;
