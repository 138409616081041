import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ControlledTable } from '~/components/shared/table';
import {
  clearCollaborations,
  fetchCollaborations,
  getCollaborations,
  getCollaborationsLoaded,
  getCollaborationsPageCount,
} from '~/ducks/admin/collaborations';
import {
  clearFilters,
  getCollaborationsFilters,
  getCollaborationsFiltersForRequest,
  setFilter,
} from '~/ducks/admin/collaborationsFilters';
import { useModel } from '~/lib/hooks';
import { Collaboration } from '~/models';
import { useProfileContext } from '~/services/profile';

import collaborationsTableColumns from './collaborationsTableColumns';

function CollaborationsTable({ filters, filtersForRequest, pageCount, loaded, onCollaborationEdit, ...props }) {
  const profileSvc = useProfileContext();
  const [pagingFilters, setPagingFilters] = useState({});
  const debouncedFetchCollaborations = useCallback(
    _.debounce((params) => props.fetchCollaborations(params), 50),
    []
  );
  const collaborations = useModel(Collaboration, props.collaborations);

  useEffect(() => {
    props.clearCollaborations();
    debouncedFetchCollaborations({ ...pagingFilters, ...filtersForRequest });
  }, [pagingFilters, filtersForRequest]);

  const handlePagingFiltersChange = useCallback((newPagingFilters) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const columns = useMemo(() => collaborationsTableColumns({ profileSvc, onEdit: onCollaborationEdit }), []);

  return (
    <ControlledTable
      data={collaborations}
      defaultSortBy={'name asc'}
      loading={!loaded}
      columns={columns}
      filters={filters}
      onPagingFiltersChange={handlePagingFiltersChange}
      pageCount={pageCount}
    />
  );
}
CollaborationsTable.propTypes = {
  clearCollaborations: PropTypes.func,
  clearFilters: PropTypes.func.isRequired,
  collaborations: PropTypes.instanceOf(Array),
  fetchCollaborations: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  filtersForRequest: PropTypes.instanceOf(Object),
  loaded: PropTypes.bool,
  onCollaborationEdit: PropTypes.func.isRequired,
  pageCount: PropTypes.number,
  setFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  collaborations: getCollaborations(state),
  filters: getCollaborationsFilters(state),
  filtersForRequest: getCollaborationsFiltersForRequest(state),
  pageCount: getCollaborationsPageCount(state),
  loaded: getCollaborationsLoaded(state),
});

const mapDispatchToProps = {
  clearCollaborations,
  clearFilters,
  fetchCollaborations,
  setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollaborationsTable);
