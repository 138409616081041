import { ApiRequest, CreateRequest, FetchRequest } from './core';

const url = '/me';

export default {
  profile: new FetchRequest({ url: `${url}/profile` }),
  updateAgreements: new CreateRequest({ url: `${url}/accept_agreements` }),
  update: new ApiRequest({
    formatArgsForConfig: (data) => ({ data }),
    method: 'put',
    url,
  }),
};
