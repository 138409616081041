import { createAsyncThunk } from '~/lib';
import { Role } from '~/models';
import { adminRolesApi } from '~/services/api';

const SLICE_NAME = 'admin/roles';

const cancellableFetchRoles = adminRolesApi.fetch.cancellable();

export const fetchRoles = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (payload) => {
    const res = await cancellableFetchRoles(payload);

    return res.data.items;
  },
  {
    defaultValue: [],
    modelClass: Role
  }
);
