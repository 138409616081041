import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { termsOfServiceApi } from '~/services/api';

function TOSLink({ children }) {
  const [clicked, setClicked] = useState(false);
  const [tosURL, setTosURL] = useState('');

  const handleOpenDocument = (url) => {
    window.open(url, '_blank');
  };

  const openDocumentIfClickedAndPresent = () => {
    const url = tosURL;

    if (clicked && url !== '') {
      handleOpenDocument(url);
      setClicked(false);
      setTosURL('');
    }
  };

  const handleClick = () => {
    setClicked(true);
    termsOfServiceApi.fetch.invoke()
      .then((res) => setTosURL(res.data.url));
  };

  useEffect(openDocumentIfClickedAndPresent, [clicked, tosURL]);

  return (
    <Link onClick={ handleClick }>
      { children }
    </Link>
  );
}

export default TOSLink;

const Link = styled.a`
  cursor: pointer;
`;
