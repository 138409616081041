import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { unwrapResult } from '@reduxjs/toolkit';

import { ConfirmationModal } from '~/components/shared/modal';
import { MainPage } from '~/components/shared/pageLayout';
import { ControlledTable } from '~/components/shared/table';
import { ImportAction } from '~/constants/imports';
import { fetchActiveStorageAttachment } from '~/ducks/activeStorageAttachments';
import { clearFilters, getImportFilters, getImportFiltersForRequest, setFilter } from '~/ducks/admin/importFilters';
import { fetchImports, updateImport } from '~/ducks/admin/imports';
import { addToast } from '~/ducks/toasts';
import { useThunk } from '~/lib/hooks';

import ImportFilterBar from './ImportFilterBar';
import importHistoryTableColumns from './importHistoryTableColumns';
function ImportHistory(props) {
  const { filters, filtersForRequest } = props;
  const [pageCount, setPageCount] = useState(0);
  const [pagingFilters, setPagingFilters] = useState({});
  const [updatedImport, setUpdatedImport] = useState(null);
  const [selectedImport, setSelectedImport] = useState(null);

  useEffect(() => {
    props.clearFilters();
  }, []);

  const { data: imports, loaded: importsLoaded } = useThunk(
    fetchImports,
    [pagingFilters, filtersForRequest, updatedImport],
    {
      onSuccess: (payload) => {
        setPageCount(payload?.meta?.totalPages);
      },
      params: { ...pagingFilters, ...filtersForRequest, include: 'child,client,file,stepCounts' },
      debounce: 50,
    }
  );

  const handleViewStatusDetails = useCallback((importObj) => {
    props.history.push(`/import-history/${importObj.id}/status-details`);
  }, []);

  const handleImportAction = async (importObj, action) => {
    const payload = {
      id: importObj.id,
      invokeAction: action,
      include: 'child,client,steps',
    };

    await props.updateImport(payload).then(unwrapResult).then(setUpdatedImport);
  };

  const handleCancelImport = useCallback((importObj) => {
    setSelectedImport(importObj);
  }, []);

  const handleModalConfirm = useCallback(() => {
    handleImportAction(selectedImport, ImportAction.CANCEL);

    setSelectedImport(null);
  }, [selectedImport]);

  const handleModalCancel = useCallback(() => {
    setSelectedImport(null);
  }, []);

  const handlePagingFiltersChange = useCallback((newPagingFilters) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const handleDownloadAttachment = async (attachmentId) => {
    props
      .fetchActiveStorageAttachment({ id: attachmentId, include: 'url' })
      .then((res) => window.open(res.payload.url, '_blank'));
  };

  const columns = useMemo(
    () =>
      importHistoryTableColumns({
        onCancelImport: (importObj) => handleCancelImport(importObj),
        onRestartImport: (importObj) => handleImportAction(importObj, ImportAction.RESTART),
        onResumeImport: (importObj) => handleImportAction(importObj, ImportAction.RUN),
        onViewStatusDetails: handleViewStatusDetails,
        handleDownloadAttachment,
      }),
    []
  );

  return (
    <MainPage title='Import History'>
      <ImportFilterBar clearFilters={props.clearFilters} filters={filters} setFilter={props.setFilter} />
      <ControlledTable
        data={imports}
        defaultSortBy={'createdAt desc'}
        loading={!importsLoaded}
        columns={columns}
        filters={filters}
        onPagingFiltersChange={handlePagingFiltersChange}
        pageCount={pageCount}
      />
      <ConfirmationModal
        show={Boolean(selectedImport)}
        actionText='cancel'
        objType='import'
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
      />
    </MainPage>
  );
}

ImportHistory.propTypes = {
  addToast: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  fetchActiveStorageAttachment: PropTypes.func,
  fetchImports: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object),
  filtersForRequest: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object).isRequired,
  setFilter: PropTypes.func.isRequired,
  updateImport: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: getImportFilters(state),
  filtersForRequest: getImportFiltersForRequest(state),
});

const mapDispatchToProps = {
  addToast,
  fetchActiveStorageAttachment,
  clearFilters,
  fetchImports,
  setFilter,
  updateImport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportHistory);
