import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FieldLabel } from '~/components/shared/form';
import RadioButton from '~/components/shared/inputs/RadioButton';
import { verticalCenter } from '~/styles/mixins';

const RadioButtonGroup = ({
  className,
  label,
  name,
  value,
  onChange
}) => {
  const handleChange = (newValue) => {
    onChange(newValue);
  };

  return (
    <div className={ className }>
      <FieldLabel>{ label }</FieldLabel>
      <RadioButtonContainer>
        <RadioButton radioValue='yes'
          name={ name }
          value={ value }
          radioLabel='Yes'
          onChange={ handleChange } />
        <RadioButton radioValue='no'
          name={ name }
          value={ value }
          radioLabel='No'
          onChange={ handleChange } />
      </RadioButtonContainer>
    </div>
  );
};

RadioButtonGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default RadioButtonGroup;

export const RadioButtonContainer = styled.div`
  ${ verticalCenter() };
  margin-top: 10px;

  & > * {
    margin-right: 16px;
  }
`;
