import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import DischargeReasonsFlyoutMenu from '~/components/dischargeReasons/DischargeReasonsFlyoutMenu';
import { BodySmallBold } from '~/components/shared/typography';
import { DischargeReason } from '~/models';
import { PERMISSIONS } from '~/services/profile';

import EditFlyoutMenu from '../shared/FlyoutMenu/EditFlyoutMenu';
const columnHelper = createColumnHelper<DischargeReason>();

type ColumnFnProps = {
  profileSvc: any;
} & React.ComponentProps<typeof EditFlyoutMenu>;

export default function dischargeReasonsTableColumns({ profileSvc, ...flyoutMenuProps }: ColumnFnProps) {
  const columns = [
    columnHelper.accessor((row) => row.displayName, {
      id: 'displayName',
      header: 'Discharge Disposition',
      cell: (info) => <BodySmallBold>{info.getValue() ? info.getValue() : <>&mdash;</>}</BodySmallBold>,
    }),
    columnHelper.accessor((row) => row.dischargedToGroupType, {
      id: 'dischargedToGroupType',
      header: 'Linked Group Type',
      cell: (info) => (info.getValue() ? info.getValue()?.displayName : <>&mdash;</>),
      enableSorting: false,
    }),
    columnHelper.accessor((row) => row.groupTypeDisplayNames, {
      id: 'groupTypeDisplayNames',
      header: 'Displayed To',
      cell: (info) => (info.getValue() ? info.getValue() : <>&mdash;</>),
      enableSorting: false,
    }),
  ];

  if (profileSvc.has(PERMISSIONS.adminDischargeReasonEdit)) {
    columns.push(
      columnHelper.display({
        id: 'menu',
        cell: (info) => <DischargeReasonsFlyoutMenu {...flyoutMenuProps} row={info.row} />,
        size: 75,
        meta: { style: { justifyContent: 'flex-end' } },
      })
    );
  }

  return columns;
}
