import {
  EPISODE,
  EPISODE_CLASS,
  PLAN_TYPE,
  PLAN_TYPE_CLASS
} from '~/constants/classifications';

const defaults = {
  id: '',
  name: '',
  type: null,
  clientIds: [],
  clientNames: []

};

export const classificationType = (type) => {
  switch (type) {
    case PLAN_TYPE_CLASS:
      return PLAN_TYPE;
    case EPISODE_CLASS:
      return EPISODE;
    default:
      return null;
  }
};

const classificationClass = (type) => {
  switch (type) {
    case PLAN_TYPE:
      return PLAN_TYPE_CLASS;
    case EPISODE:
      return EPISODE_CLASS;
    default:
      return null;
  }
};

export default class Classification {
  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.name = opts.name;
    this.type = classificationType(opts.type);
    this.clientIds = opts.clientIds;
    this.clientNames = opts.clientNames;
  }

  get isPlanType() {
    return this.type === PLAN_TYPE;
  }

  get isEpisode() {
    return this.type === EPISODE;
  }

  serialize() {
    return {
      id: this.id,
      name: this.name,
      type: classificationClass(this.type)
    };
  }
}
