import React from 'react';
import PropTypes from 'prop-types';

import { StandardModal } from '~/components/shared/modal';

import ClassificationForm from './ClassificationForm';

function ClassificationModal(props) {
  const { isOpen, onCancel, classificationId, type, title } = props;

  return (
    <StandardModal
      show={isOpen}
      title={`${classificationId ? 'Edit' : 'New'} ${title}`}
      onCancel={onCancel}
      disableBackdropClick>
      <ClassificationForm onComplete={onCancel} title={title} type={type} classificationId={classificationId} />
    </StandardModal>
  );
}

ClassificationModal.propTypes = {
  classificationId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default ClassificationModal;
