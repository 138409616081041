import * as Yup from 'yup';

export const attrValueFormValidation =
  Yup.object().shape({
    name: Yup.string().required('Raw value is required'),
    client: Yup.object().shape({
      id: Yup.string(),
      name: Yup.string()
    }).nullable().test('client', 'Client is required', (obj) => Boolean(obj && obj.id)),
    attr: Yup.object().shape({
      id: Yup.string(),
      name: Yup.string()
    }).nullable().test('attr', 'Attribute is required', (obj) => Boolean(obj && obj.id)),
    displayName: Yup.string().required('Display value is required'),
    visible: Yup.boolean(),
  });
