import React from 'react';

function ArrowIcon() {
  return (
    <svg width='12px' height='11px' viewBox='0 0 12 11' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>arrow-right</title>
      <g id='ProposedMVP_Walkthrough' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' fillOpacity='0.25'>
        <g
          id='ImportConfiguration_AddEdit'
          transform='translate(-733.000000, -533.000000)'
          fill='#0F1226'
          fillRule='nonzero'>
          <g id='Group-6' transform='translate(489.000000, 502.000000)'>
            <g id='arrow-right' transform='translate(244.000000, 31.000000)'>
              <path
                d='M1,6.00155635 L8.586,6.00155635 L6.293,8.29455635 C6.03304342,8.54563082 5.9287874,8.91743424 6.02030284,9.26706367 C6.11181828,9.6166931 6.38486324,9.88973806 6.73449268,9.9812535 C7.08412211,10.0727689 7.45592553,9.96851293 7.707,9.70855635 L11.707,5.70855635 C11.8950834,5.52094782 12.0007876,5.26621087 12.0007876,5.00055635 C12.0007876,4.73490183 11.8950834,4.48016487 11.707,4.29255635 L7.707,0.292556347 C7.3159824,-0.0979089713 6.68246532,-0.0974612561 6.292,0.293556347 C5.90153468,0.68457395 5.9019824,1.31809103 6.293,1.70855635 L8.586,4.00155635 L1,4.00155635 C0.44771525,4.00155635 0,4.4492716 0,5.00155635 C0,5.5538411 0.44771525,6.00155635 1,6.00155635 Z'
                id='Path'></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default ArrowIcon;
