import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '~/lib';
import { Collaboration } from '~/models';
import { collaborationsApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from '../api';
import PaginationState from '../PaginationState';

const sliceName = 'admin/collaborations';

const cancellableFetchCollaborations = collaborationsApi.fetch.cancellable();

export const fetchCollaborations = createAsyncThunk(
  `${sliceName}/fetch`,
  async (params) => {
    const res = await cancellableFetchCollaborations(params);

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Collaboration,
  }
);

export const updateCollaboration = createAsyncThunk(`${sliceName}/update`, async (params) => {
  const res = await collaborationsApi.update.invoke(params.id, params);

  return res.data;
});

const collaborationsAdapter = createEntityAdapter({
  selectId: ({ ownerId }) => ownerId,
});

export const initialState = collaborationsAdapter.getInitialState({
  pagination: new PaginationState(),
});

const collaborationsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearCollaborations: () => initialState,
  },
  extraReducers: {
    [fetchCollaborations.fulfilled]: (state, { payload: { links, meta, data } }) => {
      state.pagination = { links, meta };
      collaborationsAdapter.setAll(state, data);
    },
    [updateCollaboration.fulfilled]: (state, action) => {
      if (action.payload.collaboratorIds.length === 0) {
        collaborationsAdapter.removeOne(state, action.payload.ownerId);
      } else {
        collaborationsAdapter.upsertOne(state, action);
      }
    },
  },
});

export const { clearCollaborations } = collaborationsSlice.actions;

const getCollaborationsState = (state) => state[sliceName];

export const { selectAll: getCollaborations } = collaborationsAdapter.getSelectors(getCollaborationsState);

export const getCollaborationsLoaded = createApiHasStatusSelector(fetchCollaborations, [
  API_STATES.complete,
  API_STATES.failed,
]);

export const getCollaborationsPageCount = (state) => getCollaborationsState(state).pagination.meta.totalPages;

export const getCollaborationsTotalRecords = (state) => getCollaborationsState(state).pagination.meta.totalRecords;

export default collaborationsSlice;
