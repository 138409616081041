import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  
  button {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export default ButtonGroup;
