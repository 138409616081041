import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RadioButtonChecked, RadioButtonUnchecked } from '~/components/shared/svg';
import { Label } from '~/components/shared/typography';
import useField from '~/lib/hooks/useField';
import { verticalCenter } from '~/styles/mixins';

function RadioButtonGroup(props) {
  const { options, getOptionValue, getOptionLabel } = props;
  const [field, , helpers] = useField(props);
  const { name, value } = field;

  useEffect(() => {
    if (!value && options.length) {
      handleChange(getOptionValue(options[0]));
    }
  }, [options]);

  const handleChange = (val) => {
    helpers.setValue(val);
    props.onChange(val, name, helpers.setValue);
  };

  return (
    <GroupContainer>
      {options.map((option) => {
        const val = getOptionValue(option);
        const label = getOptionLabel(option);

        return (
          <RadioButton key={val} name={name} onClick={() => handleChange(val)}>
            {value !== null && value === val ? <RadioButtonChecked size={18} /> : <RadioButtonUnchecked size={18} />}
            {label && <RadioLabel>{label}</RadioLabel>}
          </RadioButton>
        );
      })}
    </GroupContainer>
  );
}

RadioButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  menuPlacement: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
};

RadioButtonGroup.defaultProps = {
  disabled: false,
  getOptionLabel: ({ label }) => label,
  getOptionValue: ({ value }) => value,
  menuPlacement: 'auto',
  onChange: () => {},
};

export default RadioButtonGroup;

const GroupContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 4px;
`;

const RadioButton = styled.div`
  ${verticalCenter()};
  margin-right: 16px;
`;

const RadioLabel = styled(Label)`
  margin-left: 9px;
`;
