import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ImportStatus as ImportStatusEnum } from '~/constants/imports';
import { colors } from '~/styles/theme';

const statusToColor = {
  [ImportStatusEnum.PENDING]: colors.black25,
  [ImportStatusEnum.RUNNING]: colors.primaryBlue,
  [ImportStatusEnum.PAUSED]: colors.accentYellow,
  [ImportStatusEnum.COMPLETED]: colors.accentGreen,
  [ImportStatusEnum.ERRORED]: colors.accentRed,
  [ImportStatusEnum.CANCELED]: colors.black,
};

export default function ImportStatus({ customStyles, status }) {
  return (
    <StatusContainer>
      <StatusCircle customStyles={customStyles} status={status} />
      <Label>{status}</Label>
    </StatusContainer>
  );
}

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  text-transform: capitalize;
`;

const StatusCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: ${(props) => statusToColor[props.status]};
  margin: ${({ customStyles }) => customStyles.margin || '0 12px 0 0'};
`;

ImportStatus.defaultProps = {
  customStyles: {},
};

ImportStatus.propTypes = {
  customStyles: PropTypes.instanceOf(Object),
  status: PropTypes.string,
};
