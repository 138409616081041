import { DELIMITER_OPTS } from '~/constants/importConfigurations';

import Client from './Client';

const defaults = {
  id: '',
  client: new Client(),
  clientId: '',
  config: {},
  type: '',
  updatedAt: new Date().toISOString(),
};

export default class ImportConfiguration {
  constructor(options) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.client = new Client(opts.client);
    this.clientId = opts.clientId;
    this.config = opts.config;
    this.type = opts.type;
    this.updatedAt = opts.updatedAt;
  }

  get clientName() {
    return this.client.name;
  }

  get clientType() {
    return this.client.clientType;
  }

  toFormValues() {
    return {
      ...this,
      config: {
        ...this.config,
        dateFormat: this.config.dateFormat || '%m/%d/%Y',
        delimiter: DELIMITER_OPTS.find((opt) => opt.value === this.config.delimiter),
      },
    };
  }

  serialize() {
    return {
      id: this.id,
      clientId: this.client.id,
      config: {
        ...this.config,
        delimiter: this.config.delimiter.value,
      },
      type: this.type,
    };
  }
}
