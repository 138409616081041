import React from 'react';
import PropTypes from 'prop-types';

import { Permission } from '~/models';

import Section from './Section';

function AdminSection({ permissions }) {
  return (
    <Section label='Admin Permissions' permissions={ permissions } />
  );
}

AdminSection.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.instanceOf(Permission)).isRequired
};

AdminSection.defaultProps = {
  permissions: []
};

export default AdminSection;
