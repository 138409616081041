import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import EditFlyoutMenu from '~/components/shared/FlyoutMenu/EditFlyoutMenu';
import { DeactivateIcon } from '~/components/shared/svg';
import { ParentLabel } from '~/components/shared/table';
import { BodySmall, BodySmallBold } from '~/components/shared/typography';
import { ATTR_OLIO_MAPPED_FIELDS } from '~/constants/attrMappedFields';
import { Attr } from '~/models';
import { PERMISSIONS } from '~/services/profile';
import { colors } from '~/styles/theme';

const columnHelper = createColumnHelper<Attr>();

type ColumnFnProps = {
  profileSvc: any;
} & React.ComponentProps<typeof EditFlyoutMenu>;

export default function attributesTableColumns({ profileSvc, ...editFlyoutMenuProps }: ColumnFnProps) {
  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Raw Attribute',
      cell: (info) => {
        const attribute = info.row.original;

        if (!attribute.active) {
          return (
            <React.Fragment>
              <DeactivateIcon fill={colors.black25} size={16} />
              <BodySmallBold color={colors.black25} margin='0 0 0 8px'>
                {info.getValue()}
              </BodySmallBold>
            </React.Fragment>
          );
        }

        return <BodySmallBold>{info.getValue()}</BodySmallBold>;
      },
    }),
    columnHelper.accessor((row) => row.olioAttr, {
      id: 'olioAttr',
      header: 'Mapped Olio Field',
      cell: (info) => {
        const label = ATTR_OLIO_MAPPED_FIELDS.find((i) => i.value === info.getValue())?.label;

        return info.row.original.olioAttr && label ? label : <>&mdash;</>;
      },
    }),
    columnHelper.accessor((row) => row.displayName, {
      id: 'displayName',
      header: 'Display Attribute',
      cell: (info) => {
        return info.getValue() ? info.getValue() : <>&mdash;</>;
      },
    }),
    columnHelper.accessor((row) => row.client?.name, {
      id: 'clientName',
      header: 'Client',
      cell: (info) => {
        const isParent = !info.row?.original?.client?.leaf;

        return (
          <BodySmall>
            {info.getValue()}
            {isParent && <ParentLabel />}
          </BodySmall>
        );
      },
    }),
  ];

  if (profileSvc.has(PERMISSIONS.adminAttrEdit)) {
    columns.push(
      columnHelper.display({
        id: 'menu',
        cell: (info) => (
          <EditFlyoutMenu {...editFlyoutMenuProps} row={info.row} permission={PERMISSIONS.adminAttrEdit} />
        ),
        size: 75,
        meta: { style: { justifyContent: 'flex-end' } },
      })
    );
  }

  return columns;
}
