import { createAsyncThunk } from '~/lib';
import { Client } from '~/models';
import { clientsApi } from '~/services/api';

const cancellableFetchClients = clientsApi.fetch.cancellable();

export const fetchClients = createAsyncThunk(
  'clients/fetch',
  async (params) => {
    const res = await cancellableFetchClients(params);

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Client
  }
);

export const fetchClientsForResource = createAsyncThunk(
  'clients/fetchForResource',
  async (resource) => {
    const res = await clientsApi.fetchForResource(resource);

    return res.data;
  }
);
