import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import InfiniteScrollMenuList from './InfiniteScrollMenuList';

function InfiniteScrollWrapper(props) {
  const { component, components, getNextPage, loading, options, hasMore, setSearch, onChange, onInputChange, ...rest } =
    props;
  const [menuListElement, setMenuListElement] = useState(null);

  const handleInputChange = (newValue, actionMeta) => {
    setSearch(onInputChange(newValue, actionMeta));
  };

  const ReactSelectComponent = component;
  const infiniteScrollProps = {
    defaultOptions: true,
    hasMore,
    isLoading: loading,
    loadingMessage: () => '',
    onEndReached: getNextPage,
    onInputChange: handleInputChange,
    options,
  };

  const shouldGetNextPage = () => {
    const element = menuListElement;

    if (element) {
      const boundingRect = element.getBoundingClientRect();

      // The height of the dropdown isn't big enough to scroll and there are
      // more options to retrieve.
      return hasMore && !loading && element.scrollHeight <= boundingRect.height + 50;
    }

    return false;
  };

  const tryGetNextPage = () => {
    if (shouldGetNextPage()) {
      getNextPage();
    }
  };

  const handleOnChange = (newValue, actionMeta) => {
    tryGetNextPage();

    onChange(newValue, actionMeta);
  };

  useEffect(() => {
    tryGetNextPage();
  }, [options, menuListElement]);

  return (
    <ReactSelectComponent
      {...rest}
      {...infiniteScrollProps}
      bindMenuListRef={setMenuListElement}
      onChange={handleOnChange}
      components={{
        ...components,
        LoadingIndicator: null,
        MenuList: InfiniteScrollMenuList,
      }}
    />
  );
}

const noop = () => {};
const identity = (x) => x;

InfiniteScrollWrapper.defaultProps = {
  getNextPage: noop,
  loading: false,
  onChange: noop,
  onInputChange: identity,
  setSearch: noop,
};

InfiniteScrollWrapper.propTypes = {
  component: PropTypes.instanceOf(Object),
  components: PropTypes.instanceOf(Object),
  getNextPage: PropTypes.func,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  setSearch: PropTypes.func,
};

export default InfiniteScrollWrapper;
