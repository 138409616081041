import React from 'react';
import PropTypes from 'prop-types';

import { Permission } from '~/models';

import Section from './Section';

function NonAdminSection({ permissions }) {
  return (
    <Section label='User Permissions' permissions={ permissions } />
  );
}

NonAdminSection.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.instanceOf(Permission)).isRequired
};

NonAdminSection.defaultProps = {
  permissions: []
};

export default NonAdminSection;
