export enum UserPreferenceType {
  NOTIFICATION = 'notification',
}

export interface UserPreferenceOptions {
  id: string;
  clientId: string;
  type: UserPreferenceType;
  value: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const defaults: UserPreferenceOptions = {
  id: '',
  clientId: '',
  type: UserPreferenceType.NOTIFICATION,
  value: {},
};

export default class UserPreference {
  id: string;
  clientId: string;
  type: UserPreferenceType;
  value: Record<string, any>; // eslint-disable-line @typescript-eslint/no-explicit-any

  constructor(options: Partial<UserPreferenceOptions> = defaults) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.clientId = opts.clientId;
    this.type = opts.type;
    this.value = opts.value;
  }

  get isNotification() {
    return this.type === UserPreferenceType.NOTIFICATION;
  }

  serialize() {
    return this;
  }
}
