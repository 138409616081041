import styled from 'styled-components';

const PageContentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 40px;
  flex: 1;
  box-sizing: border-box;
`;

export default PageContentContainer;
