import { createAsyncThunk } from '~/lib';
import { jobProcessesApi } from '~/services/api';

const sliceName = 'jobProcesses';

export const fetchJobProcess = createAsyncThunk(
  `${sliceName}/fetchById`,
  async (id) => {
    const { data } = await jobProcessesApi.fetchById.invoke(id);

    return data;
  }
);
