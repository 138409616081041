import * as _ from 'lodash-es';

import { NUMERIC, RANGE, STATUS, YES_NO } from '~/constants/activities';
import { DISCHARGE } from '~/constants/questionTemplateTypes';

import RangeOrNumericQuestion from './inputs/RangeOrNumericQuestion';
import StatusQuestion from './inputs/StatusQuestion';
import YesNoQuestion from './inputs/YesNoQuestion';

const questionsConfigurations = {
  [YES_NO]: {
    inputLabel: 'Yes/No',
    component: YesNoQuestion,
  },
  [RANGE]: {
    inputLabel: 'Scale 1-10',
    component: RangeOrNumericQuestion,
  },
  [NUMERIC]: {
    inputLabel: 'Numeric',
    component: RangeOrNumericQuestion,
  },
  [STATUS]: {
    inputLabel: 'Status',
    component: StatusQuestion,
  },
};

const dischargeQuestionTypes = [YES_NO];

const questionOptions = _.keys(questionsConfigurations).map(function (key) {
  return {
    label: questionsConfigurations[key].inputLabel,
    value: key,
  };
});

const questionTypeOptionsForTemplateType = (templateType) => {
  let options = questionOptions;

  if (templateType === DISCHARGE) {
    options = options.filter((opt) => dischargeQuestionTypes.includes(opt.value));
  }

  return options;
};

export { questionsConfigurations as default, questionTypeOptionsForTemplateType };
