import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { fetchProfile, updateProfile } from '~/ducks/profile';
import { Profile } from '~/models';
import { authenticationFlow, authService } from '~/services/auth';

import theme from '../styles/theme';

import { AgreementModal } from './agreements';
import { SessionMonitor } from './auth';
import { Home } from './home';

function App(props) {
  const location = useLocation();
  // ensure initial authentication has completed before running other useEffect functions
  const [initialized, setInitialized] = useState(false);

  // This is the main spot for triggering our entire authentication flow.
  useEffect(() => {
    const initialize = async () => {
      const { flow } = await authService.authenticate(props.location.pathname);

      if (flow === authenticationFlow.error) {
        return props.history.push('/error');
      }

      const authenticated = await authService.isAuthenticated();

      if (authenticated) {
        const response = await props.fetchProfile({
          include:
            'client.leaf_descendants,preferences,acting_client.group_types,enabled_provider_types,selected_group_ids,allowed_group_ids',
        });
        const profile = new Profile(response.payload);
        const profileUpdateParams = {};
        const isLoginRedirectCallback = flow === authenticationFlow.loginRedirectCallback;

        if (isLoginRedirectCallback) {
          profileUpdateParams.lastLogin = new Date();
        }

        // automatically set acting client if you have child clients and no acting client set
        if (!profile.actingClientId && profile.client.leafDescendants.length) {
          profileUpdateParams.actingClientId = profile.client.leafDescendantIds[0];
        }

        if (Object.keys(profileUpdateParams).length > 0) {
          profileUpdateParams.include = 'acting_client.group_types,preferences,selected_group_ids,allowed_group_ids';

          await props.updateProfile(profileUpdateParams);
        }

        if (isLoginRedirectCallback) {
          props.history.push({
            pathname: authService.redirectPath,
            state: { initialLogin: true },
          });
        }

        setInitialized(true);
      }

      return null;
    };

    initialize();
  }, []);

  // This is the main spot for checking whether the current user's session is active.
  // The logic used to live in ProtectedRoute but it is elevated up to the root level now.
  useEffect(() => {
    const checkSession = async () => {
      try {
        await authService.renewSession();
      } catch (e) {
        authService.logout();
      }
    };

    if (initialized) {
      checkSession();
    }
  }, [initialized, location]);

  const renderHome = (routeProps) => (initialized ? <Home {...routeProps} /> : <CircleSpinner centered />);

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path='/agreements' component={AgreementModal} />
        <Route path='' render={renderHome} />
      </Switch>
      <SessionMonitor />
    </ThemeProvider>
  );
}

App.propTypes = {
  fetchProfile: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
};

export default connect(null, { fetchProfile, updateProfile })(App);
