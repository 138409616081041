import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import { BodySmallBold } from '~/components/shared/typography';
import { GroupType } from '~/models';
import { PERMISSIONS } from '~/services/profile';

import EditFlyoutMenu from '../shared/FlyoutMenu/EditFlyoutMenu';
const columnHelper = createColumnHelper<GroupType>();

type ColumnFnProps = {
  profileSvc: any;
} & React.ComponentProps<typeof EditFlyoutMenu>;

export default function groupTypesTableColumns({ profileSvc, ...editFlyoutMenuProps }: ColumnFnProps) {
  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Standard Name',
      cell: (info) => <BodySmallBold>{info.getValue()}</BodySmallBold>,
    }),
    columnHelper.accessor((row) => row.displayName, {
      id: 'displayName',
      header: 'Display Name',
    }),
    columnHelper.accessor((row) => row.type.displayName, {
      id: 'type',
      header: 'Category',
    }),
    columnHelper.accessor((row) => row.isInpatient, {
      id: 'isInpatient',
      header: 'Inpatient?',
      cell: (info) => <>{info.getValue() ? 'Yes' : 'No'}</>,
    }),
  ];

  if (profileSvc.has(PERMISSIONS.adminGroupTypeEdit)) {
    columns.push(
      columnHelper.display({
        id: 'menu',
        cell: (info) => (
          <EditFlyoutMenu {...editFlyoutMenuProps} permission={PERMISSIONS.adminGroupTypeEdit} row={info.row} />
        ),
        size: 75,
        meta: { style: { justifyContent: 'flex-end' } },
      })
    );
  }

  return columns;
}
