import { immerable } from 'immer';

import Client from '~/models/Client';
import User from '~/models/User';

import { ALL_FLAGS } from './Flag';

const defaults = {
  isAcute: false,
  acceptedTermsOfService: false,
  allowedGroupIds: [],
  client: new Client(),
  createdAt: '',
  lastLogin: '',
};

export default class Profile extends User {
  [immerable] = true;

  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    super(opts);

    this.isAcute = opts.actingAsAcute ?? opts.isAcute;
    this.acceptedTermsOfService = opts.acceptedTermsOfService;
    this.allowedGroupIds = opts.allowedGroupIds;
    this.client = new Client(opts.client);
    this.createdAt = opts.createdAt;
    this.lastLogin = opts.lastLogin;
  }

  get isPostAcute() {
    return !this.isAcute;
  }

  get isInpatientPostAcute() {
    return this.actingClient.isInpatientPostAcute;
  }

  get isOutpatientPostAcute() {
    return this.actingClient.isOutpatientPostAcute;
  }

  get hasImportImprovements() {
    return this.flags.includes(ALL_FLAGS.importImprovements);
  }
}
