import React from 'react';
import Icon from 'react-fontawesome';
import styled from 'styled-components';

import { Label } from '~/components/shared/typography';
import textStyles from '~/styles/theme/textStyles';

export type TablePaginatorProps = {
  canNextPage: boolean;
  canPreviousPage: boolean;
  gotoPage: (pageIndex: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  pageCount: number;
  onPageChange: (pageIndex: number) => void;
  pageIndex: number;
};

function TablePaginator(props: TablePaginatorProps) {
  const { canNextPage, canPreviousPage, gotoPage, nextPage, onPageChange, pageCount, pageIndex, previousPage } = props;

  const currentPageNumber = pageIndex + 1;
  const totalPages = pageCount < 1 ? 1 : pageCount;

  const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0;

    onPageChange(page);
    gotoPage(page);
  };

  const handleNextClick = () => {
    onPageChange(pageIndex + 1);
    nextPage();
  };

  const handlePrevClick = () => {
    onPageChange(pageIndex - 1);
    previousPage();
  };

  return (
    <StyledTablePaginator>
      <PaginatorButton onClick={handlePrevClick} data-cy='pageBack' disabled={!canPreviousPage}>
        <Icon name='caret-left' />
      </PaginatorButton>
      <PageInfo>
        <Label>Page</Label>
        {totalPages !== 1 ? (
          <CurrentPageNumberInput onChange={handlePageInputChange} value={currentPageNumber} />
        ) : (
          <Label>&nbsp;{currentPageNumber}&nbsp;</Label>
        )}
        <Label>of {totalPages}</Label>
      </PageInfo>
      <PaginatorButton onClick={handleNextClick} data-cy='pageForward' disabled={!canNextPage}>
        <Icon name='caret-right' />
      </PaginatorButton>
    </StyledTablePaginator>
  );
}

export default React.memo(TablePaginator);

const StyledTablePaginator = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PaginatorButton = styled.button`
  background-color: ${(props) => props.theme.colors.black25};
  border: 0px;
  border-radius: 3px;
  cursor: pointer;
  height: 36px;
  outline: 0;
  width: 36px;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;
    opacity: .25;
  `}

  &:focus {
    border: none;
    outline: 0;
  }

  &:hover {
    ${({ disabled, theme }) =>
      !disabled &&
      `
      background-color: ${theme.colors.black50};
    `};
  }
`;

const PageInfo = styled.span`
  align-items: center;
  display: flex;
  padding: 0px 10px;
`;

const CurrentPageNumberInput = styled.input.attrs(() => ({
  type: 'number',
}))`
  border: 1px solid ${({ theme }) => theme.colors.black25};
  border-radius: 3px;
  height: 23px;
  margin: 0px 8px;
  text-align: center;
  width: 32px;

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.black25};
    outline: 0;
  }

  ${textStyles.label};
`;
