import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, FormHeader } from '~/components/shared/form';

import AssociatedGroupsSection from './AssociatedGroupsSection';
import ContactInfoSection from './ContactInfoSection';

function GroupForm(props) {
  const {
    isSubmitting,
    isValid,
    onCancel,
    setFieldValue,
    submitForm,
    dirty,
    status: { isEdit },
  } = props;

  const submitButtonDisabled = !dirty || !isValid || isSubmitting;

  const handleSaveAndAddAnother = () => {
    setFieldValue('shouldNavigate', false, false);
    submitForm();
  };

  const handleCreateGroup = () => {
    setFieldValue('shouldNavigate', true, false);
    submitForm();
  };

  return (
    <Form>
      <FormHeader title={isEdit ? 'Edit Group' : 'Add Group'} />
      <ContactInfoSection />
      <AssociatedGroupsSection />
      <StyledButtonGroup>
        {!isEdit && (
          <Button
            color='primaryWhite'
            data-cy='saveAndAddButton'
            onClick={handleSaveAndAddAnother}
            disabled={submitButtonDisabled}
            text='Save and Add Another'
          />
        )}
        <ButtonGroup>
          <Button color='transparent' text='Cancel' onClick={onCancel} />
          <Button
            type='submit'
            onClick={handleCreateGroup}
            disabled={submitButtonDisabled}
            data-cy='updateGroup'
            text={isEdit ? 'Update Group' : 'Create Group'}
          />
        </ButtonGroup>
      </StyledButtonGroup>
    </Form>
  );
}

const StyledButtonGroup = styled.div`
  display: flex;
  justify-content: ${({ isEdit }) => (isEdit ? 'end' : 'space-between')};
  width: 100%;
`;

GroupForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  status: PropTypes.instanceOf(Object).isRequired,
};

export default GroupForm;
