import React from 'react';

import { Row } from '@tanstack/react-table';

import { FlyoutMenu, FlyoutMenuIcon, FlyoutMenuItem } from '~/components/shared/FlyoutMenu';
import { CollaborationsIcon, PencilIcon } from '~/components/shared/svg';
import ConfigureCareIcon from '~/components/shared/svg/ConfigureCareIcon';
import { Client } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';

export const { adminClientEdit, adminClientGroupTypeView, adminCollaborationEdit } = PERMISSIONS;
export const permissions = [adminClientEdit, adminClientGroupTypeView, adminCollaborationEdit];

type Props = {
  onCollaborationEdit: () => void;
  onEdit: () => void;
  onEditClientGroupTypes: () => void;
  row: Row<Client>;
};

function ClientsFlyoutMenu(props: Props) {
  const { onCollaborationEdit, onEdit, onEditClientGroupTypes, row } = props;

  const profileSvc: any = useProfileContext();

  const isOwningClientType = row.original.isOwningClientType;
  const configureCareOptionsIsVisible =
    row.original.groupTypeDisplayNames && isOwningClientType && profileSvc.has(adminClientGroupTypeView);

  return (
    <FlyoutMenu data={row.original}>
      <FlyoutMenuItem onClick={onEdit} visible={profileSvc.has(adminClientEdit)}>
        <FlyoutMenuIcon>
          <PencilIcon size={18} />
        </FlyoutMenuIcon>
        Edit
      </FlyoutMenuItem>
      {isOwningClientType && (
        <FlyoutMenuItem onClick={onCollaborationEdit} visible={profileSvc.has(adminCollaborationEdit)}>
          <FlyoutMenuIcon>
            <CollaborationsIcon />
          </FlyoutMenuIcon>
          Collaborations
        </FlyoutMenuItem>
      )}
      <FlyoutMenuItem onClick={onEditClientGroupTypes} visible={configureCareOptionsIsVisible}>
        <FlyoutMenuIcon>
          <ConfigureCareIcon size={18} />
        </FlyoutMenuIcon>
        Configure Care Options
      </FlyoutMenuItem>
    </FlyoutMenu>
  );
}

export default ClientsFlyoutMenu;
