import React, { useEffect, useMemo, useState } from 'react';

import LoadingSpinner from '~/components/shared/loadingSpinner';
import { MainPage } from '~/components/shared/pageLayout';
import { Table } from '~/components/shared/table';
import { ACUTE_CLIENT_TYPES } from '~/constants/clientTypes';
import { fetchClients } from '~/ducks/admin/clients';
import { useThunk } from '~/lib/hooks';
import { useProfileContext } from '~/services/profile';
import adminFlagsAPI from '~/sources/api/adminFlagsAPI';

import featureFlagsTableColumns from './featureFlagsTableColumns';
import { parseFlagsData } from './flagsHelpers';

function FeatureFlags() {
  const profileSvc = useProfileContext();
  const [flags, setFlags] = useState([]);
  const { data: clients } = useThunk(fetchClients, [], {
    params: {
      clientType: ACUTE_CLIENT_TYPES.join(','),
    },
  });

  const fetchFlags = async () => {
    const response = await adminFlagsAPI.fetchFlags();

    setFlags(response.data.data);
  };

  useEffect(() => {
    fetchFlags();
  }, []);

  const parsedFeatures = useMemo(() => parseFlagsData({ flags, clients }), [clients, flags]);
  const columns = useMemo(() => featureFlagsTableColumns({ profileSvc }), []);

  if (!parsedFeatures) {
    return <LoadingSpinner />;
  }

  return (
    <MainPage title='Feature Flags'>
      <Table data={parsedFeatures} columns={columns} paginated={false} />
    </MainPage>
  );
}

export default FeatureFlags;
