import * as _ from 'lodash-es';

/**
 * This function handles formatting Rails validation errors.
 *
 * The `keys` we use inside our forms typically match 1:1 with our column names
 * in the database, however, the labels we give our form inputs dont always match up.
 * Because of this, the function allows us to replace keys with a custom string so that our
 * error messages can match up with our labels displayed in the forms.
 *
 *
 * Example:
 *
 * input
 * ({ name: ['has already been taken', 'must be more than 2 characters] })
 *
 * output
 * { name: 'Name has already been taken. Name must be more than 2 characters.' }
 *
 * -------------------
 *
 * Example 2:
 *
 * input with keyReplacements
 * ({ name: ['has already been taken', 'must be more than 2 characters] }, { name: 'Display name' })
 *
 * output
 * { name: 'Display name has already been taken. Display name must be more than 2 characters.' }
 *
 */

export default function formatValidationErrors(
  errors: Record<string, string[]>,
  keyReplacements: Record<string, string> = {}
) {
  return Object.keys(errors).reduce(
    (obj, key) => {
      const label = keyReplacements[key] || key;

      obj[key] = errors[key].map((err) => `${_.startCase(label)} ${err}.`).join(' ');
      return obj;
    },
    {} as Record<string, string>
  );
}
