import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Checkbox,
  FormSection,
  Input,
  InputGroup,
  MultiSelect,
  MultiSelectLabel,
  SectionHeader,
  Select,
} from '~/components/shared/form';
import { EPISODE, PLAN_TYPE } from '~/constants/classifications';
import { PROGRESS, TEMPLATE_TYPE_OPTIONS } from '~/constants/questionTemplateTypes';
import { fetchClassifications } from '~/ducks/admin/classifications';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { getDisplayName, getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';
import { Client, QuestionTemplate } from '~/models';
import { colors } from '~/styles/theme';

function InformationSection({ clients, questionTemplate }) {
  const { setFieldTouched, setFieldValue, values } = useFormikContext();

  const resetField = (fieldName) => {
    setFieldValue(fieldName, []);
    setFieldTouched(fieldName, false);
  };

  const handleOnClientChange = () => {
    const planTypeClassificationsFieldName = 'planTypeClassifications';
    const episodeClassificationsFieldName = 'episodeClassifications';
    const groupTypesFieldName = 'groupTypes';

    resetField(planTypeClassificationsFieldName);
    resetField(episodeClassificationsFieldName);
    resetField(groupTypesFieldName);
  };

  const handleDoNotTrackLosChange = (selectedValue) => {
    const trackLosFieldName = 'trackLos';

    setFieldValue(trackLosFieldName, !selectedValue);
  };

  const planTypeAsyncOptions = useAsyncOptions(fetchClassifications, {
    condition: Boolean(values.client?.id),
    params: {
      client: values.client.id,
      type: PLAN_TYPE,
    },
  });

  const episodesAsyncOptions = useAsyncOptions(fetchClassifications, {
    condition: Boolean(values.client?.id),
    params: {
      client: values.client.id,
      type: EPISODE,
    },
  });

  const groupTypeAsyncOptions = useAsyncOptions(fetchGroupTypes, {
    condition: Boolean(values.client?.id),
    params: {
      sortBy: 'displayName asc',
      client: values.client.id,
    },
  });

  const isProgressTemplate = values.type.value === PROGRESS;
  const isExistingTemplate = !!questionTemplate?.id;
  const isDefaultTemplate = !!(
    isExistingTemplate &&
    !questionTemplate.planTypeClassifications.length &&
    !questionTemplate.episodeClassifications.length &&
    !questionTemplate.groupTypes.length
  );

  return (
    <FormSection>
      <SectionHeader>Template Information</SectionHeader>
      <InputGroup
        name='type'
        label='Template Type'
        data-cy='type'
        disabled={isExistingTemplate}
        options={TEMPLATE_TYPE_OPTIONS}
        component={Select}
      />

      <InputGroup
        name='client'
        label='Client'
        data-cy='client'
        disabled={isExistingTemplate}
        options={clients}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleOnClientChange}
        component={Select}
      />

      <InputGroup
        {...planTypeAsyncOptions}
        name='planTypeClassifications'
        label='Plan Types'
        data-cy='planTypeClasses'
        disabled={!values.client.id || isDefaultTemplate}
        getOptionLabel={getName}
        getOptionValue={getId}
        component={MultiSelect}
        labelComponent={MultiSelectLabel}
      />

      <InputGroup
        {...episodesAsyncOptions}
        name='episodeClassifications'
        label='Episode Types'
        data-cy='episodeTypes'
        disabled={!values.client.id || isDefaultTemplate}
        getOptionLabel={getName}
        getOptionValue={getId}
        component={MultiSelect}
        labelComponent={MultiSelectLabel}
      />

      <InputGroup
        {...groupTypeAsyncOptions}
        name='groupTypes'
        label='Post Acute Group Types (select all that apply)'
        data-cy='groupTypes'
        disabled={!values.client.id || isDefaultTemplate}
        getOptionLabel={getDisplayName}
        getOptionValue={getId}
        component={MultiSelect}
        labelComponent={MultiSelectLabel}
      />

      {isProgressTemplate && (
        <StyledCheckbox
          label='Do not track days for this template'
          name='doNotTrackLos'
          disabled={isExistingTemplate}
          onChange={handleDoNotTrackLosChange}
        />
      )}

      <InputGroup name='instructions' label='Instructions (optional)' placeholder='Instructions' component={Input} />
    </FormSection>
  );
}

InformationSection.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.instanceOf(Client)),
  onClientChange: PropTypes.func,
  questionTemplate: PropTypes.instanceOf(QuestionTemplate),
};

export default InformationSection;

const StyledCheckbox = styled(Checkbox).attrs({
  checkedColor: colors.primaryBlue,
  labelSize: '14px',
  size: 20,
})`
  margin-bottom: 28px;
`;
