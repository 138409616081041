import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import { PlusIcon } from '~/components/shared/svg';
import TabFilters from '~/components/shared/TabFilters';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

import ImportConfigurationsFilterBar from './ImportConfigurationsFilterBar';
import ImportConfigurationsTable from './ImportConfigurationsTable';

function ImportConfigurationsList({ onTabSelect, tabs, ...props }) {
  const profileSvc = useProfileContext();
  const canAddImports = profileSvc.has(PERMISSIONS.adminImportConfigurationCreate);
  const activeTab = tabs.find((tab) => tab.label === 'Import Configurations');

  const handleAdd = () => props.history.push('/clients/import-configurations/new');
  const handleEdit = (config) => props.history.push(`/clients/import-configurations/${config.id}/edit`);

  const addImportConfiguration = (
    <Button onClick={handleAdd} icon={<PlusIcon size={14} fill={colors.white} />} text='Add Import Configuration' />
  );

  return (
    <MainPage
      title='Clients'
      headerWhite
      rightContent={canAddImports && addImportConfiguration}
      headerContent={
        <Fragment>
          <ImportConfigurationsFilterBar />
          <TabFilters tabs={tabs} onTabClick={onTabSelect} selectedTab={activeTab} />
        </Fragment>
      }>
      <ImportConfigurationsTable handleEdit={handleEdit} {...props} />
    </MainPage>
  );
}

ImportConfigurationsList.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  onTabSelect: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default ImportConfigurationsList;
