import { createSlice } from '@reduxjs/toolkit';

import { Toast } from '~/models';

const SLICE_NAME = 'toasts';

const toastsSlice = createSlice({
  name: SLICE_NAME,
  initialState: [],
  reducers: {
    addToast: (state, { payload }) => {
      state.push({ ...new Toast(payload) });
    },
    removeToast: (state, { payload }) => state.filter((toast) => toast.id !== payload)
  }
});

export const {
  addToast,
  removeToast
} = toastsSlice.actions;

export const getToasts = (state) => state[SLICE_NAME];

export default toastsSlice.reducer;
