import Classification from '~/models/Classification';
import GroupType from '~/models/GroupType';

import Client from './Client';
import QuestionArray from './QuestionArray';

export enum QuestionTemplateType {
  PROGRESS = 'progress',
  DISCHARGE = 'discharge',
}

export type QuestionTemplateQuestion = {
  id: string;
  kind: string;
  text: string;
  order: number;
  config?: {
    positive?: string;
    chart?: {
      yAxis: string;
      chartName: string;
    };
  };
};

export interface QuestionTemplateOptions {
  id: string;
  clientId: string;
  instructions: string;
  type: QuestionTemplateType;
  trackLos: boolean;
  questions: QuestionTemplateQuestion[];
  createdAt: string;
  updatedAt: string;
  questionCount: number;
  episodeClassifications: Classification[];
  planTypeClassifications: Classification[];
  groupTypes: GroupType[];
  client: Client;
}

export const defaults: QuestionTemplateOptions = {
  id: '',
  clientId: '',
  episodeClassifications: [],
  instructions: '',
  groupTypes: [],
  planTypeClassifications: [],
  questions: [],
  questionCount: 0,
  trackLos: true,
  type: QuestionTemplateType.PROGRESS,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  client: new Client(),
};

export default class QuestionTemplate {
  id: string;
  clientId: string;
  instructions: string;
  trackLos: boolean;
  type: QuestionTemplateType;
  createdAt: string;
  updatedAt: string;
  questions: QuestionTemplateQuestion[];
  episodeClassifications?: Classification[];
  groupTypes?: GroupType[];
  planTypeClassifications?: Classification[];
  client?: Client;
  _questionCount: number;

  constructor(options: Partial<QuestionTemplateOptions> = defaults) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.client = opts.client;
    this.clientId = opts.clientId;
    this.episodeClassifications = opts.episodeClassifications;
    this.instructions = opts.instructions;
    this.groupTypes = opts.groupTypes;
    this.planTypeClassifications = opts.planTypeClassifications;
    this.questions = QuestionArray.from(opts.questions);
    this._questionCount = opts.questionCount;
    this.trackLos = opts.trackLos;
    this.type = opts.type;
    this.createdAt = opts.createdAt;
    this.updatedAt = opts.updatedAt;
  }

  get questionCount() {
    return this.questions.length > 0 ? this.questions.length : this._questionCount;
  }

  get isProgress() {
    return this.type === QuestionTemplateType.PROGRESS;
  }

  get isDischarge() {
    return this.type === QuestionTemplateType.DISCHARGE;
  }

  serialize() {
    return {
      ...this,
      clientId: this.client?.id,
      episodeClassificationIds: this.episodeClassifications?.map(({ id }) => id),
      planTypeClassificationIds: this.planTypeClassifications?.map(({ id }) => id),
      groupTypeIds: this.groupTypes?.map((value) => value?.id || value),
      questions: this.questions.map((question, index) => {
        question.order = index + 1;
        return question;
      }),
    };
  }
}
