export const PROVIDER = 'provider';

export const GROUP_TYPE_TYPES = [
  {
    label: 'Care Option',
    value: PROVIDER
  }
];

export const GROUP_TYPE_CATEGORY_OPTIONS = [{ label: 'All', value: 'All' }].concat(GROUP_TYPE_TYPES);

export const DISCHARGE_REASON_VISIBILITY_FILTER_OPTIONS = [
  { label: 'All', value: 'All' },
  { label: 'Always display', value: true },
  { label: 'Only display if enabled', value: false },
];
