import React from 'react';
import styled from 'styled-components';

import NotificationCategoryPreferences from '~/components/preferences/notifications/NotificationCategoryPreferences';
import { H3 } from '~/components/shared/typography';
import { GroupType } from '~/models';

type Props = {
  providerType: GroupType;
  disabled?: boolean;
};

export default function NotificationPreferenceSelections({ providerType, disabled = false }: Props) {
  return (
    <div>
      <StyledH3>Select Notifications to Receive</StyledH3>
      {['criticalAlerts', 'activity', 'patientStatusChanges'].map((category) => (
        <NotificationCategoryPreferences
          key={`${providerType.apiName}+${category}`}
          category={category}
          providerType={providerType}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

const StyledH3 = styled(H3)`
  margin-bottom: 12px;
`;
