import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Footer } from '~/components/layouts';

import MainPageHeader from './MainPageHeader';
import PageContainer from './PageContainer';

function MainPage(props) {
  const { banner, title, subtitle, rightContent, headerContent, children, headerSticky, onScroll } = props;

  const showPageInfoContainer = Boolean(title || subtitle || rightContent);
  const showHeaderContainer = Boolean(showPageInfoContainer || headerContent);

  return (
    <StyledPageContainer headerSticky={headerSticky}>
      {banner}
      {showHeaderContainer && <MainPageHeader showPageInfoContainer={showPageInfoContainer} {...props} />}
      <ContentContainer onScroll={onScroll} headerSticky={headerSticky}>
        <div>{children}</div>
        <Footer />
      </ContentContainer>
    </StyledPageContainer>
  );
}

MainPage.propTypes = {
  banner: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([PropTypes.arrayOf(Object), PropTypes.instanceOf(Object)]),
  headerContent: PropTypes.instanceOf(Object),
  headerSticky: PropTypes.bool,
  headerWhite: PropTypes.bool,
  middleContent: PropTypes.instanceOf(Object),
  onScroll: PropTypes.func,
  rightContent: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

MainPage.defaultProps = {
  banner: null,
  headerContent: null,
  headerSticky: false,
  headerWhite: false,
  middleContent: null,
  rightContent: null,
  subtitle: '',
  title: '',
};

export default MainPage;

const StyledPageContainer = styled(PageContainer)`
  overflow-y: ${({ headerSticky }) => (headerSticky ? 'hidden' : 'scroll')};
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 24px 24px 0px 24px;
  overflow-y: ${({ headerSticky }) => (headerSticky ? 'scroll' : 'visible')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
