import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { components } from 'react-select';

import theme from '~/styles/theme';

function DropdownIndicator(props) {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesome
        name='caret-down'
        size='lg'
        style={{
          color: props.isDisabled ? theme.colors.black25 : theme.colors.black,
          lineHeight: '1em',
          marginTop: '1px',
        }}
      />
    </components.DropdownIndicator>
  );
}

DropdownIndicator.propTypes = {
  isDisabled: PropTypes.bool,
};

export default DropdownIndicator;
