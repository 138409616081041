import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import { isDeepEqual } from '~/lib';
import { useDeepEffect } from '~/lib/hooks';
import useField from '~/lib/hooks/useField';

import InfiniteScrollWrapper from '../InfiniteScrollWrapper';

import { customStyles, customTheme } from './customStyles';
import DropdownIndicator from './DropdownIndicator';

function Select(props) {
  const {
    autoSelectSingleOption,
    components,
    options,
    disabled,
    getOptionValue,
    hasError,
    loading,
    menuPlacement,
    onBlur,
    onChange,
    styles,
  } = props;

  const [prevOptions, setPrevOptions] = useState(options);
  const [field, meta, helpers] = useField(props);
  const { name } = field;

  const handleChange = (val) => {
    helpers.setValue(val);
    onChange(val, name, helpers.setValue);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
    onBlur(name);
  };

  const showError = hasError || Boolean(meta.error && typeof meta.error === 'string' && meta.touched);
  const areEqual = (value, other) => value && other && getOptionValue(value) === getOptionValue(other);
  const handleOptionsChange = () => {
    const optionValueChanged = !(
      areEqual(meta.value, options[0]) ||
      (options.length && areEqual(meta.value, getOptionValue(options[0])))
    );
    const optionsChanged = !isDeepEqual(prevOptions, options);

    if (!loading && autoSelectSingleOption && options.length === 1 && optionValueChanged && optionsChanged) {
      handleChange(options[0]);
    }
  };

  useDeepEffect(() => {
    setPrevOptions(options);
  }, [options]);
  useDeepEffect(handleOptionsChange, [loading, autoSelectSingleOption, options]);

  const allStyles = customStyles(styles);

  return (
    <InfiniteScrollWrapper
      {...field}
      {...props}
      component={ReactSelect}
      isDisabled={disabled}
      hasError={showError}
      onChange={handleChange}
      onBlur={handleBlur}
      theme={customTheme}
      styles={allStyles}
      classNamePrefix={'react-select'}
      components={{ DropdownIndicator, ...components }}
      menuPlacement={menuPlacement}
    />
  );
}

Select.propTypes = {
  autoSelectSingleOption: PropTypes.bool,
  components: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  hasError: PropTypes.bool,
  loading: PropTypes.bool,
  menuPlacement: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  styles: PropTypes.instanceOf(Object),
};

const noop = () => {};

Select.defaultProps = {
  autoSelectSingleOption: true,
  components: {},
  disabled: false,
  getOptionLabel: ({ label }) => label,
  getOptionValue: ({ value }) => value,
  hasError: false,
  loading: false,
  menuPlacement: 'auto',
  onBlur: noop,
  onChange: noop,
};

export default Select;
