import { useCallback, useState } from 'react';

import PaginationState from '~/ducks/PaginationState';
import { FetchRequest } from '~/services/api/core';

import useDeepEffect from './useDeepEffect';
import useDeepMemo from './useDeepMemo';
import useThunk from './useThunk';

const usePagedThunk = (thunk, dependencies, options = {}) => {
  const { initialData, initialPagination, onLastPageFetched = () => {} } = options;

  const [loaded, setLoaded] = useState(true);
  const [optionsChanged, setOptionsChanged] = useState(false);
  const [pagination, setPagination] = useState(initialPagination || new PaginationState());
  const tryInvokeOnLastPage = (links) => {
    if (!links.next) onLastPageFetched();
  };

  useDeepEffect(() => {
    setOptionsChanged(true);
  }, [options]);

  const initialDataIsEmpty = !initialData?.length;
  const useInitialData = !initialDataIsEmpty && !optionsChanged;
  const thunkOptions = {
    ...options,
    condition: options?.condition && !useInitialData,
    initialData,
    onSuccess: (payload) => {
      const { links, meta } = payload;

      setPagination({ links, meta });
      tryInvokeOnLastPage(links);

      options.onSuccess(payload);
    },
  };

  const { data: thunkData, loaded: thunkLoaded, setData } = useThunk(thunk, dependencies, thunkOptions);

  const hasMore = useDeepMemo(() => Boolean(pagination?.links?.next), [pagination]);

  const getNextPage = useCallback(async () => {
    if (hasMore) {
      setLoaded(false);
      const response = await new FetchRequest({ url: pagination.links.next }).invoke();
      const { data, items, links, meta } = response.data;
      const responseData = data || items || [];

      setData([...thunkData, ...responseData]);
      setPagination({ links, meta });
      setLoaded(true);
      tryInvokeOnLastPage(links);
    }
  }, [hasMore, pagination]);

  return {
    data: thunkData,
    loaded: (initialDataIsEmpty ? thunkLoaded : true) && loaded,
    setData,
    pagination,
    hasMore,
    getNextPage,
  };
};

export default usePagedThunk;
