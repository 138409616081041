import React from 'react';
import styled from 'styled-components';

import { ModalHeaderVariants } from '~/components/shared/modal/colorTheme';

import Content from './Content';
import Header from './Header';
import Modal, { ModalProps } from './Modal';

type StandardModalProps = ModalProps & {
  title: string;
  className?: string;
  colorTheme?: ModalHeaderVariants;
  showCloseButton?: boolean;
};

function StandardModal(props: StandardModalProps) {
  const {
    colorTheme = 'primary',
    show,
    title,
    onCancel,
    children,
    disableBackdropClick,
    className,
    showCloseButton = true,
    ...rest
  } = props;
  const baseProps = { show, onCancel, disableBackdropClick, className, ...rest };

  return (
    <Modal {...baseProps}>
      <HeaderContainer
        data-cy='modalHeader'
        colorTheme={colorTheme}
        onCancel={onCancel}
        showCloseButton={showCloseButton}>
        {title}
      </HeaderContainer>

      <Content>{children}</Content>
    </Modal>
  );
}

export default StandardModal;

const HeaderContainer = styled(Header)`
  display: flex;
  align-items: center;

  & svg {
    margin-right: 12px;
  }

  & img {
    height: 24px;
    width: 24px;
    margin-right: 8px;
  }
`;
