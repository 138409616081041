import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import { BodySmallBold } from '~/components/shared/typography';
import { simpleDateAtTime } from '~/lib/formatDate';
import { Import } from '~/models';

import ImportStatus from '../ImportStatus';

import ImportsFlyoutMenu from './ImportsFlyoutMenu';

const columnHelper = createColumnHelper<Import>();

type Props = {
  onCancelImport: (importObj: Import) => void;
  onRestartImport: (importObj: Import) => void;
  onResumeImport: (importObj: Import) => void;
  onViewStatusDetails: (importObj: Import) => void;
  handleDownloadAttachment: (id: string) => void;
};

export default function importHistoryTableColumns(props: Props) {
  return [
    columnHelper.accessor((row) => row.client.name, {
      id: 'clientName',
      header: 'Client',
      cell: (info) => <BodySmallBold>{info.getValue()}</BodySmallBold>,
    }),
    columnHelper.accessor((row) => row.status, {
      id: 'status',
      header: 'Status',
      cell: (info) => <ImportStatus status={info.getValue()} />,
    }),
    columnHelper.accessor((row) => row.stepCounts.fileRowCount, {
      id: 'fileRowCount',
      header: 'Total Raw Rows',
      cell: (info) => {
        return info.getValue().toLocaleString();
      },
    }),
    columnHelper.accessor((row) => row.stepCounts.memberCount, {
      id: 'memberCount',
      header: 'Member Count',
      cell: (info) => {
        return info.getValue().toLocaleString();
      },
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: 'createdAt',
      header: 'Import Created On',
      cell: (info) => <div>{info.getValue() ? simpleDateAtTime(info.getValue()) : ''}</div>,
    }),
    columnHelper.display({
      id: 'menu',
      cell: (info) => <ImportsFlyoutMenu row={info.row} {...props} />,
      size: 75,
      meta: { style: { justifyContent: 'flex-end' } },
    }),
  ];
}
