import { addToast } from '~/ducks/toasts';
import { createAsyncThunk } from '~/lib';
import { activeStorageAttachmentsApi } from '~/services/api';

const sliceName = 'activeStorageAttachments';

type FetchParams = {
  id: string;
  include?: string;
};

export const fetchActiveStorageAttachment = createAsyncThunk(
  `${sliceName}/fetchById`,
  async ({ id, ...params }: FetchParams, { dispatch }: any) => {
    try {
      const { data } = await activeStorageAttachmentsApi.fetchById.invoke(id, params);

      return data;
    } catch (e) {
      dispatch(addToast({ text: 'There was an error fetching the attachment. Please try again.' }));
      throw e;
    }
  }
);
