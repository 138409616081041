import * as Yup from 'yup';

import { isValidJSON } from '~/helpers';

export const attrFormValidation = Yup.object().shape({
  name: Yup.string().required('Raw attribute is required'),
  client: Yup.object()
    .shape({
      id: Yup.string(),
      name: Yup.string(),
    })
    .nullable()
    .test('client', 'Client is required', (obj) => Boolean(obj && obj.id)),
  displayName: Yup.string().required('Display attribute is required'),
  olioAttr: Yup.object()
    .shape({
      id: Yup.string(),
      label: Yup.string(),
    })
    .nullable(),
  visible: Yup.boolean(),
  matchRules: Yup.string()
    .test('is-json', 'Matching logic must be in a valid JSON format', (value) => (value ? isValidJSON(value) : true))
    .nullable(),
});
