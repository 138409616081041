/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useState } from 'react';
import { History, LocationState } from 'history';
import { Route, Switch } from 'react-router';

import AttributesList from '~/components/attributes/AttributesList';
import AttributeValuesList from '~/components/attributes/AttributeValuesList';
import { PERMISSIONS, useProfileContext } from '~/services/profile';

type Tab = {
  label: string;
  value: string;
};

function Attributes({ ...props }: AttributesProps) {
  const profileSvc: any = useProfileContext();
  const attributesTab: Tab = { label: 'Attributes', value: 'attributes' };
  const valuesTab: Tab = { label: 'Values', value: 'values' };
  const shouldShowTab = (tabView: Tab, adminPermission: string) => {
    return profileSvc.has(adminPermission) ? tabView : ({} as Tab);
  };

  const [tabs] = useState([
    shouldShowTab(attributesTab, PERMISSIONS.adminAttrView),
    shouldShowTab(valuesTab, PERMISSIONS.adminAttrValueView),
  ]);

  const handleTabSelect = (val: string | number) => {
    const pathMap = {
      [attributesTab.value]: '/attributes',
      [valuesTab.value]: '/attributes/values',
    };

    props.history.push(pathMap[val]);
  };

  return (
    <Fragment>
      <Switch>
        <Route path='/attributes/values'>
          <AttributeValuesList {...props} tabs={tabs} onTabSelect={handleTabSelect} />
        </Route>
        <Route>
          <AttributesList {...props} tabs={tabs} onTabSelect={handleTabSelect} />
        </Route>
      </Switch>
    </Fragment>
  );
}
interface AttributesProps {
  history: History<LocationState>;
}

export default Attributes;
