import React from 'react';
import PropTypes from 'prop-types';

function ErrorOccurredIcon({ size }) {
  return (
    <svg width={ size } height={ size } viewBox='0 0 95 86' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='global-auth0-updates' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
        <g id='generic-error-screen' transform='translate(-673.000000, -265.000000)' strokeWidth='5'>
          <g id='l-remove' transform='translate(676.000000, 268.000000)'>
            <path d='M8.88888889,47.4074074 L8.88888889,5.92592593 C8.88888889,2.65333333 11.5422222,0 14.8148148,0 L74.0740741,0 C77.3466667,0 80,2.65333333 80,5.92592593 L80,35.5555556' id='Path' stroke='#3253EF' />
            <line x1='41.4814815' y1='8.88888889' x2='47.4074074' y2='8.88888889' id='Path' stroke='#3253EF' />
            <path d='M44.4444444,56.2962963 L0,56.2962963 C0,62.8414815 5.30666667,68.1481481 11.8518519,68.1481481 L44.4444444,68.1481481' id='Path' stroke='#3253EF' />
            <line x1='65.1851852' y1='62.2222222' x2='77.037037' y2='62.2222222' id='Path' stroke='#D10D44' />
            <circle id='Oval' stroke='#D10D44' cx='71.1111111' cy='62.2222222' r='17.7777778' />
          </g>
        </g>
      </g>
    </svg>
  );
}

ErrorOccurredIcon.defaultProps = {
  size: 80
};

ErrorOccurredIcon.propTypes = {
  size: PropTypes.number
};

export default ErrorOccurredIcon;
