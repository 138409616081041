import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';

import { Client } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';

import ClientsList from './ClientsList';
import { CollaborationModal, CollaborationsList } from './collaborations';
import { ImportConfigurationsList } from './imports';

function Clients(props) {
  const profileSvc = useProfileContext();
  const { adminCollaborationView, adminImportConfigurationView } = PERMISSIONS;
  const [showCollaborationModal, setShowCollaborationModal] = useState(false);
  const [currentClient, setCurrentClient] = useState(new Client());
  const clientsTab = { label: 'Clients', value: 'clients' };
  const collaborationsTab = { label: 'Collaborations', value: 'collaborations' };
  const importConfigurationsTab = { label: 'Import Configurations', value: 'importConfigurations' };

  const shouldShowTab = (tabView, adminPermission) => {
    return profileSvc.has(adminPermission) ? [tabView] : [];
  };

  const [tabs] = useState(
    [
      clientsTab,
      ...shouldShowTab(collaborationsTab, adminCollaborationView),
      ...shouldShowTab(importConfigurationsTab, adminImportConfigurationView),
    ],
    []
  );

  const handleTabSelect = (val) => {
    const pathMap = {
      [clientsTab.value]: '/clients',
      [collaborationsTab.value]: '/clients/collaborations',
      [importConfigurationsTab.value]: '/clients/import-configurations',
    };

    props.history.push(pathMap[val]);
  };

  const handleCollaborationCancel = () => setShowCollaborationModal(false);

  const handleCollaborationEdit = (client) => {
    setShowCollaborationModal(true);
    setCurrentClient(client);
  };

  return (
    <Fragment>
      <Switch>
        <Route path='/clients/collaborations'>
          <CollaborationsList
            {...props}
            tabs={tabs}
            onTabSelect={handleTabSelect}
            onCollaborationEdit={handleCollaborationEdit}
          />
        </Route>
        <Route path='/clients/import-configurations'>
          <ImportConfigurationsList {...props} tabs={tabs} onTabSelect={handleTabSelect} />
        </Route>
        <Route>
          <ClientsList
            {...props}
            tabs={tabs}
            onTabSelect={handleTabSelect}
            onCollaborationEdit={handleCollaborationEdit}
          />
        </Route>
      </Switch>
      <CollaborationModal show={showCollaborationModal} client={currentClient} onCancel={handleCollaborationCancel} />
    </Fragment>
  );
}

Clients.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
};

export default Clients;
