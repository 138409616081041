import React from 'react';
import PropTypes from 'prop-types';

import { FlyoutMenu, FlyoutMenuIcon, FlyoutMenuItem } from '~/components/shared/FlyoutMenu';
import {
  DeactivateIcon,
  EditedPermissionsIcon,
  EyeIcon,
  PencilIcon,
  ResendInvitationIcon,
} from '~/components/shared/svg';
import { BodySmall } from '~/components/shared/typography';
import { User } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import { colors } from '~/styles/theme';

const { adminUserDelete, adminUserEdit, adminUserView, adminUserInvitationCreate } = PERMISSIONS;

export const permissions = [adminUserDelete, adminUserView, adminUserEdit, adminUserInvitationCreate];

function UsersFlyoutMenu(props) {
  const { onDelete, onEdit, onEditPermissions, onReactivate, onResendInvitation, onViewPreferences, row } = props;

  const profileSvc = useProfileContext();
  const isActive = row.original.active;

  // Dont show three dot menu if the user is deactivated and the user does not have
  // the permission to reactivate them
  if (!isActive && !profileSvc.has(adminUserDelete)) {
    return <></>;
  }

  return (
    <FlyoutMenu data={row.original}>
      <FlyoutMenuItem onClick={onEdit} visible={isActive && profileSvc.has(adminUserEdit)}>
        <FlyoutMenuIcon>
          <PencilIcon size={18} />
        </FlyoutMenuIcon>
        Edit
      </FlyoutMenuItem>
      <FlyoutMenuItem onClick={onViewPreferences} visible={isActive && profileSvc.has(adminUserView)}>
        <FlyoutMenuIcon>
          <EyeIcon size={18} />
        </FlyoutMenuIcon>
        View Preferences
      </FlyoutMenuItem>
      <FlyoutMenuItem onClick={onEditPermissions} visible={isActive && profileSvc.has(adminUserEdit)}>
        <FlyoutMenuIcon>
          <EditedPermissionsIcon style={{ position: 'absolute' }} ovalFillOpacity={'0%'} size={32} />
        </FlyoutMenuIcon>
        Edit Permissions
      </FlyoutMenuItem>
      <FlyoutMenuItem onClick={onResendInvitation} visible={isActive && profileSvc.has(adminUserInvitationCreate)}>
        <FlyoutMenuIcon>
          <ResendInvitationIcon size={18} />
        </FlyoutMenuIcon>
        Resend Invitation
      </FlyoutMenuItem>
      <FlyoutMenuItem onClick={onDelete} visible={isActive && profileSvc.has(adminUserDelete)}>
        <FlyoutMenuIcon>
          <DeactivateIcon size={18} />
        </FlyoutMenuIcon>
        <BodySmall color={colors.accentRed}>Deactivate</BodySmall>
      </FlyoutMenuItem>
      <FlyoutMenuItem onClick={onReactivate} visible={!isActive && profileSvc.has(adminUserDelete)}>
        <FlyoutMenuIcon>
          <i className='fa fa-history' />
        </FlyoutMenuIcon>
        Reactivate User
      </FlyoutMenuItem>
    </FlyoutMenu>
  );
}

UsersFlyoutMenu.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onEditPermissions: PropTypes.func,
  onReactivate: PropTypes.func,
  onResendInvitation: PropTypes.func,
  onViewPreferences: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.instanceOf(User),
  }),
};

export default UsersFlyoutMenu;
