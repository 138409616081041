import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '~/styles/theme/colors';

import { Label } from '../typography';

function FieldLabel({ name, children }) {
  return (
    <LabelContainer>
      <Label htmlFor={name} color={colors.black75}>
        {children}
      </Label>
    </LabelContainer>
  );
}

FieldLabel.propTypes = {
  name: PropTypes.string,
};

FieldLabel.defaultProps = {
  name: '',
};

export default FieldLabel;

const LabelContainer = styled.div`
  margin-bottom: 4px;
`;
