import { DependencyList, useMemo, useRef } from 'react';
import * as _ from 'lodash-es';

export default function useDeepMemo<T>(memoFunc: () => T, deps: DependencyList = []): T {
  const prevDeps = useRef<DependencyList | null>(null);
  const prevValue = useRef<T | null>(null);

  return useMemo(() => {
    const isSame = !!(prevDeps.current && prevDeps.current.every((obj, index) => _.isEqual(obj, deps[index])));

    if (!isSame) {
      prevValue.current = memoFunc();
      prevDeps.current = deps;
    }

    return prevValue.current as T;
  }, deps);
}
