import { createAsyncThunk } from '~/lib';
import { GroupType } from '~/models';
import { adminGroupTypesApi } from '~/services/api';
import { BaseIndexParams } from '~/types';

const cancellableFetchGroupTypes = adminGroupTypesApi.fetch.cancellable();

const SLICE_NAME = 'admin/groupTypes2';

export type FetchGroupTypesAPIParams = {
  search: string;
  inpatient: boolean;
  type: 'provider';
} & BaseIndexParams;

export const fetchGroupTypes2 = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (params: FetchGroupTypesAPIParams) => {
    const res = await cancellableFetchGroupTypes(params);

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: GroupType,
  } as any
);
