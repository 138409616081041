import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { Button } from '~/components/shared/buttons';
import { createAdminUserExport } from '~/ducks/admin/users/exports';
import useExportDownload from '~/lib/hooks/useExportDownload';
import colors from '~/styles/theme/colors';

type Props = ConnectedProps<typeof connector> & { params: Record<string, any> };

function UsersExportDownload({ params, createAdminUserExport }: Props) {
  const exportFn = () => createAdminUserExport(params);
  const { loading, downloadExport } = useExportDownload({ exportFn });

  return (
    <Button onClick={downloadExport} loading={loading} disabled={loading} fill={colors.white} text='Export User List' />
  );
}

const mapDispatchToProps = {
  createAdminUserExport,
};
const connector = connect(null, mapDispatchToProps);

export default connector(UsersExportDownload);
