import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import { highlight, languages } from 'prismjs/components/prism-core';
import Editor from 'react-simple-code-editor';
import styled from 'styled-components';

import 'prismjs/themes/prism.css';

import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';

type JSONEditorProps = {
  className?: string;
  name: string;
  disabled?: boolean;
};

export default function JSONEditor({ className, name, disabled = false }: JSONEditorProps) {
  const [code, setCode] = useState('');
  const [focused, setFocused] = useState(false);
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    let value = meta.initialValue;

    if (value) {
      try {
        value = typeof value === 'string' ? JSON.parse(value) : value;
        const newValue = JSON.stringify(value, undefined, 2);

        setCode(newValue);
        helpers.setValue(newValue);
      } catch {
        setCode('');
      }
    }
  }, [meta.initialValue]);

  const handleCodeChange = (newValue: string) => {
    setCode(newValue);
    helpers.setTouched(true);
    helpers.setValue(newValue);
  };

  const handleBlur = () => {
    setFocused(false);
    helpers.setTouched(true);
  };

  return (
    <EditorContainer className={className} hasError={!!meta.error} disabled={disabled} focused={focused}>
      <StyledEditor
        onFocus={() => setFocused(true)}
        name={name}
        value={code}
        onBlur={handleBlur}
        onValueChange={(code) => handleCodeChange(code)}
        highlight={(code) => highlight(code, languages.js, 'json')}
        padding={10}
        readOnly={disabled}
        tabSize={2}
      />
    </EditorContainer>
  );
}

type BoardColorProps = { theme: any; hasError: boolean; disabled: boolean; focused: boolean };

const getBorderColor = ({ theme, hasError, disabled, focused }: BoardColorProps): string => {
  let borderColor = theme.colors.black25;

  if (focused) {
    borderColor = theme.colors.primaryBlue;
  }

  if (hasError) {
    borderColor = theme.colors.accentRed;
  }

  if (disabled) {
    borderColor = 'transparent';
  }

  return borderColor;
};

const EditorContainer = styled.div`
  max-height: 168px;
  min-height: 118px;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid ${getBorderColor};
  border-radius: 3px;
  font-size: ${({ theme }) => theme.textStyles.bodySmall.fontSize};
  line-height: ${({ theme }) => theme.textStyles.bodySmall.lineHeight};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.black10 : theme.colors.white)};
  & > * textarea {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};
  }
  outline: none;
  width: 100%;
  font-family: monospace;
  font-size: 12px;
`;

const StyledEditor = styled(Editor)`
  min-height: 118px;
  resize: none;
`;
