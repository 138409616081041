import React from 'react';

import { StyledTableCell, StyledTableRow } from './Table';

function EmptyRow() {
  return (
    <StyledTableRow>
      <StyledTableCell className='span-all-columns'>No rows found</StyledTableCell>
    </StyledTableRow>
  );
}

export default EmptyRow;
