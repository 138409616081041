import React from 'react';

import { SSOErrorIcon } from '~/components/shared/svg';
import { BodyBold } from '~/components/shared/typography';

import { Body, Container, Link } from './shared';

function LinkAccountError() {
  return (
    <Container>
      <SSOErrorIcon />
      <Body>
        <BodyBold margin='0px 0px 36px 0px' fontSize='20px'>
          {'Oh no! Looks like something went wrong linking your SSO account.'}
        </BodyBold>
        {'For help resolving this issue, please reach out to '}
        <Link href='mailto:support@olio.health'>support@olio.health</Link>
      </Body>
    </Container>
  );
}

export default LinkAccountError;
