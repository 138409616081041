import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import styled from 'styled-components';

import colors from '~/styles/theme/colors';

const getColors = ({ color }) => {
  const colorMap = {
    primary: {
      background: colors.primaryBlue,
      text: colors.white,
      hover: {
        background: colors.hoverPrimaryBlue,
      },
      focus: {
        background: colors.hoverPrimaryBlue,
        outline: colors.hoverPrimaryBlue,
      },
    },
    white: {
      background: colors.white,
      text: colors.black,
      hover: {},
      focus: {
        border: colors.primaryBlue,
      },
    },
    primaryWhite: {
      background: colors.white,
      text: colors.primaryBlue,
      border: colors.primaryBlue,
      hover: {},
      focus: {
        text: colors.primaryBlue,
        border: colors.primaryBlue,
      },
    },
    accentWhite: {
      background: colors.white,
      text: colors.accentRed,
      border: colors.accentRed,
      hover: {},
      focus: {
        text: colors.accentRed,
        border: colors.accentRed,
      },
    },
    danger: {
      background: colors.accentRed,
      text: colors.white,
      hover: {
        background: colors.hoverAccentRed,
      },
      focus: {
        background: colors.hoverAccentRed,
        outline: colors.hoverAccentRed,
      },
    },
    transparent: {
      background: 'transparent',
      text: colors.black75,
      hover: {
        text: colors.black100,
      },
      focus: {
        text: colors.primaryBlue,
        border: colors.primaryBlue,
      },
    },
    black: {
      background: colors.black,
      text: colors.white,
      hover: {},
      focus: {
        background: colors.black,
        outline: colors.black,
      },
    },
    gray: {
      background: colors.black05,
      text: colors.black,
      hover: {
        background: colors.primaryBlue10,
      },
      focus: {
        outline: 'none',
      },
    },
  };

  return colorMap[color] || colorMap.primary;
};

const BaseButton = styled.button.attrs((props) => ({
  colors: getColors(props),
}))`
  background-color: ${(props) => props.colors.background};

  color: ${(props) => props.colors.text};

  opacity: ${(props) => props.disabled && '0.5'};

  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => `${props.borderWidth || '2px'} solid ${props.colors.border || 'transparent'}`};
  border-radius: 3px;
  font-size: ${(props) => props.fontSize || '16px'};
  font-weight: ${(props) => props.fontWeight || 700};
  letter-spacing: 0.02em;
  line-height: 1.5;
  padding: ${(props) => props.padding || '8px 24px'};
  cursor: pointer;
  white-space: nowrap;

  * {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }

  &:hover {
    background-color: ${(props) => props.colors.hover.background || props.colors.background};
    color: ${(props) => props.colors.hover.text || props.colors.text};
  }

  &:active {
    outline: none !important;
  }

  &:focus {
    background-color: ${(props) => props.colors.focus.background || props.colors.background};
    border-color: ${(props) => props.colors.focus.border || 'transparent'};
    color: ${(props) => props.colors.focus.text || props.colors.text};
    outline: ${(props) => (props.colors.focus.outline ? `2px solid ${props.colors.focus.outline}` : 'none')};
    outline-offset: 2px;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const Button = ({ icon = null, loading = false, text, childRef = undefined, ...rest }) => (
  <BaseButton ref={childRef} {...rest}>
    {(loading || !!icon) && (
      <IconContainer>
        {loading ? <Icon name='spinner' spin /> : null}
        {!!icon && icon}
      </IconContainer>
    )}

    {text ? <TextContainer>{text}</TextContainer> : null}
  </BaseButton>
);

Button.defaultProps = {
  color: 'primary',
  type: 'button',
};

Button.propTypes = {
  childRef: PropTypes.instanceOf(Object),
  color: PropTypes.oneOf(['transparent', 'primary', 'white', 'danger', 'black', 'primaryWhite', 'accentWhite', 'gray']),
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'submit']),
};

export default Button;

const TextContainer = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const IconContainer = styled.div`
  display: flex;
`;
