/* eslint-disable no-console */
import { useState } from 'react';

const useCopyToClipboard = () => {
  const [clipboardText, setClipboardText] = useState(null);

  const copyToClipboard = async (text) => {
    if (!navigator?.clipboard) {
      console.log('Clipboard not supported');
    }

    try {
      await navigator.clipboard.writeText(text);
      setClipboardText(text);
    } catch (error) {
      console.log('Copy failed', error);

      setClipboardText(null);
    }
  };

  return { clipboardText, copyToClipboard };
};

export default useCopyToClipboard;
