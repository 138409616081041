import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { H3 } from '~/components/shared/typography';
import { Permission } from '~/models';

import { permissionsByCategoryReducer } from './helpers';
import PermissionsList from './PermissionsList';

function Section({ label, permissions }) {
  const permissionsByCategory = useMemo(() => permissions.reduce(permissionsByCategoryReducer, {}), [permissions]);

  return (
    <Container>
      <H3>{ label }</H3>
      {
        Object.keys(permissionsByCategory).map((category) => {
          return (
            <PermissionsList key={ category }
              category={ category }
              permissions={ permissionsByCategory[category] } />
          );
        })
      }
    </Container>
  );
}

Section.propTypes = {
  label: PropTypes.string.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.instanceOf(Permission)).isRequired
};

Section.defaultProps = {
  permissions: []
};

export default Section;

const Container = styled.div`
  align-self: flex-start;
  border-radius: 5px;
  border: 1px solid ${ (props) => props.theme.colors.black10 };
  background-color: ${ (props) => props.theme.colors.white };
  margin-right: 24px;
  margin-bottom: 24px;
  padding: 24px;
  width: 500px;
`;
