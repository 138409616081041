import React, { useMemo } from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { TIMEZONES } from '~/constants/timezones';
import { createGroup, fetchGroup, updateGroup } from '~/ducks/admin/groups';
import { addToast } from '~/ducks/toasts';
import { capitalize } from '~/helpers';
import { unwrapResult } from '~/lib';
import { useThunk } from '~/lib/hooks';
import { Group } from '~/models';
import { history } from '~/store';

import { FormPage } from '../shared/pageLayout';

import GroupForm from './GroupForm';
import validationSchema from './validationSchema';

function EditGroup(props) {
  const { match } = props;
  const groupId = match.params.id;

  const { data: group, loaded: groupLoaded } = useThunk(fetchGroup, [groupId], {
    condition: Boolean(groupId),
    params: {
      id: groupId,
      include: 'groups,group_type,owners',
    },
  });

  const handleCancel = () => props.history.push('/groups');
  const filterByGroupOrOwnerType = group.client.isOwningClientType ? group.groups : group.owners;

  const initialValues = {
    ...group.toFormValues(),
    timezone: TIMEZONES.find((tz) => tz.value === group.timezone),
    hospitals: filterByGroupOrOwnerType.filter((loc) => loc.isHospital),
    payers: group.owners.filter((loc) => loc.isPayer),
    physicianGroups: group.owners.filter((loc) => loc.isPhysicianGroup),
    physicianTeams: group.groups.filter((loc) => loc.isPhysicianTeam),
    rehabFacilities: group.groups.filter((loc) => loc.isProvider),
  };

  const goToGroups = () => history.push('/groups');
  const handleSubmit = ({ shouldNavigate = true, ...values }, { setErrors, setSubmitting, resetForm }) => {
    const group = Group.fromFormValues(values);
    const groupRequest = group.id ? props.updateGroup : props.createGroup;

    const tryNotifySuccess = () => !shouldNavigate && props.addToast({ text: `${values.name} successfully added!` });

    groupRequest(group.serialize())
      .then(unwrapResult)
      .then(() => shouldNavigate && goToGroups())
      .then(tryNotifySuccess)
      .then(() => {
        resetForm({
          values: {
            ...values,
            name: '',
            address: {
              streetAddress: '',
              streetAddressLine2: '',
              city: '',
              state: '',
              zip: '',
            },
          },
        });
      })
      .catch((e) => {
        const error = e?.response?.data?.errors;

        if (error) {
          const errorMessage = Object.keys(error).reduce((obj, key) => {
            obj[key] = error[key].map((err) => `${capitalize(key)} ${err}`).join(', ');
            return obj;
          }, {});

          setErrors(errorMessage);
        } else {
          props.addToast({ text: 'There was an error creating the group. Please try again.' });
        }
      })
      .finally(() => setSubmitting(false));
  };

  const formikOptions = useMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit,
      mapPropsToStatus: () => ({ isEdit: Boolean(groupId) }),
      mapPropsToValues: () => ({ ...initialValues }),
      validationSchema,
    }),
    [initialValues]
  );

  const FormikGroupForm = useMemo(() => withFormik(formikOptions)(GroupForm), [formikOptions]);

  if (Boolean(groupId) && !groupLoaded) return <CircleSpinner centered />;

  return (
    <FormPage>
      <FormikGroupForm onCancel={handleCancel} />
    </FormPage>
  );
}

EditGroup.propTypes = {
  addToast: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addToast,
  createGroup,
  updateGroup,
};

export default connect(null, mapDispatchToProps)(EditGroup);
