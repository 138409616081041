import { cssProp } from './helpers';

const flexbox = ({ alignItems, justifyContent, flexDirection, flexWrap } = {}) => {
  const align = cssProp('align-items', alignItems);
  const justify = cssProp('justify-content', justifyContent);
  const direction = cssProp('flex-direction', flexDirection);
  const wrap = cssProp('flex-wrap', flexWrap);

  return `display: flex; ${align} ${justify} ${direction} ${wrap}`;
};

const center = (direction = 'row') => {
  return flexbox({
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: direction
  });
};

const verticalCenter = (direction = 'row') => {
  return flexbox({
    alignItems: 'center',
    flexDirection: direction
  });
};

const horizontalCenter = (direction = 'row') => {
  return flexbox({
    justifyContent: 'center',
    flexDirection: direction
  });
};

export {
  center,
  flexbox,
  horizontalCenter,
  verticalCenter
};
