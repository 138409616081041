import React from 'react';
import styled from 'styled-components';

import { BodySmall } from '../typography';

function ErrorMessage({ children }) {
  return typeof children === 'string' && <Message>{ children }</Message>;
}

export default ErrorMessage;

const Message = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.accentRed};
  margin-bottom: 15px;
`;
