import React, { useEffect } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import {
  Checkbox,
  Form,
  FormHeader,
  FormSection,
  Input,
  InputGroup,
  SectionHeader,
  Select,
} from '~/components/shared/form';
import JSONEditor from '~/components/shared/form/JSONEditor';
import { InfoIconOutline } from '~/components/shared/svg';
import { ATTR_OLIO_MAPPED_FIELDS } from '~/constants/attrMappedFields';
import { OWNER_CLIENT_TYPES } from '~/constants/clientTypes';
import { fetchAttrs } from '~/ducks/admin/attrs';
import { fetchClients } from '~/ducks/admin/clients';
import { getId, getName } from '~/helpers';
import { useAsyncOptions, useThunk } from '~/lib/hooks';
import { Attr, Client } from '~/models';
import colors from '~/styles/theme/colors';

export type AttrFormValues = {
  client?: Client;
  displayName: string;
  matchRules: string;
  name: string;
  olioAttr?: { label: string; value: string };
  visible: boolean;
  active: boolean;
};

type AttrFormProps = {
  isEditing: boolean;
  onCancel: () => void;
};

type Props = AttrFormProps & FormikProps<AttrFormValues>;

export default function AttrForm({ onCancel, isEditing, ...props }: Props) {
  const { handleSubmit, isValid, dirty, isSubmitting, setFieldValue } = useFormikContext();

  const olioAttr = props?.values?.olioAttr;
  const client = props?.values?.client;

  const asyncClientOptions = useAsyncOptions(fetchClients, {
    params: { clientType: OWNER_CLIENT_TYPES.join(',') },
  });

  const { data: attrs, setData: setAttrs } = useThunk(fetchAttrs, [olioAttr?.value, client?.id], {
    params: { client: client?.id, olioAttr: olioAttr?.value },
    condition: !!client?.id && !!olioAttr?.value,
    onSuccess: (payload: { data: Attr[] }) => setAttrs(payload.data),
  });

  useEffect(() => {
    setAttrs([]);
  }, [olioAttr, client]);

  const handleOlioAttrChange = (newValue?: { label: string; value: string }) => {
    if (newValue) {
      const displayName = ATTR_OLIO_MAPPED_FIELDS.find((attr) => attr.value === newValue.value)?.label;

      setFieldValue('displayName', displayName || props.values.name);
      setFieldValue('olioAttr', newValue);
    } else {
      setFieldValue('displayName', props.values.name);
      setFieldValue('olioAttr', newValue);
    }
    setFieldValue('visible', false);
  };

  const handleNameBlur = (val: string) => {
    if (val && !props.values.displayName) {
      setFieldValue('displayName', val);
    }
  };

  return (
    <Form>
      <FormHeader title={isEditing ? 'Edit Attribute' : 'Add Attribute'} />

      <InputGroup
        {...asyncClientOptions}
        name='client'
        label='Client'
        autoSelectSingleOption={false}
        data-cy='client'
        getOptionLabel={getName}
        getOptionValue={getId}
        disabled={isEditing}
        component={Select}
      />

      <FormSection>
        <SectionHeader>Attribute Details</SectionHeader>

        <InputGroup name='name' label='Raw Attribute' disabled={isEditing} onBlur={handleNameBlur} component={Input} />

        <InputGroup
          name='olioAttr'
          label='Mapped Olio Field'
          autoSelectSingleOption={false}
          isClearable
          onChange={handleOlioAttrChange}
          options={ATTR_OLIO_MAPPED_FIELDS}
          component={Select}
        />

        {!!attrs?.[0] && (
          <MappedFieldWarning>
            <InfoIconOutline />
            <p>
              &quot;{attrs?.[0]?.name}&quot; attribute is also mapped to the &quot;{attrs?.[0]?.displayName}&quot; Olio
              field
            </p>
          </MappedFieldWarning>
        )}

        {!olioAttr && (
          <StyledCheckbox
            name='visible'
            label='Display on UI'
            checkedColor={colors.primaryBlue}
            labelSize='14px'
            size={20}
          />
        )}

        <InputGroup name='displayName' label='Display Attribute' disabled={!!olioAttr} component={Input} />
      </FormSection>

      {!!olioAttr && (
        <FormSection>
          <SectionHeader>Import Matching Logic</SectionHeader>
          <InputGroup
            name='matchRules'
            label='Please provide any matching logic for the attribute (JSON FORMAT)'
            component={JSONEditor}
          />
        </FormSection>
      )}

      <ButtonGroup>
        <Button color='transparent' onClick={onCancel} text='Cancel' />
        <Button onClick={handleSubmit} disabled={!dirty || !isValid || isSubmitting} text='Submit' />
      </ButtonGroup>
    </Form>
  );
}

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 20px;
`;

const MappedFieldWarning = styled.div`
  margin: -10px 0 20px;
  background-color: ${colors.black05};
  display: flex;
  align-items: center;
  color: ${colors.black};
  padding: 11px 16px;
  border-radius: 4px;
  font-size: 12px;

  p {
    margin: 0 0 0 6px;
  }
`;
