let id = 0;

class Toast {
  constructor({ text = '' } = {}) {
    this.text = text;
    this.id = id++;
  }
}

export default Toast;
