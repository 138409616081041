import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckboxOffIcon from '~/components/shared/svg/CheckboxOffIcon';
import CheckboxOnIcon from '~/components/shared/svg/CheckboxOnIcon';
import { Label } from '~/components/shared/typography';
import useField from '~/lib/hooks/useField';
import colors from '~/styles/theme/colors';

function Checkbox(props) {
  const {
    checked,
    checkedColor,
    className,
    color,
    disabled,
    label,
    labelIcon,
    labelMargin,
    labelSize,
    onChange,
    overrideSetValue,
    size,
    style,
    ...rest
  } = props;

  const [field, , helpers] = useField(props);
  const { name, value } = field;
  const isChecked = checked || (!Array.isArray(value) && value) || false;

  const handleClick = useCallback(() => {
    const val = !isChecked;

    if (!disabled) {
      if (!overrideSetValue) helpers.setValue(val);
      onChange(val, name, helpers.setValue);
    }
  }, [disabled, overrideSetValue, isChecked, onChange, props.name]);

  const iconStyles = {
    fill: color,
    width: size,
    height: size,
  };

  return (
    <StyledCheckbox
      className={className}
      onClick={handleClick}
      disabled={disabled}
      color={color}
      labelMargin={labelMargin}
      {...rest}>
      <CheckboxContainer disabled={disabled} style={style.checkboxContainer} size={size}>
        {isChecked ? (
          <CheckboxOnIcon {...iconStyles} fill={checkedColor || color} data-cy='checkboxOn' />
        ) : (
          <CheckboxOffIcon {...iconStyles} data-cy='checkboxOff' />
        )}
      </CheckboxContainer>
      <Label fontSize={labelSize}>{label}</Label>
      {!!labelIcon && <LabelIcon>{labelIcon}</LabelIcon>}
    </StyledCheckbox>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  checkedColor: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  labelIcon: PropTypes.element,
  labelMargin: PropTypes.string,
  labelSize: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  overrideFormik: PropTypes.bool,
  overrideSetValue: PropTypes.bool,
  size: PropTypes.number,
  style: PropTypes.instanceOf(Object),
};

const noop = () => {};

Checkbox.defaultProps = {
  color: colors.black,
  checked: false,
  checkedColor: '',
  disabled: false,
  label: '',
  labelSize: '16px',
  labelMargin: '10px',
  onChange: noop,
  overrideFormik: false,
  size: 24,
  style: {},
};

export default Checkbox;

const StyledCheckbox = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  align-items: center;
  & label {
    margin-left: ${({ labelMargin }) => labelMargin};
    color: ${({ color }) => color};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

// Don't remove min-width and min-height!
// I know it seems pointless but IE is dumb.
const CheckboxContainer = styled.div`
  display: flex;
  flex: 0;
  align-items: center;
  min-width: ${({ size }) => `${size}px`};
  min-height: ${({ size }) => `${size}px`};
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
`;

const LabelIcon = styled.div`
  margin-left: 5px;
`;
