import React, { useContext, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import PropTypes, { InferProps } from 'prop-types';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import { withSaveChangesModal } from '~/components/shared/withSaveChangesModal';
import { Client } from '~/models';

import CareOptionsTabs from './CareOptionsTabs';
import { ConfigureCareOptionsContext } from './context';
import { ReducedConfigs } from './types';

function ConfigureCareOptionsMainPage(props: Props) {
  const { children, client, onTabClick, onTabChanged } = props;

  const {
    dirty,
    isSubmitting,
    isValid,
    resetForm,
    status: { disabled },
    submitForm,
  } = useFormikContext<ReducedConfigs>();

  const selectedTabValue = useContext(ConfigureCareOptionsContext);
  const [showModalFunc, setShowModalFunc] = useState(() => () => undefined);
  const discardDisabled = useMemo(() => disabled || !dirty || isSubmitting, [dirty, isSubmitting]);
  const saveDisabled = useMemo(() => disabled || !dirty || isSubmitting || !isValid, [dirty, isSubmitting, isValid]);
  const handleDiscardClick = () => showModalFunc();
  const handleBindShowModal = (func: () => undefined) => {
    setShowModalFunc(() => func);
  };

  const buttonGroup = (
    <ButtonGroup>
      <Button color='transparent' disabled={saveDisabled} onClick={handleDiscardClick} text='Discard Changes' />
      <Button
        data-cy='saveConfigureCareOptionsButton'
        disabled={discardDisabled}
        onClick={submitForm}
        text='Save Changes'
      />
    </ButtonGroup>
  );

  const MainPageWithSaveChangesModal = useMemo(() => withSaveChangesModal(MainPage), []);

  return (
    <MainPageWithSaveChangesModal
      title='Configure Care Options'
      bindShowModal={handleBindShowModal}
      headerWhite
      historyBlockCondition={dirty || isSubmitting}
      onSaveChangesConfirm={resetForm}
      rightContent={buttonGroup}
      subtitle={`${client.name}`}
      headerContent={
        <CareOptionsTabs
          client={client}
          onTabChanged={onTabChanged}
          onTabClick={onTabClick}
          selectedTabValue={selectedTabValue}
        />
      }>
      {React.Children.map(
        children,
        (child) =>
          child &&
          React.cloneElement(child, {
            selectedTabValue,
          })
      )}
    </MainPageWithSaveChangesModal>
  );
}

ConfigureCareOptionsMainPage.propTypes = {
  children: PropTypes.element,
  client: PropTypes.instanceOf(Client).isRequired,
  onTabChanged: PropTypes.func,
  onTabClick: PropTypes.func,
  selectedTabValue: PropTypes.string,
};

type Props = InferProps<typeof ConfigureCareOptionsMainPage.propTypes>;

export default ConfigureCareOptionsMainPage;
