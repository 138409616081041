import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

import { getProp } from '~/helpers';

import FieldLabel from '../FieldLabel';

function MultiSelectLabel({ getSelectedCountLabel, name, selectedCountDefault, children }) {
  const { values } = useFormikContext();
  const selectedCount = getProp(values, name)?.length || selectedCountDefault;
  const selectedCountLabel = getSelectedCountLabel(selectedCount);
  const fullLabel = `${children}${children && selectedCountLabel && ' - '}${selectedCountLabel}`;

  return <FieldLabel name={name}>{fullLabel}</FieldLabel>;
}

MultiSelectLabel.propTypes = {
  getSelectedCountLabel: PropTypes.func,
  name: PropTypes.string,
  selectedCountDefault: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MultiSelectLabel.defaultProps = {
  getSelectedCountLabel: (selectedCount) => `${selectedCount} selected`,
  name: '',
  selectedCountDefault: 0,
};

export default MultiSelectLabel;
