import React from 'react';

const CopyIcon = () => {
  return (
    <svg width='14px' height='15px' viewBox='0 0 14 15' version='1.1' xmlns='http://www.w3.org/2000/svg' >
      <g id='Users' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='square'>
        <g id='Hover_CopyButtonShown' transform='translate(-796.000000, -269.000000)' stroke='#3253EF' strokeWidth='1.5'>
          <g id='Group-12' transform='translate(797.000000, 267.000000)'>
            <g id='copy-2' transform='translate(0.000000, 3.000000)'>
              <polyline id='Path' points='3.6 10.8 0 10.8 0 0 9 0 9 3' />
              <rect id='Rectangle' x='6' y='5.4' width='6' height='7.8' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CopyIcon;
