/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';
import { capitalize, humanize } from '~/helpers';
import { NewAttrValueCount, StepResultsErrorMessage } from '~/models/imports';

import { StatusDetailsCardProps } from './StatusDetailsCard';

function StatusStepResults({ step }: StatusDetailsCardProps) {
  const { results } = step;

  const renderListItem = (value: number | string[] | NewAttrValueCount) => {
    let listItems;

    if (Array.isArray(value)) {
      listItems = renderArrayListItems(value);
    } else {
      return <StyledBodySmall>{value}</StyledBodySmall>;
    }

    return <List>{listItems}</List>;
  };

  const renderRowNumbers = (rowNumbers: number[]) => {
    const formattedRowNumbers = rowNumbers.join(', ');

    return (
      <Fragment>
        <br />
        Row Numbers: {formattedRowNumbers}
      </Fragment>
    );
  };

  const renderArrayListItems = (value: string[] | NewAttrValueCount[]) => {
    return value.map((item, i) => {
      let formattedValue = item;

      if (typeof item === 'object') {
        formattedValue = `${item.key}: ${item.value}`;
      }

      return (
        <li key={i}>
          <StyledBodySmall key={i}>{formattedValue}</StyledBodySmall>
        </li>
      );
    });
  };

  const renderErrorListItems = (errors: StepResultsErrorMessage[]) => {
    return errors.map((error, i) => {
      const { message, rowNumbers } = error;

      const formattedValue = `${message}`;
      const formattedArrayValue = renderRowNumbers(rowNumbers);

      return (
        <li key={i}>
          <StyledBodySmall>
            {formattedValue}
            {!!rowNumbers.length && formattedArrayValue}
          </StyledBodySmall>
        </li>
      );
    });
  };

  const renderResults = () => {
    const filtered = Object.entries(results)
      .filter(([k, _v]) => k !== 'errorMsgs')
      .map(([key, value], i) => {
        if (Array.isArray(value) && !value.length) return;

        return (
          <li key={i}>
            {`${capitalize(humanize(key))}: `}
            {renderListItem(value!)}
          </li>
        );
      });

    return filtered;
  };

  const renderErrorMsgs = (label: string, item: StepResultsErrorMessage[]) => {
    if (item.length > 1) {
      label += 's';
    }

    return (
      <List>
        <li>
          {`${label}: (${item.length})`}
          <List>{renderErrorListItems(item)}</List>
        </li>
      </List>
    );
  };

  return (
    <ResultsContainer data-cy='statusDetailsCardBody'>
      <List>{renderResults()}</List>
      {Boolean(step.warnings.length) && renderErrorMsgs('Warning', step.warnings)}
      {Boolean(step.errors.length) && renderErrorMsgs('Error', step.errors)}
    </ResultsContainer>
  );
}

export default StatusStepResults;

const ResultsContainer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.black05};
  padding: 0px 16px 4px 0px;
  max-width: 500px;
`;

const List = styled.ul`
  line-height: 2;
`;

export const StyledBodySmall = styled(BodySmall)`
  display: inline;
`;
