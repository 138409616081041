import * as Yup from 'yup';

const clientFormValidations = Yup.object().shape({
  name: Yup.string().required('Client name required'),
  fileRetentionDays: Yup.number()
    .label('File Retention Days')
    .nullable()
    .moreThan(0, 'Must be greater than 0')
    .lessThan(2555, 'Must be less than 2555 days (7 years)'),
  clientType: Yup.object()
    .shape({
      name: Yup.string(),
      kind: Yup.string(),
    })
    .nullable()
    .test('clientType', 'Client type is required', (clientType) => !!clientType?.name),
  mfaConfig: Yup.object().shape({
    enabled: Yup.boolean().nullable(),
    allowedIndependentFactors: Yup.array().when('enabled', {
      is: true,
      then: (schema) => schema.min(1, 'At least one independent factor is required'),
    }),
    default: Yup.object()
      .nullable()
      .when('enabled', {
        is: true,
        then: (schema) => schema.required('Default factor required'),
      }),
  }),
  ssoEnabled: Yup.boolean().nullable(),
});

export default clientFormValidations;
