import { ErrorType } from '~/constants/importSteps';

interface IImportStepOptions {
  apiType: string;
  order: number;
  status: string;
  results: StepResults;
}

interface IImportStep {
  readonly errors: StepResultsErrorMessage[];
  readonly hasResults: boolean;
  readonly hasResultsErrorMsgs: boolean;
  readonly warnings: StepResultsErrorMessage[];
}

export interface StepResultsErrorMessage {
  type: string;
  class: string;
  rowNumbers: number[];
  message: string;
}

export type NewAttrValueCount = { key: string; value: number };

export type StepResults = {
  errorMsgs: StepResultsErrorMessage[];
  newAttrNames?: string[];
  newAttrValueCounts?: NewAttrValueCount[];
  fileRowCount?: [] | number[];
  importedRowCount?: [] | number[];
  memberCount?: [] | number[];
};

const defaults: IImportStepOptions = {
  apiType: '',
  order: 0,
  status: '',
  results: { errorMsgs: [] },
};

export class ImportStep implements IImportStep, IImportStepOptions {
  apiType: string;
  order: number;
  status: string;
  results: StepResults;
  constructor(options: IImportStepOptions) {
    const opts = { ...defaults, ...options };

    this.apiType = opts.apiType;
    this.order = opts.order;
    this.status = opts.status;
    this.results = opts.results;
  }

  get errors() {
    return this.results.errorMsgs.filter((msg) => msg.type === ErrorType.ERROR);
  }

  get hasResults() {
    return Boolean(Object.values(this.results).flat().filter(Boolean).length);
  }

  get hasResultsErrorMsgs() {
    return Boolean(this.results.errorMsgs.length);
  }

  get warnings() {
    return this.results.errorMsgs.filter((msg) => msg.type === ErrorType.WARNING);
  }
}
