import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import remove from '~/assets/images/Delete.svg';
import { Input, InputGroup } from '~/components/shared/form';

import { InlineContainer } from './InlineContainer';
import RadioButtonGroup from './RadioButtonGroup';

const YesNoQuestion = ({ field: { name, value }, form, removeItem }) => {
  useEffect(() => {
    if (!value.config.positive) handleRadioChange('no');
  }, []);

  const handleRadioChange = (inputVal) => {
    form.setFieldValue(name, { ...value, config: { positive: inputVal } });
  };

  return (
    <InlineContainer>
      <InputGroup label='Yes/No' name={`${name}.text`} placeholder='Type question here...' component={Input} />
      <img src={remove} onClick={removeItem} />
      <RadioButtonGroup
        label='Positive Answer'
        name={`${name}.radio`}
        value={value.config.positive}
        onChange={handleRadioChange}
      />
    </InlineContainer>
  );
};

YesNoQuestion.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.instanceOf(Object),
  }),
  form: PropTypes.instanceOf(Object),
  removeItem: PropTypes.func,
};

export default YesNoQuestion;
