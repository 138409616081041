import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';

import { useField } from '~/lib/hooks';

import DatePickerInput from './DatePickerInput';

import 'react-datepicker/dist/react-datepicker.css';
import './datePickerCustomStyles.scss';

/*
  All of the checking to see if input value is a Moment object / changing output to
  be a Moment object is to maintain compatibility with models that currently use
  Moment for default date values. That stuff can be removed once
  https://www.pivotaltracker.com/story/show/171438333 has been completed (remove
  Moment library).
*/

function DatePicker(props) {
  const { dateFormat, disabled, hasError, onChange, onBlur, placeholder, clearable = true } = props;

  const [field, meta, helpers] = useField(props);
  const { name, value } = field;

  const inputValueIsMoment = () => value instanceof moment;
  const [isMoment, setIsMoment] = useState(inputValueIsMoment());

  const selectedDate = () => {
    if (!value) {
      return null;
    }

    return isMoment ? value.toDate() : new Date(value);
  };

  const [dateValue, setDateValue] = useState(selectedDate());

  useEffect(() => {
    setIsMoment(inputValueIsMoment());
  }, [value]);

  useEffect(() => {
    setDateValue(selectedDate());
  }, [value]);

  const handleChange = (newDate) => {
    let outputValue = newDate;

    if (!clearable && !newDate) {
      outputValue = dateValue;
    }

    setDateValue(newDate);

    if (outputValue && isMoment) {
      outputValue = outputValue ? moment(outputValue) : moment();
    }

    helpers.setTouched(true);
    helpers.setValue(outputValue, true);
    onChange(outputValue, name, helpers.setValue);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
    onBlur(value, name);
  };

  const showError = hasError || Boolean(meta.error && typeof meta.error === 'string' && meta.touched);

  return (
    <ReactDatePicker
      {...field}
      {...props}
      autoComplete='off'
      wrapperClassName='standard-datepicker'
      value={dateValue}
      customInput={<DatePickerInput hasError={showError} />}
      dateFormat={dateFormat}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholderText={placeholder}
      selected={dateValue}
    />
  );
}

DatePicker.propTypes = {
  clearable: PropTypes.bool,
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.instanceOf(Object),
};

const noop = () => {};
const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';

DatePicker.defaultProps = {
  dateFormat: DEFAULT_DATE_FORMAT,
  disabled: false,
  hasError: false,
  onBlur: noop,
  onChange: noop,
  placeholder: DEFAULT_DATE_FORMAT.toUpperCase(),
};

export default DatePicker;
