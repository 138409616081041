import React, { Fragment } from 'react';
import { useFormikContext } from 'formik';

import { InputGroup, MultiSelect, MultiSelectLabel } from '~/components/shared/form';
import { HOSPITAL, PAYER, PHYSICIAN_GROUP } from '~/constants/locationTypes';
import { fetchGroups } from '~/ducks/admin/groups';
import { getId, getName } from '~/helpers';
import { useAddressOptions, useAsyncOptions, useDeepEffect } from '~/lib/hooks';
import { Group } from '~/models';

type PostAcuteAssociatedGroupsFormValues = {
  hospitals: Group[];
  payers: Group[];
  physicianGroups: Group[];
  locationType: { kind: string; name: string };
};

function PostAcuteAssociatedGroups() {
  const {
    setFieldTouched,
    setFieldValue,
    status: { isEdit },
    values,
  } = useFormikContext<PostAcuteAssociatedGroupsFormValues>();

  const hospitalsFieldName = 'hospitals';
  const payersFieldName = 'payers';
  const physicianGroupsFieldName = 'physicianGroups';
  const addressProps = useAddressOptions();

  const setField = (fieldName: string, value: string[]) => {
    setFieldValue(fieldName, value, false);
    setFieldTouched(fieldName, false, false);
  };

  useDeepEffect(() => {
    if (!isEdit && values?.locationType) {
      setField(hospitalsFieldName, []);
      setField(payersFieldName, []);
      setField(physicianGroupsFieldName, []);
    }
  }, [values.locationType]);

  const asyncHospitalOptions = useAsyncOptions(fetchGroups, {
    params: { type: HOSPITAL },
  });

  const asyncPhysicianGroupsOptions = useAsyncOptions(fetchGroups, {
    params: { type: PHYSICIAN_GROUP },
  });

  const asyncPayerOptions = useAsyncOptions(fetchGroups, {
    params: { type: PAYER },
  });

  return (
    <Fragment>
      <InputGroup
        {...asyncHospitalOptions}
        {...addressProps}
        closeMenuOnSelect={false}
        name='hospitals'
        label='Associated Hospitals'
        data-cy='associatedHospitals'
        placeholder='Associated Hospitals'
        getOptionLabel={getName}
        getOptionValue={getId}
        component={MultiSelect}
        labelComponent={MultiSelectLabel}
      />

      <InputGroup
        {...asyncPhysicianGroupsOptions}
        {...addressProps}
        closeMenuOnSelect={false}
        name='physicianGroups'
        label='Associated Physician Groups'
        data-cy='associatedPhysicianGroups'
        placeholder='Associated Physician Groups'
        getOptionLabel={getName}
        getOptionValue={getId}
        component={MultiSelect}
        labelComponent={MultiSelectLabel}
      />

      <InputGroup
        {...asyncPayerOptions}
        {...addressProps}
        closeMenuOnSelect={false}
        name='payers'
        label='Associated Payers'
        data-cy='associatedPayers'
        placeholder=' Associated Payers'
        getOptionLabel={getName}
        getOptionValue={getId}
        component={MultiSelect}
        labelComponent={MultiSelectLabel}
      />
    </Fragment>
  );
}

export default PostAcuteAssociatedGroups;
