import Client from './Client';

const defaults = {
  id: '',
  name: '',
  clientType: '',
  collaboratorNames: '',
  collaboratorIds: [],
  collaborators: []
};

class Collaboration extends Client {
  constructor(options) {
    const opts = { ...defaults, ...options };

    super(opts);

    this.id = this.id || opts.ownerId;
    this.name = this.name || opts.ownerName;
    this.clientType = this.clientType || opts.ownerType;
    this.collaboratorNames = opts.collaboratorNames;
    this.collaborators = opts.collaborators;
  }

  toFormValues(clients) {
    return {
      ...this,
      collaborators: this.collaboratorIds.map((cid) => clients.find((c) => c.id === cid))
    };
  }

  static fromFormValues(opts) {
    return new this({
      ...opts,
      collaboratorIds: (opts.collaborators || []).map((c) => c.id)
    });
  }

  serialize() {
    return {
      id: this.id,
      collaboratorIds: this.collaboratorIds
    };
  }
}

export default Collaboration;
