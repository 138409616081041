import React from 'react';
import styled from 'styled-components';

interface ToggleProps {
  active: boolean;
  disabled: boolean;
  onChange: (active: boolean) => void;
}

const Slider = styled.div<{ active: boolean; disabled: boolean }>`
  width: 32px;
  height: 1rem;
  background-color: ${({ active, theme }) => (active ? theme.colors.primaryBlue : theme.colors.black25)};
  border-radius: 2rem;
  transition: background-color 0.2s;
  position: relative;

  opacity: ${({ disabled = true }) => (disabled ? 0.25 : 1)};
  pointer-events: ${({ disabled = true }) => (disabled ? 'none' : 'auto')};

  &::before {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #fff;
    transition: transform 0.2s;
    transform: translateX(${({ active }) => (active ? '16px' : '0')});
  }
`;

const Toggle: React.FC<ToggleProps> = ({ active, disabled, onChange }) => {
  const handleChange = () => {
    onChange(!active);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleChange();
    }
  };

  return <Slider active={active} disabled={disabled} onClick={handleChange} tabIndex={0} onKeyDown={handleKeyPress} />;
};

export default Toggle;
