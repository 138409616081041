export const permissionsByCategoryReducer = (acc, permission) => {
  if (!permission.category) return acc;

  if (!acc[permission.category]) {
    acc[permission.category] = [];
  }

  acc[permission.category].push(permission);

  return acc;
};
