export function parseFlagData({ flag, clients }) {
  if (!clients.length || !flag) return null;

  flag.clients = clients.filter(({ id }) => flag.clientIds.includes(id));

  return flag;
}

export function parseFlagsData({ flags, clients }) {
  if (!clients.length || !flags) return null;

  return flags.map((flag) => parseFlagData({ flag, clients }));
}
