import React from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import NotificationPreferenceScopes from '~/components/preferences/notifications/NotificationPreferenceScopes';
import NotificationPreferenceSelections from '~/components/preferences/notifications/NotificationPreferenceSelections';
import { NotificationPreferencesFormValues } from '~/components/preferences/notifications/NotificationPreferencesFormWrapper';
import SectionToggle from '~/components/shared/SectionToggle';
import { GroupType } from '~/models';

type Props = {
  actingClientId: string;
  includeCaseManagersScope: boolean;
  includeUtilizationManagersScope: boolean;
  providerTypes: GroupType[];
  selectedTab?: string;
};

export default function NotificationPreferencesForm({
  actingClientId,
  includeCaseManagersScope,
  includeUtilizationManagersScope,
  providerTypes,
  selectedTab,
}: Props) {
  const { setFieldValue, values, status, setStatus } = useFormikContext<NotificationPreferencesFormValues>();

  const actingProviderType: GroupType | undefined = providerTypes.find((type: GroupType) => type.id === selectedTab);

  if (!actingProviderType) {
    return null;
  }

  const handleActiveChange = (active: boolean) => {
    setFieldValue(`${actingProviderType.apiName}.active`, active);
    setStatus({ ...status, activeStatus: { ...status.activeStatus, [actingProviderType.apiName]: active } });
  };

  const formDisabled = !status.activeStatus[actingProviderType.apiName] || status.disabled;

  return (
    <PageContainer>
      <SectionToggle
        active={values[actingProviderType.apiName]?.active}
        disabled={status.disabled}
        onChange={handleActiveChange}
        label={`Receive notifications for ${actingProviderType?.displayName}`}
      />
      <CardWrapper>
        <Container>
          <NotificationPreferenceScopes
            actingClientId={actingClientId}
            includeCaseManagersScope={includeCaseManagersScope}
            includeUtilizationManagersScope={includeUtilizationManagersScope}
            providerType={actingProviderType}
            disabled={formDisabled}
          />
        </Container>
        <Container>
          <NotificationPreferenceSelections providerType={actingProviderType} disabled={formDisabled} />
        </Container>
      </CardWrapper>
    </PageContainer>
  );
}

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.black10};
  background-color: ${(props) => props.theme.colors.white};
  padding: 24px;
  width: 500px;
  align-self: flex-start;
  margin-top: 24px;
`;

const PageContainer = styled.div`
  /* Each card is 550px wide, margin between cards is 24px */
  max-width: calc(1100px + 24px);
`;
