export const ATTR_OLIO_MAPPED_FIELDS: {
  label: string,
  value: string,
  apiName: string
}[] = [
  {
    label: 'Physician Team',
    value: 'Group::PhysicianTeam',
    apiName: 'physician_team'
  }
];
