import { CreateRequest, DeleteRequest, FetchByIdRequest, FetchRequest, UpdateRequest } from './';

export default class Api {
  create: CreateRequest;
  fetch: FetchRequest;
  fetchById: FetchByIdRequest;
  update: UpdateRequest;
  delete: DeleteRequest;
  constructor({ url = '' } = {}) {
    this.create = new CreateRequest({ url });
    this.fetch = new FetchRequest({ url });
    this.fetchById = new FetchByIdRequest({ url });
    this.update = new UpdateRequest({ url });
    this.delete = new DeleteRequest({ url });
  }
}
