import React from 'react';

import * as Sentry from '@sentry/react';

import { history } from '~/store';

import Error from './Error';

Sentry.init({
  dsn: import.meta.env.SENTRY_DSN,
  environment: import.meta.env.SENTRY_ENVIRONMENT,
  integrations: [
    // currently using react-router v5
    Sentry.reactRouterV5BrowserTracingIntegration({
      history,
    }),
  ],
  ignoreErrors: [/UnhandledRejection.*Non-Error promise rejection/],
  release: import.meta.env.COMMIT_SHA,
  tracesSampleRate: 0.1,
});

function ErrorBoundary(props) {
  return <Sentry.ErrorBoundary fallback={Error}>{props.children}</Sentry.ErrorBoundary>;
}

export default ErrorBoundary;
