import { useCallback, useState } from 'react';

import { useDebounce, useDeepEffect, useDeepMemo, usePagedThunk } from '~/lib/hooks';

const useAsyncOptions = (thunk, config = {}) => {
  const {
    condition = true,
    initialOptions = [],
    initialPagination,
    optionsToAppend = [],
    optionsToPrepend = [],
    optionsTransform = (opts) => opts,
    pageSize = 10,
    ...thunkOptions
  } = config;

  const [options, setOptions] = useState(initialOptions);
  const [search, setSearch] = useState('');
  const [lastPageFetched, setLastPageFetched] = useState(false);
  const debouncedSearch = useDebounce(search, 250);
  const handleLastPageFetched = useCallback(() => setLastPageFetched(true), []);
  const pagedThunkOptions = useDeepMemo(() => ({
    ...thunkOptions,
    condition,
    initialData: initialOptions,
    initialPagination,
    onLastPageFetched: handleLastPageFetched,
    params: {
      ...thunkOptions?.params,
      pageSize,
      search
    }
  }), [condition, initialOptions, pageSize, debouncedSearch, thunkOptions]);

  const {
    data,
    loaded,
    hasMore,
    getNextPage
  } = usePagedThunk(thunk, [pagedThunkOptions], pagedThunkOptions);

  useDeepEffect(() => {
    if (!condition) return;

    setOptions(optionsTransform([
      ...optionsToPrepend,
      ...data,
      ...(lastPageFetched ? optionsToAppend : [])
    ]));
  }, [condition, optionsToAppend, optionsToPrepend, lastPageFetched, data]);

  return {
    getNextPage,
    loading: condition && !loaded,
    options,
    hasMore,
    setSearch
  };
};

export default useAsyncOptions;
