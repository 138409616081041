import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import { useDeepEffect } from '~/lib/hooks';

function ValueContainer(props) {
  const values = props.getValue();

  useDeepEffect(() => {
    const getOptionLabel = props.selectProps.getOptionLabel;

    let sortedValues = values;

    if (getOptionLabel) {
      sortedValues = values.sort((optionA, optionB) => {
        const optionALabel = getOptionLabel(optionA);
        const optionBLabel = getOptionLabel(optionB);

        if (optionALabel && optionBLabel) {
          return optionALabel.localeCompare(optionBLabel);
        }

        return 0;
      });

      props.setValue(sortedValues);
    }
  }, [values]);

  return (
    <components.ValueContainer { ...props } />
  );
}

ValueContainer.propTypes = {
  getValue: PropTypes.func.isRequired,
  selectProps: PropTypes.instanceOf(Object),
  setValue: PropTypes.func.isRequired
};

export default ValueContainer;
