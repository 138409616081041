import { createAsyncThunk } from '~/lib';
import adminFlagsAPI from '~/sources/api/adminFlagsAPI';

export const updateFlag = createAsyncThunk(
  'admin/flag/update',
  async (payload) => {
    const { data } = await adminFlagsAPI.updateFlag(payload);

    return data;
  }
);
