export enum JobProcessStatus {
  Pending = 'pending',
  Running = 'running',
  Completed = 'completed',
  Error = 'error',
}

type ExportContext = {
  exportId: string;
};

export interface JobProcessOptions {
  id: string;
  key: string | null;
  status: JobProcessStatus;
  error: string | null;
  context: Record<string, any> | ExportContext;
}

const defaults: JobProcessOptions = {
  id: '',
  key: null,
  status: JobProcessStatus.Pending,
  error: null,
  context: {},
};

class JobProcess {
  id: string;
  key: string | null;
  status: JobProcessStatus;
  error: string | null;
  context: Record<string, any> | ExportContext;

  constructor(options: Partial<JobProcessOptions> = defaults) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.key = opts.key;
    this.status = opts.status;
    this.error = opts.error;
    this.context = opts.context;
  }

  get isPending() {
    return this.status === JobProcessStatus.Pending;
  }

  get isRunning() {
    return this.status === JobProcessStatus.Running;
  }

  get hasError() {
    return this.status === JobProcessStatus.Error;
  }

  get isCompleted() {
    return this.status === JobProcessStatus.Completed;
  }

  get isFinished() {
    return this.isCompleted || this.hasError;
  }
}

export default JobProcess;
