import React from 'react';
import PropTypes from 'prop-types';

import theme from '~/styles/theme';

const EditedPermissionsIcon = ({ fill, ovalFill, ovalFillOpacity, size, ...rest }) => {
  return (
    <svg {...rest} width={size} height={size} viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='roles-and-permissions' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Modified-Role-Indication' transform='translate(-631.000000, -266.000000)'>
          <g id='Group-12' transform='translate(631.000000, 266.000000)'>
            <circle id='Oval' fill={ovalFill} fillOpacity={ovalFillOpacity} cx='8' cy='8' r='8' />
            <g id='a-edit' transform='translate(4.000000, 4.000000)' fill={fill} fillRule='nonzero'>
              <circle id='Oval' cx='4' cy='2' r='2' />
              <path
                d='M3.5,6.5855 L5.027,5.0585 C4.707,5.023 4.366,5 4,5 C2.404,5 1.2305,5.3975 0.5815,5.691 C0.225,5.8525 0,6.2085 0,6.6 L0,8 L3.5,8 L3.5,6.5855 Z'
                id='Path'
              />
              <polygon id='Path' points='5.5 8 4.5 8 4.5 7 7 4.5 8 5.5' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

EditedPermissionsIcon.defaultProps = {
  fill: theme.colors.black50,
  ovalFill: theme.colors.accentYellow,
  ovalFillOpacity: '100%',
  size: 24,
};

EditedPermissionsIcon.propTypes = {
  fill: PropTypes.string,
  ovalFill: PropTypes.string,
  ovalFillOpacity: PropTypes.string,
  size: PropTypes.number,
};

export default EditedPermissionsIcon;
