import React from 'react';

import { ErrorOccurredIcon } from '~/components/shared/svg';
import { BodyBold } from '~/components/shared/typography';

import { Body, Button, Container, Link } from './shared';

function UnhandledError() {
  const handleClick = () => window.location.reload();

  return (
    <Container>
      <ErrorOccurredIcon />
      <Body>
        <BodyBold as='span' fontSize='20px'>
          {'Oh no! Looks like something went wrong.'}
        </BodyBold>
        &nbsp;
        {'If this problem occurs again, please contact '}
        <Link href='mailto:support@olio.health'>support@olio.health</Link>
      </Body>
      <Button text='Return to Olio' onClick={handleClick} />
    </Container>
  );
}

export default UnhandledError;
