import { useCallback, useEffect, useMemo, useState } from 'react';

import { SortingState } from '@tanstack/react-table';

export const parseSortBy = (sortBy: string | string[]): SortingState => {
  if (Array.isArray(sortBy)) {
    return [{ id: sortBy[0], desc: sortBy[1] === 'desc' }];
  }

  return sortBy.split(',').map((sort) => {
    const [column, direction] = sort.trim().split(' ');

    return { id: column, desc: direction === 'desc' };
  });
};

const usePagination = (options = {}) => {
  const defaults = {
    page: 1,
    pageSize: 10,
    sortBy: '',
  };

  const filteredOptions = Object.fromEntries(Object.entries(options).filter(([_k, v]) => typeof v !== 'undefined'));

  const opts = {
    ...defaults,
    ...filteredOptions,
  };

  const [filters, setFilters] = useState(opts);
  const [pageIndex, setPageIndex] = useState(opts.page - 1);
  const [pageSize, setPageSize] = useState(opts.pageSize);
  const defaultSortBy = useMemo(() => parseSortBy(opts.sortBy), []);

  const setSortBy = useCallback((sortBy: SortingState) => {
    const newSortBy = sortBy.map((o) => `${o.id} ${o.desc ? 'desc' : 'asc'}`).join(',');

    setFilters({ ...filters, sortBy: newSortBy });
    setPageIndex(0);
  }, []);

  useEffect(() => {
    setFilters({ ...filters, page: pageIndex + 1 });
  }, [pageIndex]);

  return {
    defaultSortBy,
    pageIndex,
    pageSize,
    filters,
    setPageIndex,
    setPageSize,
    setSortBy,
  };
};

export default usePagination;
