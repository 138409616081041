import * as Yup from 'yup';

import { ADMIN } from '~/constants/clientTypes';

export const userFormValidation = () => Yup.lazy(({ client }) => (
  Yup.object().shape({
    name: Yup.string().required('User name is required'),
    email: (client?.clientType === ADMIN) ?
      Yup.string().email().matches(/.*@olio.health$/, 'Users associated to an Admin client must use the "@olio.health" domain').required('Valid email is required') :
      Yup.string().email().required('Valid email is required'),
    role: Yup.object().shape({
      id: Yup.string(),
      name: Yup.string()
    }).nullable().test('role', 'Role is required', (obj) => Boolean(obj && obj.id)),
    client: Yup.object().shape({
      id: Yup.string(),
      name: Yup.string()
    }).nullable().test('client', 'Client is required', (obj) => Boolean(obj && obj.id))
  }))
);
