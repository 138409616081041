import styled from 'styled-components';

import { H2 } from '~/components/shared/typography';

const PageTitle = styled(H2)`
  display: none;

  @media (min-width: 640px) {
    display: block;
  }
`;

export default PageTitle;
