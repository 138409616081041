import { useCallback, useEffect, useRef } from 'react';

const usePreviousWithReset = (value) => {
  const ref = useRef();
  const setRef = useCallback((newValue) => {
    ref.current = newValue;
  }, []);

  const reset = useCallback(() => {
    setRef(null);
  }, []);

  useEffect(() => {
    setRef(value);
  }, [value]);

  return [ref.current, reset];
};

export default usePreviousWithReset;
