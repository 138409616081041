import React, { useMemo } from 'react';
import { FormikHelpers, withFormik } from 'formik';
import PropTypes, { InferProps } from 'prop-types';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { unwrapResult } from '@reduxjs/toolkit';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { FormPage } from '~/components/shared/pageLayout';
import {
  createAttrValue,
  CreateAttrValueAPIParams,
  fetchAttrValue,
  updateAttrValue,
  UpdateAttrValueAPIParams,
} from '~/ducks/admin/attrValues';
import { addToast } from '~/ducks/toasts';
import formatValidationErrors from '~/helpers/formatValidationErrors';
import { useThunk } from '~/lib/hooks';

import AttrValueForm, { AttrValueFormValues } from './AttrValueForm';
import { attrValueFormValidation } from './attrValueFormValidation';

interface IAttrValueFormProps {
  onCancel: () => void;
  isEditing: boolean;
}

function EditAttrValue(props: Props) {
  const attrValueId = props.match.params.id;

  const { data: attrValue, loaded: attrLoaded } = useThunk(fetchAttrValue, [attrValueId], {
    condition: Boolean(attrValueId),
    params: {
      id: attrValueId,
      include: 'client,attr,associatedGroups',
    },
  });

  const navigateToAttrValues = () => {
    props.history.push('/attributes/values');
  };

  const handleSubmit = (
    values: AttrValueFormValues,
    { setSubmitting, setFieldError }: FormikHelpers<AttrValueFormValues>
  ) => {
    const request = attrValueId ? props.updateAttrValue : props.createAttrValue;

    const valuesForRequest: CreateAttrValueAPIParams | UpdateAttrValueAPIParams = {
      id: attrValueId,
      attrId: values.attr.id,
      clientId: values.client.id,
      displayName: values.displayName || null,
      associatedGroupIds: values.associatedGroups.map((group) => group.id),
      name: values.name,
      visible: values.visible,
      active: values.active,
    };

    return request(valuesForRequest)
      .then(unwrapResult)
      .catch((e) => {
        const errors = e?.response?.data?.errors;

        if (errors) {
          const formattedErrors = formatValidationErrors(errors, { name: 'Raw value' });

          Object.keys(formattedErrors).forEach((err) => {
            setFieldError(err, formattedErrors[err]);
          });
        } else {
          props.addToast({
            text: `There was an error ${attrValueId ? 'updating' : 'creating'} the value. Please try again.`,
          });
        }
        throw e;
      })
      .then(navigateToAttrValues)
      .then(() => props.addToast({ text: `Value successfully ${attrValueId ? 'updated' : 'added'}!` }))
      .finally(() => setSubmitting(false));
  };

  const formikOptions = useMemo(() => {
    return {
      enableReinitialize: true,
      handleSubmit,
      validationSchema: attrValueFormValidation,
      mapPropsToStatus: () => ({ isEditing: Boolean(attrValueId) }),
      mapPropsToValues: () => attrValue,
    };
  }, [attrValue]);

  const FormikAttrForm = useMemo(
    () => withFormik<IAttrValueFormProps, AttrValueFormValues>(formikOptions)(AttrValueForm),
    [formikOptions]
  );

  if (Boolean(attrValueId) && !attrLoaded) {
    return <CircleSpinner centered />;
  }

  return (
    <FormPage>
      <FormikAttrForm onCancel={navigateToAttrValues} isEditing={!!attrValueId} />
    </FormPage>
  );
}

EditAttrValue.propTypes = {
  addToast: PropTypes.func.isRequired,
  createAttrValue: PropTypes.func.isRequired,
  updateAttrValue: PropTypes.func.isRequired,
};

type RouteProps = RouteComponentProps<{ id?: string }>;
type Props = InferProps<typeof EditAttrValue.propTypes> & RouteProps;

const mapDispatchToProps = {
  addToast,
  createAttrValue,
  updateAttrValue,
};

export default connect(null, mapDispatchToProps)(EditAttrValue);
