import React from 'react';

function CollaborationsIcon() {
  return (
    <svg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>Group 7</title>
      <g id='ahn-home-health-flow' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' fillOpacity='0.5'>
        <g id='ahn---hha-portfolio' transform='translate(-665.000000, -967.000000)' fill='#0F1226' fillRule='nonzero'>
          <g id='Group-7' transform='translate(665.000000, 967.000000)'>
            <g id='joint-account' transform='translate(0.000000, 0.000000)'>
              <ellipse id='Oval' cx='3.66670844' cy='2.33333333' rx='2.33333335' ry='2.33333333' />
              <ellipse id='Oval' cx='12.3333752' cy='2.33333333' rx='2.33333335' ry='2.33333333' />
              <path d='M15.2560419,6.33333333 C13.5354665,5.00140731 11.1319505,5.00140731 9.41137515,6.33333333 C9.25986391,6.45782891 9.12813965,6.60461378 9.02070848,6.76866667 C11.0338787,7.16579773 12.6417961,8.68070149 13.1580419,10.6666667 L16.0000893,10.6666667 L16.0000893,7.89066667 C16.0042381,7.28487274 15.7299431,6.7107211 15.2560419,6.33333333 Z' id='Path' />
              <path d='M6.97937513,6.76733333 C6.87228628,6.60361071 6.74076259,6.45724845 6.58937513,6.33333333 C5.75803712,5.67583498 4.72657564,5.32291705 3.66670844,5.33333333 C2.60707798,5.32312363 1.57589399,5.67602711 0.744708412,6.33333333 C0.270637245,6.710651 -0.00389356954,7.28478169 0,7.89066667 L0,10.6666667 L2.84204176,10.6666667 C3.35784582,8.68018397 4.96585013,7.16468008 6.97937513,6.76733333 L6.97937513,6.76733333 Z' id='Path' />
            </g>
            <g id='c-add' transform='translate(4.000000, 8.000000)'>
              <path d='M4,0 C1.8,0 0,1.8 0,4 C0,6.2 1.8,8 4,8 C6.2,8 8,6.2 8,4 C8,1.8 6.2,0 4,0 Z M6,4.5 L4.5,4.5 L4.5,6 L3.5,6 L3.5,4.5 L2,4.5 L2,3.5 L3.5,3.5 L3.5,2 L4.5,2 L4.5,3.5 L6,3.5 L6,4.5 Z' id='Shape' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CollaborationsIcon;
