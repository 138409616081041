import { Api, FetchRequest } from './core';

const url = '/admin/attrs';
const {
  create,
  fetch,
  fetchById,
  update
} = new Api({ url });

export default {
  create,
  fetch,
  fetchById,
  fetchMatchesById: new FetchRequest({
    formatArgsForConfig: ({ id, ...params }) => ({
      url: `${url}/${id}/matches`,
      params
    })
  }),
  update
};
