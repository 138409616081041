const defaults = {
  category: '',
  description: '',
  label: '',
  name: ''
};

export default class Permission {
  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.category = opts.category;
    this.description = opts.description;
    this.label = opts.label;
    this.name = opts.name;
  }
}
