import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getToasts, removeToast } from '~/ducks/toasts';

import Toast from './toast';

const ListWrapper = styled.ul`
  position: absolute;
  z-index: 999;
  top: 12px;
  left: 0;
  right: 0;
  margin: auto;
  list-style: none;
  width: 60%;
`;

export function Toasts(props) {
  return (
    <ListWrapper className='toasts' data-cy='toasts'>
      {props.toasts.map((toast) => {
        const { id } = toast;

        return <Toast {...toast} key={id} onClick={props.removeToast} />;
      })}
    </ListWrapper>
  );
}

Toasts.propTypes = {
  removeToast: PropTypes.func,
  toasts: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

const mapDispatchToProps = { removeToast };

const mapStateToProps = (state) => ({ toasts: getToasts(state) });

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
