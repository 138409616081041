import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';
import { verticalCenter } from '~/styles/mixins';

function FlyoutMenuItem({ visible, ...props }) {
  return visible && <StyledFlyoutMenuItem {...props} />;
}

FlyoutMenuItem.defaultProps = {
  visible: true,
};

FlyoutMenuItem.propTypes = {
  visible: PropTypes.bool,
};

const StyledFlyoutMenuItem = styled(BodySmall).attrs(() => ({
  forwardedAs: 'div',
}))`
  color: ${({ color }) => color};
  cursor: pointer;
  min-width: 150px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black10};
  }

  ${verticalCenter()};
`;

export default FlyoutMenuItem;
