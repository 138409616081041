import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-fontawesome';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import StyledNavLink from './StyledNavLink';

function SideNavLink(props) {
  const [isOpen, setIsOpen] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const containerRef = useRef(null);

  const handleParentClick = () => {
    setIsOpen(!isOpen);
  };

  const relativeIsActive = props.relativeIsActiveHandler(props.title, props.location.pathname);

  useEffect(() => {
    if (!containerRef.current) return;

    const active =
      [...containerRef.current.children].some((child) => child.href && child.className.includes('active')) ||
      relativeIsActive;

    setIsActive(active);
  }, [props.location.pathname]);

  return (
    props.visible && (
      <Container className={isActive ? 'active' : ''} ref={containerRef}>
        {props.to ? (
          <StyledNavLink className={relativeIsActive ? 'active' : ''} to={props.to}>
            {props.title}
          </StyledNavLink>
        ) : (
          <StyledNavLink onClick={handleParentClick} className={isActive ? 'active' : ''} as='div' to={{}}>
            {props.title}
            <StyledIcon name={`${isOpen ? 'caret-up' : 'caret-down'}`} />
          </StyledNavLink>
        )}

        {isOpen &&
          React.Children.map(props.children, (child) => {
            const { title, visible, ...rest } = child.props;

            return (
              visible && (
                <StyledNavLink {...rest}>
                  <Spacer />
                  {title}
                </StyledNavLink>
              )
            );
          })}
      </Container>
    )
  );
}

SideNavLink.propTypes = {
  relativeIsActiveHandler: PropTypes.func,
  title: PropTypes.string,
  to: PropTypes.string,
  visible: PropTypes.bool,
};

SideNavLink.defaultProps = {
  relativeIsActiveHandler: () => {},
  title: '',
  to: '',
  visible: true,
};

export default withRouter(SideNavLink);

const Container = styled.div`
  border: 2px solid transparent;

  &.active {
    border-left-color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.white15};
  }
`;

const Spacer = styled.div`
  width: 18px;
  height: 18px;
  display: inline-block;
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
  margin-left: auto;
  align-self: center;
`;
