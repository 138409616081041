import theme from '~/styles/theme';

const { colors, textStyles } = theme;

export const handleOverride = (provided, state, override) => {
  if (typeof override === 'function') {
    return override(provided, state);
  } else if (typeof override === 'object') {
    return override;
  }
};

const getBorderColor = ({ isFocused, selectProps, isDisabled }) => {
  let borderColor = colors.black25;

  if (isFocused) {
    borderColor = colors.primaryBlue;
  }

  if (selectProps.hasError) {
    borderColor = colors.accentRed;
  }

  if (isDisabled) {
    borderColor = 'transparent';
  }

  return borderColor;
};

export const customTheme = (selectTheme) => ({
  ...selectTheme,
  borderRadius: 3,
  colors: {
    ...selectTheme.colors,
    primary: colors.primaryBlue,
    primary75: colors.primaryBlue75,
    primary50: colors.primaryBlue50,
    primary25: colors.primaryBlue15,
    danger: colors.accentRed,
    dangerLight: colors.accentRed10,
    neutral0: colors.white,
    neutral5: colors.black05,
    neutral10: colors.black10,
    neutral20: colors.black25,
    neutral30: colors.black25,
    neutral40: colors.black50,
    neutral50: colors.black50,
    neutral60: colors.black50,
    neutral70: colors.black75,
    neutral80: colors.black,
    neutral90: colors.black,
  },
});

export const customStyles = ({
  container = {},
  control = {},
  input = {},
  menu = {},
  menuPortal = {},
  placeholder = {},
  dropdownIndicator = {},
  indicatorSeparator = {},
  ...rest
} = {}) => ({
  container: (provided, state) => ({
    ...provided,
    flex: 1,
    ...handleOverride(provided, state, container),
  }),
  control: (provided, state) => ({
    ...provided,
    borderWidth: 1,
    ...textStyles.bodySmall,
    boxShadow: 'none',
    borderColor: getBorderColor(state),
    '&:hover': {
      borderColor: getBorderColor(state),
    },
    backgroundColor: state.isDisabled ? colors.black10 : provided.backgroundColor,
    pointerEvents: state.isDisabled ? 'auto' : 'inherit',
    cursor: state.isDisabled ? 'not-allowed' : 'inherit',
    ...handleOverride(provided, state, control),
  }),
  input: (provided, state) => ({
    ...provided,
    paddingTop: 4,
    paddingBottom: 4,
    ...handleOverride(provided, state, input),
  }),
  menu: (provided, state) => ({
    ...provided,
    ...textStyles.bodySmall,
    textOverflow: 'ellipsis',
    zIndex: 10,
    ...handleOverride(provided, state, menu),
  }),
  menuPortal: (provided, state) => ({
    ...provided,
    ...handleOverride(provided, state, menuPortal),
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: colors.black25,
    ...handleOverride(provided, state, placeholder),
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    width: 24,
    marginRight: 8,
    ...handleOverride(provided, state, dropdownIndicator),
  }),
  indicatorSeparator: (provided, state) => ({
    display: 'none',
    ...handleOverride(provided, state, indicatorSeparator),
  }),
  ...rest,
});
