import React from 'react';
import styled from 'styled-components';

import textStyles, { FONT_WEIGHT_BOLD, FONT_WEIGHT_EXTRA_BOLD } from '~/styles/theme/textStyles';
export { FONT_WEIGHT_BOLD, FONT_WEIGHT_EXTRA_BOLD };

/* eslint-disable react/no-multi-comp */

const { base, h1, h2, h3, body, bodySmall, label, labelSmall } = textStyles;

const color = (props) => `color: ${props.color || base.color};`;
const fontFamily = (props) => `font-family: ${props.fontFamily || base.fontFamily};`;
const fontWeight = (props) => `font-weight: ${props.fontWeight || base.fontWeight};`;
const fontSize = (props) => `font-size: ${props.fontSize || base.fontSize};`;
const lineHeight = (props) => `line-height: ${props.lineHeight || base.lineHeight};`;
const letterSpacing = (props) => `letter-spacing: ${props.letterSpacing || base.letterSpacing};`;
const display = (props) => `display: ${props.display || base.display};`;
const margin = (props) => `margin: ${props.margin || base.margin};`;
const textAlign = (props) => (props.textAlign && `text-align: ${props.textAlign}`) || '';

export const BaseText = styled.div`
  ${color};
  ${fontFamily};
  ${fontWeight};
  ${fontSize};
  ${lineHeight};
  ${letterSpacing};
  ${display};
  ${margin};
  ${textAlign};
`;

export const H1 = (props) => <BaseText as='h1' {...h1} {...props} />;

export const H2 = (props) => <BaseText as='h2' {...h2} {...props} />;

export const H3 = (props) => <BaseText as='h3' {...h3} {...props} />;

export const Body = (props) => <BaseText as='p' {...body} {...props} />;

export const BodySmall = (props) => <BaseText as='p' {...bodySmall} {...props} />;

export const Label = (props) => <BaseText as='label' {...label} {...props} />;

export const LabelSmall = (props) => <BaseText as='label' {...labelSmall} {...props} />;

export const H2Bold = (props) => <H2 fontWeight={FONT_WEIGHT_EXTRA_BOLD} {...props} />;

export const BodyBold = (props) => <Body fontWeight={FONT_WEIGHT_BOLD} {...props} />;

export const BodySmallBold = (props) => <BodySmall fontWeight={FONT_WEIGHT_BOLD} {...props} />;

export const LabelBold = (props) => <Label fontWeight={FONT_WEIGHT_BOLD} {...props} />;

export const LabelSmallBold = (props) => <LabelSmall fontWeight={FONT_WEIGHT_BOLD} {...props} />;
