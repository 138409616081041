import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { ClientsFilterBar, ClientsTable } from '~/components/clients';
import { Button } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import { PlusIcon } from '~/components/shared/svg';
import TabFilters from '~/components/shared/TabFilters';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

function ClientsList({ onTabSelect, tabs, ...props }) {
  const profileSvc = useProfileContext();
  const canAddClients = profileSvc.has(PERMISSIONS.adminClientCreate);
  const navigateToNewClient = () => props.history.push('/clients/new');
  const activeTab = tabs.find((tab) => tab.label === 'Clients');
  const addClientButton = (
    <Button onClick={navigateToNewClient} icon={<PlusIcon size={14} fill={colors.white} />} text='Add Client' />
  );

  return (
    <MainPage
      title='Clients'
      headerWhite
      rightContent={canAddClients && addClientButton}
      headerContent={
        <Fragment>
          <ClientsFilterBar />
          <TabFilters tabs={tabs} onTabClick={onTabSelect} selectedTab={activeTab} />
        </Fragment>
      }>
      <ClientsTable {...props} />
    </MainPage>
  );
}

ClientsList.propTypes = {
  history: PropTypes.instanceOf(Object),
  onTabSelect: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default ClientsList;
