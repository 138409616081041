import { DefaultTheme } from 'styled-components';

import colors from './colors';
import dimensions from './dimensions';
import textStyles from './textStyles';

export { colors, dimensions, textStyles };

const theme: DefaultTheme = {
  colors,
  dimensions,
  textStyles,
};

export default theme;
