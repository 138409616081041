import React from 'react';
import styled from 'styled-components';

import { InputGroup, MultiSelect, MultiSelectLabel } from '~/components/shared/form';
import { BodySmall, H3 } from '~/components/shared/typography';
import { EPISODE, PLAN_TYPE } from '~/constants/classifications';
import { fetchClassifications } from '~/ducks/classifications';
import { fetchGroups } from '~/ducks/groups';
import { fetchUsers } from '~/ducks/users';
import { getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';
import { GroupType } from '~/models';
import { colors } from '~/styles/theme';

type Props = {
  actingClientId: string;
  includeCaseManagersScope: boolean;
  includeUtilizationManagersScope: boolean;
  providerType: GroupType;
  disabled?: boolean;
};

const baseSelectProps = {
  styles: { placeholder: { color: colors.black } },
  isClearable: true,
  component: MultiSelect,
  labelComponent: MultiSelectLabel,
  selectedCountDefault: 'All',
  getOptionLabel: getName,
  getOptionValue: getId,
  placeholder: 'All',
};

export default function NotificationPreferenceScopes({
  actingClientId,
  includeCaseManagersScope,
  includeUtilizationManagersScope,
  providerType,
  disabled = false,
}: Props) {
  const asyncGroupOptions = useAsyncOptions(fetchGroups, {
    params: {
      type: providerType.apiName,
      include: 'groupType',
      sortBy: 'name',
    },
  });

  const asyncPlanTypeOptions = useAsyncOptions(fetchClassifications, {
    params: {
      type: PLAN_TYPE,
      sortBy: 'name',
    },
  });

  const asyncEpisodeTypeOptions = useAsyncOptions(fetchClassifications, {
    params: {
      type: EPISODE,
      sortBy: 'name',
    },
  });

  const asyncCaseManagerOptions = useAsyncOptions(fetchUsers, {
    condition: includeCaseManagersScope,
    params: {
      client: actingClientId,
      include: 'credential',
      sortBy: 'name',
    },
  });

  return (
    <div>
      <StyledH3>Notification Settings</StyledH3>
      <BodyExtraSmall>
        Indicate below if you wish to only receive notifications for {providerType.displayName} patients that meet a
        specific set of criteria
      </BodyExtraSmall>
      <InputGroup
        {...baseSelectProps}
        {...asyncGroupOptions}
        name={`${providerType.apiName}.scopes.groups`}
        label={`Specific ${providerType.displayName}s`}
        data-cy='groupScopes'
        disabled={disabled}
      />

      <InputGroup
        {...baseSelectProps}
        {...asyncPlanTypeOptions}
        name={`${providerType.apiName}.scopes.planTypes`}
        label='Specific Plan Types'
        data-cy='planTypeScopes'
        disabled={disabled}
      />

      <InputGroup
        {...baseSelectProps}
        {...asyncEpisodeTypeOptions}
        name={`${providerType.apiName}.scopes.episodes`}
        label='Specific Episode Types'
        data-cy='episodeScopes'
        disabled={disabled}
      />

      {includeUtilizationManagersScope && (
        <InputGroup
          {...baseSelectProps}
          {...asyncCaseManagerOptions}
          name={`${providerType.apiName}.scopes.utilizationManagers`}
          label='Specific Utilization Managers'
          data-cy='utilizationManagerScopes'
          disabled={disabled}
        />
      )}

      {includeCaseManagersScope && (
        <InputGroup
          {...baseSelectProps}
          {...asyncCaseManagerOptions}
          name={`${providerType.apiName}.scopes.caseManagers`}
          label='Specific Case Managers'
          data-cy='caseManagerScopes'
          disabled={disabled}
        />
      )}
    </div>
  );
}

const StyledH3 = styled(H3)`
  margin-bottom: 12px;
`;

const BodyExtraSmall = styled(BodySmall)`
  font-size: 12px;
  margin-bottom: 24px;
`;
