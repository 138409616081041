interface IAttrMatchOption {
  id: string,
  name: string
}

const defaults: IAttrMatchOption = {
  id: '',
  name: ''
};

class AttrMatchOption implements IAttrMatchOption {
  id: string;
  name: string;

  constructor(options: IAttrMatchOption) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.name = opts.name;
  }
}

export default AttrMatchOption;
