import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CalendarIcon2 } from '~/components/shared/svg';
import { colors } from '~/styles/theme';

import { StyledInput } from '../Input';

/* eslint-disable react/prefer-stateless-function */
/* Has to be a class Component for react-datepicker library to be able to work properly */
class DatePickerInput extends Component {
  constructor(props) {
    super(props);

    this.inputRef = null;
    this.handleCalendarClick = this.handleCalendarClick.bind(this);
    this.setInputRef = this.setInputRef.bind(this);
  }

  render() {
    const { disabled } = this.props;

    return (
      <InputContainer>
        <StyledInput {...this.props} ref={this.setInputRef} />
        <CalendarIconContainer {...this.props} onClick={this.handleCalendarClick}>
          <CalendarIcon2 size={14} fill={disabled ? colors.black25 : colors.black} />
        </CalendarIconContainer>
      </InputContainer>
    );
  }

  handleCalendarClick(e) {
    if (this.inputRef) {
      this.inputRef.focus();
    }
    this.props.onClick(e);
  }

  setInputRef(input) {
    this.inputRef = input;
  }
}

DatePickerInput.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

DatePickerInput.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export default DatePickerInput;

export const InputContainer = styled.div`
  width: 100%;
  max-width: 200px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
`;

const CalendarIconContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
