import React from 'react';
import PropTypes from 'prop-types';

const ErrorStatusIcon = ({ size = 16 }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='view-status-details-import-history' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='ImportDetailsStatus' transform='translate(-239.000000, -203.000000)' fill='#D10D44' fillRule='nonzero'>
          <g id='c-warning' transform='translate(239.000000, 203.000000)'>
            <path
              d='M8,0 C3.589,0 0,3.589 0,8 C0,12.411 3.589,16 8,16 C12.411,16 16,12.411 16,8 C16,3.589 12.411,0 8,0 Z M8.9645,3.5 L8.5,9.5 L7.5,9.5 L7.0355,3.5 L8.9645,3.5 Z M8,12.5 C7.4475,12.5 7,12.0525 7,11.5 C7,10.9475 7.4475,10.5 8,10.5 C8.5525,10.5 9,10.9475 9,11.5 C9,12.0525 8.5525,12.5 8,12.5 Z'
              id='Shape'></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

ErrorStatusIcon.propTypes = {
  size: PropTypes.number,
};

export default ErrorStatusIcon;
