import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import { PlusIcon } from '~/components/shared/svg';
import TabFilters from '~/components/shared/TabFilters';
import { ControlledTable } from '~/components/shared/table';
import { DISCHARGE } from '~/constants/questionTemplateTypes';
import {
  clearFilters,
  getDischargeTemplatesFilters,
  getDischargeTemplatesFiltersForRequest,
  setFilter,
} from '~/ducks/dischargeTemplatesFilters';
import {
  clearQuestionTemplates,
  fetchQuestionTemplates,
  getQuestionTemplates,
  getQuestionTemplatesLoaded,
  getQuestionTemplatesPageCount,
} from '~/ducks/questionTemplates';
import { useModel } from '~/lib/hooks';
import { QuestionTemplate } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

import DischargeTemplatesFilterBar from './DischargeTemplatesFilterBar';
import dischargeTemplatesTableColumns from './dischargeTemplatesTableColumns';

function DischargeTemplates({ filters, filtersForRequest, history, loaded, onTabSelect, tabs, pageCount, ...props }) {
  const profileSvc = useProfileContext();
  const canAddTemplates = profileSvc.has(PERMISSIONS.adminQuestionTemplateCreate);
  const [pagingFilters, setPagingFilters] = useState({});
  const debouncedFetchQuestionTemplates = useCallback(
    _.debounce((params) => props.fetchQuestionTemplates(params), 50),
    []
  );
  const templates = useModel(QuestionTemplate, props.templates);
  const baseUrl = '/question-templates/discharge';
  const handleAdd = useCallback(() => history.push(`${baseUrl}/new`), []);
  const handleEdit = useCallback((template) => {
    history.push(`${baseUrl}/${template.id}/edit`);
  }, []);

  useEffect(() => {
    props.clearQuestionTemplates();
    debouncedFetchQuestionTemplates({ ...pagingFilters, ...filtersForRequest, type: DISCHARGE });
  }, [pagingFilters, filtersForRequest]);

  const handlePagingFiltersChange = useCallback((newPagingFilters) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const columns = useMemo(() => dischargeTemplatesTableColumns({ profileSvc, onEdit: handleEdit }), []);

  const addTemplateButton = (
    <Button onClick={handleAdd} text='Add Template' icon={<PlusIcon size={14} fill={colors.white} />} />
  );

  const activeTab = useMemo(() => tabs[1], []);

  return (
    <MainPage
      title='Question Templates'
      headerWhite
      headerContent={
        <Fragment>
          <DischargeTemplatesFilterBar
            clearFilters={props.clearFilters}
            filters={filters}
            setFilter={props.setFilter}
          />
          <TabFilters tabs={tabs} onTabClick={onTabSelect} selectedTab={activeTab} />
        </Fragment>
      }
      rightContent={canAddTemplates && addTemplateButton}>
      <ControlledTable
        data={templates}
        defaultSortBy={'clientName asc'}
        loading={!loaded}
        columns={columns}
        filters={filters}
        onPagingFiltersChange={handlePagingFiltersChange}
        pageCount={pageCount}
      />
    </MainPage>
  );
}

const mapStateToProps = (state) => ({
  filters: getDischargeTemplatesFilters(state),
  filtersForRequest: getDischargeTemplatesFiltersForRequest(state),
  loaded: getQuestionTemplatesLoaded(state),
  pageCount: getQuestionTemplatesPageCount(state),
  templates: getQuestionTemplates(state),
});

const mapDispatchToProps = {
  clearFilters,
  clearQuestionTemplates,
  fetchQuestionTemplates,
  setFilter,
};

DischargeTemplates.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  clearQuestionTemplates: PropTypes.func.isRequired,
  fetchQuestionTemplates: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object).isRequired,
  filtersForRequest: PropTypes.instanceOf(Object).isRequired,
  loaded: PropTypes.bool.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  setFilter: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  templates: PropTypes.instanceOf(Array).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DischargeTemplates);
