import { createAsyncThunk } from '~/lib';
import { GroupType } from '~/models';
import { adminGroupTypesApi } from '~/services/api';
import { BaseIndexParams } from '~/types';

const cancellableFetchGroupTypes = adminGroupTypesApi.fetch.cancellable();

const SLICE_NAME = 'admin/groupTypes';

export type FetchGroupTypesAPIParams = {
  search: string;
  inpatient: boolean;
  type: 'provider';
} & BaseIndexParams;

export const fetchGroupTypes = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (params: FetchGroupTypesAPIParams) => {
    const res = await cancellableFetchGroupTypes(params);

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: GroupType,
  } as any
);

export const fetchGroupType = createAsyncThunk(
  `${SLICE_NAME}/fetchById`,
  async (id: string) => {
    const res = await adminGroupTypesApi.fetchById.invoke(id);

    return res.data;
  },
  {
    modelClass: GroupType,
  } as any
);

export type CreateGroupTypeAPIParams = {
  name: string;
  displayName: string;
  type: string;
  isInpatient: boolean;
};

export const createGroupType = createAsyncThunk(`${SLICE_NAME}/create`, async (params: CreateGroupTypeAPIParams) => {
  const res = await adminGroupTypesApi.create.invoke(params);

  return res.data;
});

export type UpdateGroupTypeAPIParams = Partial<CreateGroupTypeAPIParams> & {
  id: string;
};

export const updateGroupType = createAsyncThunk(`${SLICE_NAME}/update`, async (params: UpdateGroupTypeAPIParams) => {
  const res = await adminGroupTypesApi.update.invoke(params.id, params);

  return res.data;
});
