import React from 'react';
import styled from 'styled-components';

import { authService, INACTIVE_USER_ERROR_MESSAGE, LINK_ACCOUNT_ERROR_MESSAGE } from '~/services/auth';
import colors from '~/styles/theme/colors';

import InactiveUserError from './InactiveUserError';
import LinkAccountError from './LinkAccountError';
import UnhandledError from './UnhandledError';

const COMPONENT_MAP = {
  [INACTIVE_USER_ERROR_MESSAGE]: InactiveUserError,
  [LINK_ACCOUNT_ERROR_MESSAGE]: LinkAccountError,
};

function Error() {
  const ErrorComponent = COMPONENT_MAP[authService.error?.split(':')[0]] || UnhandledError;

  return (
    <Main>
      <Container>
        <OlioLogo src='https://static-assets.olio.health/images/olio-block-logo-dark.png' />
        <ErrorComponent />
      </Container>
    </Main>
  );
}

export default Error;

const Main = styled.div`
  align-items: center;
  background-color: rgba(50, 83, 239, 0.05);
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

const Container = styled.div`
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.black10};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 250px;
  min-height: 400px;
  max-width: 500px;
  padding-bottom: 50px;
`;

const OlioLogo = styled.img`
  margin: 40px 0px 60px 0px;
  width: 100px;
`;
