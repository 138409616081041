import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ControlledTable } from '~/components/shared/table';
import { clearClients, fetchClients, getClients, getClientsLoaded, getClientsPageCount } from '~/ducks/admin/clients';
import { clearFilters, getClientsFilters, getClientsFiltersForRequest, setFilter } from '~/ducks/clientsFilters';
import { useModel } from '~/lib/hooks';
import { Client } from '~/models';
import { useProfileContext } from '~/services/profile';

import clientsTableColumns from './clientsTableColumns';

function ClientsTable({ filters, filtersForRequest, loaded, onCollaborationEdit, pageCount, ...props }) {
  const profileSvc = useProfileContext();
  const [pagingFilters, setPagingFilters] = useState({});
  const debouncedFetchClients = useCallback(
    _.debounce((params) => props.fetchClients(params), 50),
    []
  );
  const clients = useModel(Client, props.clients);

  useEffect(() => {
    props.clearClients();
    debouncedFetchClients({
      ...pagingFilters,
      ...filtersForRequest,
      include: 'groupTypeDisplayNames',
    });
  }, [pagingFilters, filtersForRequest]);

  const handlePagingFiltersChange = useCallback((newPagingFilters) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const handleEditClient = useCallback((client) => {
    props.history.push(`/clients/${client.id}/edit`);
  }, []);

  const handleEditClientGroupTypes = useCallback((client) => {
    props.history.push(`/clients/${client.id}/group-types/edit`);
  }, []);

  const columns = useMemo(
    () =>
      clientsTableColumns({
        profileSvc,
        onEdit: handleEditClient,
        onEditClientGroupTypes: handleEditClientGroupTypes,
        onCollaborationEdit,
      }),
    []
  );

  return (
    <ControlledTable
      data={clients}
      defaultSortBy={'name asc'}
      loading={!loaded}
      columns={columns}
      filters={filters}
      onPagingFiltersChange={handlePagingFiltersChange}
      pageCount={pageCount}
    />
  );
}
ClientsTable.propTypes = {
  clearClients: PropTypes.func,
  clearFilters: PropTypes.func.isRequired,
  clients: PropTypes.instanceOf(Array),
  fetchClients: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  filtersForRequest: PropTypes.instanceOf(Object),
  loaded: PropTypes.bool,
  onCollaborationEdit: PropTypes.func.isRequired,
  pageCount: PropTypes.number,
  setFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  clients: getClients(state),
  filters: getClientsFilters(state),
  filtersForRequest: getClientsFiltersForRequest(state),
  pageCount: getClientsPageCount(state),
  loaded: getClientsLoaded(state),
});

const mapDispatchToProps = {
  clearClients,
  clearFilters,
  fetchClients,
  setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsTable);
