import axios, { AxiosRequestConfig } from 'axios';

import { IRequestOptions } from '~/services/api/core/IRequestOptions';
import client from '~/sources/api/client';

export default class ApiRequest {
  _method: any;
  _url: any;
  _config?: AxiosRequestConfig;
  _formatArgsForConfig: any;
  constructor(options: IRequestOptions = {}) {
    const { formatArgsForConfig = (args: any) => args, method = 'get', url, config }: IRequestOptions = options;

    this._method = method;
    this._url = url;
    this._config = config;
    this._formatArgsForConfig = formatArgsForConfig;
  }

  get defaultConfig() {
    return {
      method: this._method,
      url: this._url,
      ...this._config,
    };
  }

  generateConfig(...args: any[]) {
    return {
      ...this.defaultConfig,
      ...this.formatArgsForConfig(...args),
    };
  }

  formatArgsForConfig(...args: any[]) {
    return this._formatArgsForConfig(...args);
  }

  invoke(...args: any[]) {
    const config = this.generateConfig(...args);

    return client(config);
  }

  cancellable() {
    let controller: AbortController;

    return (...args: any) => {
      const config = this.generateConfig(...args);

      if (controller) {
        controller.abort();
      }

      controller = new AbortController();
      return client({ ...config, signal: controller.signal }).catch((error) => {
        if (!axios.isCancel(error)) {
          throw error;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          return { then: () => {}, catch: () => {}, finally: () => {} };
        }
      });
    };
  }
}
