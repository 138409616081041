const defaults = {
  id: '',
  name: ''
};

export default class Role {
  constructor(options = {}) {
    const opts = { ...defaults, ...options };

    this.id = opts.id;
    this.name = opts.name;
  }
}
