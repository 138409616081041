import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import PageTitle from '~/components/shared/pageLayout/PageTitle';
import colors from '~/styles/theme/colors';

import { H3 } from '../typography';

function MainPageHeader(props) {
  const { headerWhite, middleContent, rightContent, headerContent, subtitle, title, showPageInfoContainer } = props;

  return (
    <HeaderContainer id='pageHeader' {...{ headerWhite, showPageInfoContainer }}>
      {showPageInfoContainer && (
        <PageInfoContainer>
          <div>
            <PageTitle>{title}</PageTitle>
            {subtitle && (
              <SubTitleContainer>
                <H3 color={colors.primaryBlue}>{subtitle}</H3>
              </SubTitleContainer>
            )}
          </div>
          <MiddleContent>{middleContent}</MiddleContent>
          <RightContent>{rightContent}</RightContent>
        </PageInfoContainer>
      )}
      {headerContent && <HeaderContentContainer>{headerContent}</HeaderContentContainer>}
    </HeaderContainer>
  );
}

export default MainPageHeader;

MainPageHeader.propTypes = {
  headerContent: PropTypes.instanceOf(Object),
  headerWhite: PropTypes.bool,
  middleContent: PropTypes.instanceOf(Object),
  rightContent: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.bool]),
  showPageInfoContainer: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

const HeaderContainer = styled.div`
  padding: ${({ showPageInfoContainer }) => (showPageInfoContainer ? '24px 24px 0 24px' : '0px 24px 0 24px;')};
  background-color: ${({ headerWhite }) => (headerWhite ? colors.white : 'transparent')};
  border-bottom: ${({ headerWhite }) => (headerWhite ? `1px solid ${colors.black10}` : 'none')};
`;

const HeaderContentContainer = styled.div`
  margin-top: 12px;
`;

const PageInfoContainer = styled.div`
  display: flex;
  min-height: 40px;
`;

const MiddleContent = styled.div`
  @media (max-width: 960px) {
    display: none;
  }
`;

const RightContent = styled.div`
  margin-left: auto;
`;

const SubTitleContainer = styled.div`
  margin-top: 20px;
`;
