import { Api } from './core';

export const url = '/users';

const { fetch, update } = new Api({ url });

export default {
  fetch,
  update,
};
