// This should be used in combination with our createAsyncThunk function.
// Our createAsyncThunk function calls rejectWithValue when a thunk encounters
// an error, which places the error in the "payload" of the result. The api reducer
// will capture the error and place it in the "api" slice of the store, but in
// scenarios where we want to call a thunk directly in a component and handle the
// result/error, we should use this unwrapResult. If this isn't used, "error" will be sent
// to the "catch" argument and it is redux toolkit's SerializedError which does
// not include status code or response payload.

const unwrapResult = (result) => {
  if ('error' in result) {
    throw result.payload;
  }

  return result.payload;
};

export default unwrapResult;
