import React from 'react';
import PropTypes from 'prop-types';

import { StandardModal } from '~/components/shared/modal';

import ClientSelectForm from './ClientSelectForm';

function ClientSelectModal(props) {
  const {
    history,
    isOpen,
    onCancel
  } = props;

  const handleOnComplete = () => {
    onCancel();
    history.push('/patients');
  };

  return (
    <StandardModal show={ isOpen }
      title={ 'Select View' }
      onCancel={ onCancel }
      disableBackdropClick>
      <ClientSelectForm onComplete={ handleOnComplete } onCancel={ onCancel } />
    </StandardModal>
  );
}

ClientSelectModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ClientSelectModal;
