/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { History, LocationState } from 'history';

import AttributesFilterBar from '~/components/attributes/AttributesFilterBar';
import MainPage from '~/components/shared/pageLayout/MainPage';
import TabFilters from '~/components/shared/TabFilters';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

import { Button } from '../shared/buttons';
import { PlusIcon } from '../shared/svg';

import AttributesTable from './AttributesTable';

type Tab = {
  label: string;
  value: string;
};

function AttributesList({ ...props }: AttributeListProps) {
  const profileSvc: any = useProfileContext();
  const canAddAttrs = profileSvc.has(PERMISSIONS.adminAttrCreate);
  const activeTab = props.tabs.find((tab) => {
    return tab.label === 'Attributes';
  });
  const navigateToNewAttribute = () => props.history.push('/attributes/new');
  const addAttributeButton = (
    <Button onClick={navigateToNewAttribute} icon={<PlusIcon size={14} fill={colors.white} />} text='Add Attribute' />
  );

  return (
    <MainPage
      title='Attributes'
      headerWhite
      rightContent={canAddAttrs && addAttributeButton}
      headerContent={
        <Fragment>
          <AttributesFilterBar></AttributesFilterBar>
          <TabFilters tabs={props.tabs} onTabClick={props.onTabSelect} selectedTab={activeTab} />
        </Fragment>
      }>
      <AttributesTable {...props} />
    </MainPage>
  );
}
interface AttributeListProps {
  onTabSelect: (tab: string) => void;
  tabs: Tab[];
  history: History<LocationState>;
}

export default AttributesList;
