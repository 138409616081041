import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import styled from 'styled-components';

import InfiniteScroll from '~/components/shared/InfiniteScroll';

function InfiniteScrollMenuList({ innerRef: _, ...props }) {
  const { bindMenuListRef = () => {}, hasMore = false, isLoading = false, onEndReached = () => {} } = props.selectProps;

  const menuListRef = useRef(null);

  // We invoke bindMenuListRef here because the parent component does
  // not have access to it, even if you get the ref to the React Select.
  // We can't handle and pass through the onChange for the Select in
  // this component, so bind the MenuList ref and let the parent implement
  // the needed onChange logic that retrieves more pages of data if the
  // menu list isn't scrollable.
  const handleInnerRef = useCallback((element) => {
    bindMenuListRef(element);
    menuListRef.current = element;
  }, []);

  return (
    <components.MenuList {...props} innerRef={handleInnerRef}>
      {props.children}
      <StyledInfiniteScroll
        element={menuListRef.current}
        hasMore={hasMore}
        loading={isLoading}
        onEndReached={onEndReached}
      />
    </components.MenuList>
  );
}

InfiniteScrollMenuList.propTypes = {
  innerRef: PropTypes.func,
  selectProps: PropTypes.instanceOf(Object),
};

export default InfiniteScrollMenuList;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  height: 10px !important;
`;
