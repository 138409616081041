import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import { PlusIcon } from '~/components/shared/svg';
import { ControlledTable } from '~/components/shared/table';
import { fetchClassifications } from '~/ducks/admin/classifications';
import { fetchClients, getClients } from '~/ducks/admin/clients';
import {
  clearFilters,
  getClassificationFilters,
  getClassificationFiltersForRequest,
  setFilter,
} from '~/ducks/classificationFilters';
import { useModel, useThunk } from '~/lib/hooks';
import { Client } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

import ClassificationFilterBar from './ClassificationFilterBar';
import ClassificationModal from './ClassificationModal';
import classificationsTableColumns from './classificationsTableColumns';
import { classificationPageTitle, classificationTitle, classificationTypeFromRoute } from './helpers';

function Classifications(props) {
  const { filters, filtersForRequest } = props;
  const profileSvc = useProfileContext();
  const canAddClassifications = profileSvc.has(PERMISSIONS.adminClassificationCreate);
  const type = classificationTypeFromRoute(props.match.params.type);
  const addEditTitle = useMemo(() => classificationTitle(type), [type]);
  const pageTitle = useMemo(() => classificationPageTitle(type), [type]);
  const [pageCount, setPageCount] = useState(0);
  const [pagingFilters, setPagingFilters] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentClassificationId, setCurrentClassificationId] = useState(null);

  const addNewClassification = useCallback(() => setShowModal(true), []);

  const handleEditClassification = useCallback((classification) => {
    setCurrentClassificationId(classification.id);
    setShowModal(true);
  }, []);

  const handleCancel = useCallback(() => {
    setCurrentClassificationId(null);
    setShowModal(false);
  }, []);

  useEffect(() => {
    props.fetchClients();
  }, []);

  useEffect(() => {
    props.clearFilters();
  }, [type]);

  const { data: classifications, loaded: classificationsLoaded } = useThunk(
    fetchClassifications,
    [pagingFilters, filtersForRequest, type, showModal],
    {
      condition: !showModal,
      onSuccess: (payload) => {
        setPageCount(payload?.meta?.totalPages);
      },
      params: { ...pagingFilters, ...filtersForRequest, type: type },
      debounce: 50,
    }
  );

  const clients = useModel(Client, props.clients);

  const handlePagingFiltersChange = useCallback((newPagingFilters) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const addClassificationButton = (
    <Button
      onClick={addNewClassification}
      icon={<PlusIcon size={14} fill={colors.white} />}
      text={`Add ${addEditTitle}`}
    />
  );

  const columns = useMemo(
    () =>
      classificationsTableColumns({
        profileSvc,
        onEdit: handleEditClassification,
        type: addEditTitle,
      }),
    []
  );

  return (
    <MainPage title={pageTitle} rightContent={canAddClassifications && addClassificationButton}>
      <ClassificationFilterBar
        clearFilters={props.clearFilters}
        clients={clients}
        filters={filters}
        setFilter={props.setFilter}
      />
      <ControlledTable
        data={classifications}
        defaultSortBy={'name asc'}
        loading={!classificationsLoaded}
        columns={columns}
        filters={filters}
        onPagingFiltersChange={handlePagingFiltersChange}
        pageCount={pageCount}
      />
      <ClassificationModal
        isOpen={showModal}
        onCancel={handleCancel}
        type={type}
        title={addEditTitle}
        classificationId={currentClassificationId}
      />
    </MainPage>
  );
}

Classifications.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  fetchClassifications: PropTypes.func.isRequired,
  fetchClients: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object),
  filtersForRequest: PropTypes.instanceOf(Object),
  setFilter: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  filters: getClassificationFilters(state),
  filtersForRequest: getClassificationFiltersForRequest(state),
  clients: getClients(state),
});

const mapDispatchToProps = {
  clearFilters,
  fetchClients,
  fetchClassifications,
  setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Classifications);
