import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useDeepEffect } from '~/lib/hooks';
import useField from '~/lib/hooks/useField';

function ToggleGroup(props) {
  const {
    autoSelectFirstOption,
    disabled,
    getOptionLabel,
    getOptionValue,
    onChange,
    options,
    visible
  } = props;

  const [field, meta, helpers] = useField(props);
  const { name } = field;

  const handleChange = (val) => {
    if (disabled) return;

    helpers.setValue(val);
    onChange(val, name, helpers.setValue);
  };

  const areEqual = (option, otherOption) => option && otherOption && getOptionValue(option) === getOptionValue(otherOption);
  const handleOptionsChange = () => {
    const optionValueChanged = !(areEqual(meta.value, options[0]) ||
      options.length && areEqual(meta.value, getOptionValue(options[0])));

    if (autoSelectFirstOption && options.length > 0 && optionValueChanged) {
      handleChange(options[0]);
    }
  };

  useDeepEffect(handleOptionsChange, [autoSelectFirstOption, options, disabled]);

  const renderButton = (option) => {
    const optionValue = getOptionValue(option);
    const selectedValue = meta.value && getOptionValue(meta.value) || meta.value;
    const handleOnClick = () => handleChange(option);

    return (
      <Button key={ optionValue }
        className={ !disabled && (optionValue === selectedValue && 'active') }
        data-cy={ optionValue }
        disabled={ disabled }
        onClick={ handleOnClick }>{ getOptionLabel(option) }
      </Button>
    );
  };

  return (
    <Container>
      { visible && options.map((option) => renderButton(option)) }
    </Container>
  );
}

ToggleGroup.propTypes = {
  autoSelectFirstOption: PropTypes.bool,
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  hasError: PropTypes.bool,
  menuPlacement: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  visible: PropTypes.bool
};

ToggleGroup.defaultProps = {
  autoSelectFirstOption: true,
  disabled: false,
  getOptionLabel: (option) => option?.label,
  getOptionValue: (option) => option?.value,
  hasError: false,
  menuPlacement: 'auto',
  onChange: () => {},
  visible: true
};

export default ToggleGroup;

const Container = styled.div`
  display: flex;
  width: 100%;
  border-radius: 4px;
`;

const Button = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: ${({ disabled, theme }) => disabled ? theme.colors.black25 : theme.colors.black};
  background-color: ${({ disabled, theme }) => disabled ? theme.colors.black10 : theme.colors.white};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
  font-size: 16px;
  font-weight: bold;
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${({ disabled, theme }) => disabled ? theme.colors.black10 : theme.colors.black25};
  &:first-child {
    border-left: 1px solid ${({ disabled, theme }) => disabled ? theme.colors.black10 : theme.colors.black25};
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &.active {
    background-color: ${({ theme }) => theme.colors.primaryBlue};
    border-color: ${({ theme }) => theme.colors.primaryBlue};
    color: ${({ theme }) => theme.colors.white};
  }
`;
