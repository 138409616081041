import React from 'react';
import PropTypes from 'prop-types';

import { FlyoutMenu, FlyoutMenuIcon, FlyoutMenuItem } from '~/components/shared/FlyoutMenu';
import { DeactivateIcon, PencilIcon } from '~/components/shared/svg';
import { BodySmall } from '~/components/shared/typography';
import { Group } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import { colors } from '~/styles/theme';

const { adminGroupDelete, adminGroupEdit } = PERMISSIONS;

export const permissions = [adminGroupDelete, adminGroupEdit];

function GroupsFlyoutMenu(props) {
  const { onDelete, onEdit, row } = props;
  const archived = row.original.archived;

  const profileSvc = useProfileContext();

  return (
    <FlyoutMenu data={row.original}>
      <FlyoutMenuItem onClick={onEdit} visible={!archived && profileSvc.has(adminGroupEdit)}>
        <FlyoutMenuIcon>
          <PencilIcon size={18} />
        </FlyoutMenuIcon>
        Edit
      </FlyoutMenuItem>
      <FlyoutMenuItem onClick={onDelete} visible={!archived && profileSvc.has(adminGroupDelete)}>
        <FlyoutMenuIcon>
          <DeactivateIcon size={18} />
        </FlyoutMenuIcon>
        <BodySmall color={colors.accentRed}>Archive</BodySmall>
      </FlyoutMenuItem>
    </FlyoutMenu>
  );
}

GroupsFlyoutMenu.propTypes = {
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onEditPermissions: PropTypes.func,
  onReactivate: PropTypes.func,
  onResendInvitation: PropTypes.func,
  onViewPreferences: PropTypes.func,
  row: PropTypes.shape({
    original: PropTypes.instanceOf(Group),
  }),
};

export default GroupsFlyoutMenu;
