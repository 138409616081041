export default class AttachmentBlob {
  id: string;
  signedId: string;
  filename: string;
  contentType: string;
  metadata: Record<string, unknown>;

  constructor(options: Partial<AttachmentBlob> = {}) {
    const opts = { ...options };

    this.id = opts.id || '';
    this.signedId = opts.signedId || '';
    this.filename = opts.filename || '';
    this.contentType = opts.contentType || '';
    this.metadata = opts.metadata || {};
  }
}
