import UserPreference, {
  defaults as userPreferenceDefaults,
  UserPreferenceOptions,
  UserPreferenceType,
} from '../UserPreference';

type NotificationUserPreferenceOptions = UserPreferenceOptions;

const defaults: NotificationUserPreferenceOptions = {
  ...userPreferenceDefaults,
  type: UserPreferenceType.NOTIFICATION,
};

class NotificationUserPreference extends UserPreference {
  constructor(options: Partial<NotificationUserPreferenceOptions> = defaults) {
    const opts = { ...defaults, ...options };

    super(opts);
  }

  static get type() {
    return UserPreferenceType.NOTIFICATION;
  }

  serialize() {
    const { activity, criticalAlerts, patientStatusChanges } = this.value;

    return {
      ...super.serialize(),
      value: {
        activity,
        criticalAlerts,
        patientStatusChanges,
      },
    };
  }
}

export default NotificationUserPreference;
