import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ConfirmationModal } from '~/components/shared/modal';
import { ControlledTable, CountMessage } from '~/components/shared/table';
import UsersExportDownload from '~/components/users/UsersExportDownload';
import { SEARCH } from '~/constants/filterKeysConstants';
import {
  deleteUser,
  fetchUsers,
  getUsers,
  getUsersLoaded,
  getUsersPageCount,
  getUsersTotalRecords,
  reactivateUser,
} from '~/ducks/admin/users';
import { clearFilters, getUsersFilters, getUsersFiltersForRequest, setFilter } from '~/ducks/admin/usersFilters';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { useCopyToClipboard } from '~/lib/hooks';
import { useModel } from '~/lib/hooks';
import { User } from '~/models';
import { adminUsersApi } from '~/services/api';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

import { Button, ButtonGroup } from '../shared/buttons';
import { MainPage } from '../shared/pageLayout';
import { PlusIcon } from '../shared/svg';

import UsersFilterBar from './UsersFilterBar';
import usersTableColumns from './usersTableColumns';

const fetchUsersIncludeParam = 'client,credential,allowedGroupNames,role.permissions,enabledProviderTypeNames';

function Users({ loaded, filters, totalRecords, pageCount, filtersForRequest, ...props }) {
  const profileSvc = useProfileContext();
  const { adminUserCreate, adminUserDelete, adminUserInvitationCreate } = PERMISSIONS;
  const [pagingFilters, setPagingFilters] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [tableProps, setTableProps] = useState({ setSorting: () => {} });
  const canAddUsers = profileSvc.has(adminUserCreate);
  const { clipboardText, copyToClipboard } = useCopyToClipboard();
  const debouncedFetchUsers = useCallback(
    _.debounce((params) => props.fetchUsers(params), 50),
    []
  );
  const users = useModel(User, props.users);

  useEffect(() => {
    debouncedFetchUsers({
      ...pagingFilters,
      ...filtersForRequest,
      include: fetchUsersIncludeParam,
    });
  }, [pagingFilters, filtersForRequest]);

  const navigateToNewUser = useCallback(() => props.history.push('/users/new'), []);

  const headerButtons = (
    <ButtonGroup>
      <UsersExportDownload params={{ ...pagingFilters, ...filtersForRequest, include: fetchUsersIncludeParam }} />
      {canAddUsers && (
        <Button onClick={navigateToNewUser} icon={<PlusIcon size={14} fill={colors.white} />} text='Add User' />
      )}
    </ButtonGroup>
  );

  const handleEdit = useCallback((user) => props.history.push(`/users/${user.id}/edit`), []);

  const handleDelete = useCallback((user) => {
    if (!profileSvc.has(adminUserDelete)) return;

    setSelectedUserId(user.id);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    props.deleteUser(selectedUserId);
    setSelectedUserId(null);
  }, [selectedUserId]);

  const handleDeleteCancel = useCallback(() => {
    setSelectedUserId(null);
  }, []);

  const handleViewPreferences = useCallback((user) => {
    props.history.push(`/users/${user.id}/preferences`);
  }, []);

  const handleEditPermissions = useCallback((user) => {
    props.history.push(`/users/${user.id}/permissions`);
  });

  const handleResendInvitation = useCallback(async (user) => {
    try {
      if (!profileSvc.has(adminUserInvitationCreate)) return;

      await adminUsersApi.resendInvitation(user.id);
      props.addToast({ text: 'Invitation sent.' });
    } catch {
      props.addToast({ text: 'Something went wrong. Please try again.' });
    }
  }, []);

  const handleReactivate = useCallback((user) => {
    if (!profileSvc.has(adminUserDelete)) return;

    props
      .reactivateUser({
        id: user.id,
        include: fetchUsersIncludeParam,
      })
      .then(unwrapResult)
      .then((res) => {
        const hasSameClient = user.client.id === res.client.id;
        const hasSameGroupAccess = user.allowedGroupNames === res.allowedGroupNames;

        let text = 'User successfully reactivated.';

        if (!hasSameClient) {
          text = "User's client has been changed based on group access.";
        } else if (!hasSameGroupAccess) {
          text = "User's access has changed.";
        }

        props.addToast({ text });
      });
  }, []);

  const handlePagingFiltersChange = useCallback((newPagingFilters) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const columns = useMemo(
    () =>
      usersTableColumns({
        profileSvc,
        onCopy: copyToClipboard,
        onDelete: handleDelete,
        onEdit: handleEdit,
        onEditPermissions: handleEditPermissions,
        onViewPreferences: handleViewPreferences,
        onReactivate: handleReactivate,
        onResendInvitation: handleResendInvitation,
      }),
    []
  );

  const count = <CountMessage count={totalRecords} countType='result' />;

  useEffect(() => {
    if (clipboardText) props.addToast({ text: `${clipboardText} copied to clipboard.` });
  }, [clipboardText]);

  const defaultSortBy = [{ id: 'userName', desc: false }];
  const searchValue = filters[SEARCH];

  useEffect(() => {
    const sortBy = searchValue ? [{ id: SEARCH, desc: true }] : defaultSortBy;

    tableProps.setSorting(sortBy);
  }, [searchValue]);

  return (
    <MainPage title='User Management' rightContent={headerButtons} middleContent={count}>
      <UsersFilterBar usersFilters={filters} setFilter={props.setFilter} clearFilters={props.clearFilters} />
      <ControlledTable
        data={users}
        defaultSortBy={searchValue ? 'search desc' : 'userName asc'}
        loading={!loaded}
        onInitialize={setTableProps}
        columns={columns}
        filters={filters}
        onPagingFiltersChange={handlePagingFiltersChange}
        pageCount={pageCount}
      />
      <ConfirmationModal
        show={Boolean(selectedUserId)}
        actionText='deactivate'
        objType='user'
        subText='They can be reactivated at any time.'
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      />
    </MainPage>
  );
}

Users.propTypes = {
  addToast: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func,
  filters: PropTypes.instanceOf(Object),
  filtersForRequest: PropTypes.instanceOf(Object),
  loaded: PropTypes.bool,
  pageCount: PropTypes.number,
  reactivateUser: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  totalRecords: PropTypes.number,
  users: PropTypes.instanceOf(Array),
};

const mapStateToProps = (state) => ({
  users: getUsers(state),
  loaded: getUsersLoaded(state),
  pageCount: getUsersPageCount(state),
  filters: getUsersFilters(state),
  filtersForRequest: getUsersFiltersForRequest(state),
  totalRecords: getUsersTotalRecords(state),
});

const mapDispatchToProps = {
  addToast,
  clearFilters,
  deleteUser,
  fetchUsers,
  reactivateUser,
  setFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
