import { createAsyncThunk } from '~/lib';
import { Credential } from '~/models';
import { credentialsApi } from '~/services/api';

export const fetchCredentials = createAsyncThunk(
  'credentials/fetch',
  async (payload) => {
    const res = await credentialsApi.fetch.invoke(payload);

    return res.data.items;
  },
  {
    defaultValue: [],
    modelClass: Credential,
  }
);
