import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { convertColorThemeToColors } from './colorTheme';

function Header({ children, onCancel, showCloseButton, ...rest }) {
  return (
    <StyledHeader { ...rest }>
      <HeaderContent>
        { children }
      </HeaderContent>
      { showCloseButton &&
        <CloseButton onClick={ onCancel } { ...rest } >
          &times;
        </CloseButton>
      }
    </StyledHeader>
  );
}

Header.defaultProps = {
  colorTheme: 'primary',
  onCancel: () => {},
  showCloseButton: true
};

Header.propTypes = {
  colorTheme: PropTypes.string,
  onCancel: PropTypes.func,
  showCloseButton: PropTypes.bool
};

export default Header;

const StyledHeader = styled.div.attrs(convertColorThemeToColors)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  flex-grow: 1;

  position: relative;
  height: 60px;
  padding: 20px;

  background-color: ${({ colors }) => colors.header.background };

  color: ${({ colors }) => colors.header.font };
  font-size: 24px;
  font-weight: 100;

  border-radius: 3px 3px 0px 0px;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.div.attrs(convertColorThemeToColors)`
  position: absolute;
  right: 0px;
  top: 0px;

  height: 48px;
  width: 48px;

  color: ${({ colors }) => colors.header.closeButton };
  font-size: 32px;
  font-weight: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;
