import React from 'react';
import PropTypes from 'prop-types';

import remove from '~/assets/images/Delete.svg';
import { Input, InputGroup } from '~/components/shared/form';

import { InlineContainer } from './InlineContainer';

const StatusQuestion = ({ field: { name }, inputLabel, removeItem }) => {
  return (
    <InlineContainer>
      <InputGroup label={inputLabel} name={`${name}.text`} placeholder='Type status prompt here...' component={Input} />
      <img src={remove} onClick={removeItem} />
    </InlineContainer>
  );
};

StatusQuestion.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
  }),
  inputLabel: PropTypes.string.isRequired,
  removeItem: PropTypes.func,
};

export default StatusQuestion;
