import * as Yup from 'yup';

export const classificationValidation = (title) => {
  return (
    Yup.object().shape({
      name: Yup.string().required(`${title} is required`)

    })
  );
};
