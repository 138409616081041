import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import CheckboxOffIcon from '~/components/shared/svg/CheckboxOffIcon';
import CheckboxOnIcon from '~/components/shared/svg/CheckboxOnIcon';
import { Label } from '~/components/shared/typography';
import colors from '~/styles/theme/colors';

function CheckboxMultiSelectOption({ data, isSelected, label, selectOption, ...props }) {
  const handleClick = () => {
    selectOption(data);
  };

  const iconStyles = {
    fill: colors.black,
    width: 14,
    height: 14,
  };

  return (
    <StyledCheckbox onClick={handleClick} hoverColor={props.theme.colors.primary25}>
      <CheckboxContainer size={24}>
        {isSelected ? (
          <CheckboxOnIcon {...{ ...iconStyles, fill: colors.primaryBlue }} />
        ) : (
          <CheckboxOffIcon {...iconStyles} />
        )}
      </CheckboxContainer>
      <StyledLabel fontSize={'14px'}>{label}</StyledLabel>
    </StyledCheckbox>
  );
}

CheckboxMultiSelectOption.propTypes = {
  data: PropTypes.instanceOf(Object),
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  selectOption: PropTypes.func,
  theme: PropTypes.instanceOf(Object),
};

export default CheckboxMultiSelectOption;

const StyledCheckbox = styled.div`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  padding: 8px 12px;
  & label {
    cursor: pointer;
  }
  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
  }
`;

const CheckboxContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 0;
  margin-top: -1px;
  min-width: ${({ size }) => `${size}px`};
  min-height: ${({ size }) => `${size}px`};
`;

const StyledLabel = styled(Label)`
  line-height: 20px;
`;
