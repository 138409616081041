import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import SaveChangesModal from './SaveChangesModal';

function withSaveChangesModal(Component) {
  function WrappedComponent(props) {
    const { bindShowModal, historyBlockCondition, children } = props;

    const history = useHistory();
    const historyUnblock = useRef();

    const [blockedPathname, setBlockedPathname] = useState(null);
    const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);

    const handleSaveChangesCancel = () => {
      setShowSaveChangesModal(false);
    };
    const handleSaveChangesConfirm = () => {
      historyUnblock.current();

      if (blockedPathname) {
        history.push(blockedPathname);
      } else {
        props.onSaveChangesConfirm();

        setShowSaveChangesModal(false);
      }
    };

    useEffect(() => {
      bindShowModal(() => {
        setShowSaveChangesModal(true);

        // The modal is being shown by the parent, not the blocked logic.
        // Clear the pathname in case it was shown via blocked logic before
        // so it's not inadvertantly redirected on confirm of save changes.
        setBlockedPathname(null);
      });
    }, []);

    useEffect(() => {
      historyUnblock.current = history.block(({ pathname }) => {
        if (historyBlockCondition) {
          setBlockedPathname(pathname);
          setShowSaveChangesModal(true);
          return false;
        }

        return true;
      });

      return () => {
        historyUnblock.current();
      };
    }, [historyBlockCondition]);

    return (
      <Component { ...props }>
        { children }
        <SaveChangesModal onCancel={ handleSaveChangesCancel }
          onConfirm={ handleSaveChangesConfirm }
          show={ showSaveChangesModal } />
      </Component>
    );
  }

  WrappedComponent.propTypes = {
    bindShowModal: PropTypes.func,
    children: PropTypes.instanceOf(Object),
    historyBlockCondition: PropTypes.bool,
    onSaveChangesConfirm: PropTypes.func
  };

  WrappedComponent.defaultProps = {
    bindShowModal: () => {},
    historyBlockCondition: false,
    onSaveChangesConfirm: () => {}
  };

  return WrappedComponent;
}

export default withSaveChangesModal;
