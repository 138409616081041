import { ALL_OPTION } from '~/constants/filterKeysConstants';

export const ADMIN = 'Admin';
export const AFFILIATE = 'Affiliate';
export const HEALTH_SYSTEM = 'Health System';
export const INPATIENT_POST_ACUTE_CARE = 'Inpatient Post Acute Care';
export const OUTPATIENT_POST_ACUTE_CARE = 'Outpatient Post Acute Care';
export const PAYOR = 'Payor';
export const PHYSICIAN_GROUP = 'Physician Group';

export const CLIENT_TYPES = [
  ADMIN,
  AFFILIATE,
  HEALTH_SYSTEM,
  INPATIENT_POST_ACUTE_CARE,
  OUTPATIENT_POST_ACUTE_CARE,
  PAYOR,
  PHYSICIAN_GROUP,
];

export const CLIENT_TYPE_SELECT_OPTIONS = [ALL_OPTION, ...CLIENT_TYPES.map((type) => ({ name: type }))];

export const OWNER_CLIENT_TYPES = [HEALTH_SYSTEM, PAYOR, PHYSICIAN_GROUP];

export const ACUTE_CLIENT_TYPES = [AFFILIATE, ...OWNER_CLIENT_TYPES];

export const POST_ACUTE_CLIENT_TYPES = [INPATIENT_POST_ACUTE_CARE, OUTPATIENT_POST_ACUTE_CARE];
