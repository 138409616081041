import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink).attrs({
  draggable: false,
})`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white50};
  font-family: 'Lato';
  text-decoration: none;
  padding: 14px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;

  &:visited {
    color: 'initial';
  }

  &.active {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;

    & > svg {
      opacity: 1;
    }
  }

  & > svg,
  & > div {
    margin-right: 10px;
    opacity: 0.5;
  }
`;

export default StyledNavLink;
