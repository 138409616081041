import React, { Component } from 'react';
import PropTypes from 'prop-types';

class LoadingSpinnerAroundComponent extends Component {
  constructor(props) {
    super();

    const showForAtLeast = props.showForAtLeast || 200;
    const stepsInInterval = 10; // N number of steps in total interval. So, if we want to show for at least 1000 milliseconds, we will check every 100 milliseconds whether or not to resolve

    this.state = {
      timer: null,
      showForAtLeast: showForAtLeast, // milliseconds to show loading indicator to prevent "flashes" of the loading indiciator
      checkEvery: showForAtLeast / stepsInInterval,
    };

    this.updateSpinnerStateIfNecessary = this.updateSpinnerStateIfNecessary.bind(this);
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    // if we were not loading and now we are, show the spinner immediately
    if (!this.props.loading && newProps.loading) {
      this.setState({ showSpinner: true, startedShowingSpinnerAt: new Date() });
    } else if (this.props.loading && !newProps.loading) {
      // if we were loading and now we are not
      // make sure at least N seconds have passed since we showed the indicator
      if (!this.updateSpinnerStateIfNecessary()) {
        this.setState({ timer: setInterval(this.updateSpinnerStateIfNecessary, this.state.checkEvery) });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  render() {
    if (this.state.showSpinner) {
      return (
        <div className='loading-around-component'>
          <div className='sk-circle'>
            <div className='sk-circle1 sk-child' />
            <div className='sk-circle2 sk-child' />
            <div className='sk-circle3 sk-child' />
            <div className='sk-circle4 sk-child' />
            <div className='sk-circle5 sk-child' />
            <div className='sk-circle6 sk-child' />
            <div className='sk-circle7 sk-child' />
            <div className='sk-circle8 sk-child' />
            <div className='sk-circle9 sk-child' />
            <div className='sk-circle10 sk-child' />
            <div className='sk-circle11 sk-child' />
            <div className='sk-circle12 sk-child' />
          </div>

          {this.props.children}
        </div>
      );
    }

    return this.props.children;
  }

  updateSpinnerStateIfNecessary() {
    if (new Date() - this.state.startedShowingSpinnerAt >= this.state.showForAtLeast) {
      this.setState({ showSpinner: false });
      clearInterval(this.state.timer);

      return true;
    }

    return false;
  }
}

LoadingSpinnerAroundComponent.propTypes = {
  loading: PropTypes.bool,
  showForAtLeast: PropTypes.number,
};

export default LoadingSpinnerAroundComponent;
