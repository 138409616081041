export const AFFILIATE = 'affiliate';
export const HHA = 'HHA';
export const HOME_HEALTH_AGENCY = 'home_health_agency';
export const HOSPICE = 'hospice';
export const HOSPITAL = 'hospital';
export const INPATIENT_REHAB_FACILITY = 'inpatient_rehab_facility';
export const IRF = 'IRF';
export const LONG_TERM_CARE = 'long_term_care';
export const LONG_TERM_ACUTE_CARE_HOSPITAL = 'long_term_acute_care_hospital';
export const LTACH = 'LTACH';
export const OTP = 'OTP';
export const OUTPATIENT_THERAPY_PROVIDER = 'outpatient_therapy_provider';
export const PAYER = 'payer';
export const PHYSICIAN_GROUP = 'physician_group';
export const PHYSICIAN_TEAM = 'physician_team';
export const REHAB_FACILITY = 'rehab_facility';
export const SKILLED_NURSING_FACILITY = 'skilled_nursing_facility';
export const SNF = 'SNF';

export const ACUTE_LOCATION_TYPES = [AFFILIATE, HOSPITAL, PHYSICIAN_GROUP, PAYER];

export const GROUP_TYPES_FORMATTED_VALUES = {
  [AFFILIATE]: 'Affiliate',
  [HOME_HEALTH_AGENCY]: 'Home Health Agency',
  [HOSPICE]: 'Hospice',
  [HOSPITAL]: 'Hospital',
  [INPATIENT_REHAB_FACILITY]: 'Inpatient Rehab Facility',
  [LONG_TERM_CARE]: 'Long Term Care',
  [LONG_TERM_ACUTE_CARE_HOSPITAL]: 'Long Term Acute Care Hospital',
  [OUTPATIENT_THERAPY_PROVIDER]: 'Outpatient Therapy Provider',
  [PAYER]: 'Payer',
  [PHYSICIAN_GROUP]: 'Physician Group',
  [PHYSICIAN_TEAM]: 'Physician Team',
  [REHAB_FACILITY]: 'Rehab Facility',
  [SKILLED_NURSING_FACILITY]: 'Skilled Nursing Facility',
};
