import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { FormPage } from '~/components/shared/pageLayout';
import { ACUTE_CLIENT_TYPES } from '~/constants/clientTypes';
import { fetchClients } from '~/ducks/admin/clients';
import { createQuestionTemplate, fetchQuestionTemplate, updateQuestionTemplate } from '~/ducks/questionTemplates';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { useThunk } from '~/lib/hooks';
import { QuestionTemplate } from '~/models';
import { history } from '~/store';

import { TemplateForm } from './TemplateForm';

function TemplateFormContainer({ match, ...props }) {
  const { id: questionTemplateId, type: questionTemplateType } = match.params;

  const { data: questionTemplate, loaded: templateLoaded } = useThunk(fetchQuestionTemplate, [questionTemplateId], {
    condition: !!questionTemplateId,
    params: questionTemplateId,
  });
  const { data: clients } = useThunk(fetchClients, [], { params: { clientType: ACUTE_CLIENT_TYPES.join(',') } });

  const goToQuestionTemplates = ({ type }) => history.push(`/question-templates/${type.value ?? type}`);
  const submitForm = ({ type, ...values }, { setErrors, setSubmitting }) => {
    const template = new QuestionTemplate({ ...values, type: type.value });
    const request = template.id ? props.updateQuestionTemplate : props.createQuestionTemplate;

    request(template.serialize())
      .then(unwrapResult)
      .then(() => goToQuestionTemplates(template))
      .catch((error) => handleSubmitError({ error, setErrors, setSubmitting }));
  };

  const handleSubmitError = ({ error, setErrors, setSubmitting }) => {
    if (Array.isArray(error.response?.data?.message)) {
      handleValidationErrors(error, setErrors);
    } else {
      handleUnknownError(error);
    }

    setSubmitting(false);
  };

  const handleUnknownError = (e) => {
    const message = e.response?.data?.message || 'There was an error saving the template.';

    props.addToast({ text: message });
  };

  const handleValidationErrors = (e, setErrors) => {
    const { message } = e.response.data;

    setErrors({ groupTypes: message.join('\n\n') });
  };

  useEffect(() => {
    if (questionTemplate && !questionTemplate.id) {
      questionTemplate.type = questionTemplateType;
    }
  }, [questionTemplate]);

  const formProps = {
    clients,
    questionTemplate,
    onCancel: goToQuestionTemplates,
    onSubmit: submitForm,
  };

  if (questionTemplateId && !templateLoaded) return <CircleSpinner centered />;

  return (
    <FormPage>
      <TemplateForm {...formProps} />
    </FormPage>
  );
}

TemplateFormContainer.propTypes = {
  addToast: PropTypes.func.isRequired,
  createQuestionTemplate: PropTypes.func.isRequired,
  updateQuestionTemplate: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addToast,
  createQuestionTemplate,
  updateQuestionTemplate,
};

export default connect(null, mapDispatchToProps)(TemplateFormContainer);
