import * as Yup from 'yup';

export const formValidation = Yup.object().shape({
  client: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string()
  }).nullable().test('client', 'Client is required', (obj) => Boolean(obj && obj.id)),
  instructions: Yup.string().nullable(),
  questions: Yup.array()
    .min(1, 'Please enter at least one Question')
    .max(10, 'Maximum of ten Questions per Template')
    .of(
      Yup.object().shape({
        text: Yup.string().required(),
        kind: Yup.string().required(),
        config: Yup.object()
      })
    )
});
