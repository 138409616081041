import { IMPORTED_PATIENT } from '~/constants/importConfigurations';

import ImportConfiguration from '../ImportConfiguration';

const defaults = {
  type: IMPORTED_PATIENT,
};

export default class ImportedPatient extends ImportConfiguration {
  constructor(options = {}) {
    super({ ...defaults, ...options });
    this.type = IMPORTED_PATIENT;
  }
}
