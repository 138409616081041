import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '~/styles/theme/colors';

import { Label } from './typography';

export const ICON_ALIGN = {
  left: 'left',
  right: 'right',
};

function Chip(props) {
  const {
    className,
    label,
    icon,
    iconCursor,
    iconPadding,
    bold,
    fontSize,
    padding,
    onClick,
    backgroundColor,
    textColor,
    textMargin,
    textPadding,
    truncateText,
    lineHeight,
  } = props;

  const iconAlign = Object.values(ICON_ALIGN).includes(props.iconAlign) ? props.iconAlign : ICON_ALIGN.left;

  const chipTextProps = {
    bold,
    color: textColor,
    fontSize,
    icon,
    iconAlign,
    lineHeight,
    textMargin,
    textPadding,
    truncateText,
  };

  const iconContainer = (
    <IconContainer cursor={iconCursor} iconAlign={iconAlign} iconPadding={iconPadding} onClick={onClick}>
      {icon}
    </IconContainer>
  );

  return (
    <ChipContainer className={className} padding={padding} color={backgroundColor}>
      {icon && iconAlign === ICON_ALIGN.left && iconContainer}
      <ChipText {...chipTextProps}>{label}</ChipText>
      {icon && iconAlign === ICON_ALIGN.right && iconContainer}
    </ChipContainer>
  );
}

Chip.propTypes = {
  backgroundColor: PropTypes.string,
  bold: PropTypes.bool,
  className: PropTypes.string,
  fontSize: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  iconAlign: PropTypes.oneOf(Object.values(ICON_ALIGN)),
  iconCursor: PropTypes.string,
  iconPadding: PropTypes.string,
  label: PropTypes.string.isRequired,
  lineHeight: PropTypes.string,
  onClick: PropTypes.func,
  padding: PropTypes.string,
  textColor: PropTypes.string,
  textMargin: PropTypes.string,
  textPadding: PropTypes.string,
  truncateText: PropTypes.bool,
};

Chip.defaultProps = {
  backgroundColor: colors.secondaryOrange,
  bold: false,
  fontSize: '12px',
  iconAlign: ICON_ALIGN.left,
  iconCursor: 'default',
  iconPadding: '4px',
  lineHeight: '16px',
  onClick: () => {},
  padding: '4px 12px',
  textColor: colors.white,
  textMargin: '4px',
  textPadding: '0px',
  truncateText: true,
};

export default Chip;

const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 24px;
  padding: ${({ padding }) => padding};
  align-self: flex-start;
`;

const ChipText = styled(Label)`
  display: block;
  font-size: ${({ fontSize }) => fontSize};

  ${({ truncateText }) =>
    truncateText &&
    `
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `};

  ${({ icon, iconAlign, textMargin }) =>
    icon &&
    `
    margin-${iconAlign}: ${textMargin};
  `};

  ${({ bold }) =>
    bold &&
    `
    font-weight: 700;
  `};

  ${({ iconAlign, textPadding }) =>
    iconAlign === ICON_ALIGN.left &&
    `
    padding-right: ${textPadding};
  `};

  ${({ iconAlign, textPadding }) =>
    iconAlign === ICON_ALIGN.right &&
    `
    padding-left: ${textPadding};
  `};
`;

const IconContainer = styled.div`
  display: flex;
  cursor: ${({ cursor }) => cursor};

  ${({ iconAlign, iconPadding }) =>
    iconAlign === ICON_ALIGN.left &&
    `
    padding-right: ${iconPadding};
  `};

  ${({ iconAlign, iconPadding }) =>
    iconAlign === ICON_ALIGN.right &&
    `
    padding-left: ${iconPadding};
  `};
`;
