import React, { useMemo } from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { FormPage } from '~/components/shared/pageLayout';
import {
  createImportConfiguration,
  fetchImportConfiguration,
  updateImportConfiguration,
} from '~/ducks/admin/importConfigurations';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { useThunk } from '~/lib/hooks';
import { ImportedPatient } from '~/models/importConfigurations';
import { history } from '~/store';

import ImportConfigurationForm from './ImportConfigurationForm';
import { importConfigurationValidation } from './importConfigurationValidation';

function EditImportConfiguration(props) {
  const {
    match: {
      params: { id },
    },
  } = props;

  const { data: importConfig, loaded } = useThunk(fetchImportConfiguration, [id], {
    condition: Boolean(id),
    params: { id, include: 'client' },
  });

  const goToImportConfigs = () => history.push('/clients/import-configurations');

  const handleSubmit = (values, { setSubmitting }) => {
    const config = new ImportedPatient(values).serialize();
    const request = id ? props.updateImportConfiguration : props.createImportConfiguration;

    request(config)
      .then(unwrapResult)
      .then(goToImportConfigs)
      .catch((e) => {
        const errorMessage =
          e?.response?.data?.message ||
          `There was an error ${id ? 'updating' : 'creating'} the import configuration. Please try again.`;

        props.addToast({ text: errorMessage });
      })
      .then(() => props.addToast({ text: `Import Configuration successfully ${id ? 'updated' : 'added'}!` }))
      .finally(() => setSubmitting(false));
  };

  const initialValues = useMemo(() => {
    const config = new ImportedPatient(importConfig).toFormValues();

    return { ...config, rawConfig: config.config };
  }, [importConfig]);

  const formikOptions = useMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit,
      mapPropsToValues: () => initialValues,
      validationSchema: importConfigurationValidation,
    }),
    [importConfig]
  );

  const FormikUserForm = useMemo(() => withFormik(formikOptions)(ImportConfigurationForm), [formikOptions]);

  if (Boolean(id) && !loaded) return <CircleSpinner centered />;

  return (
    <FormPage>
      <FormikUserForm onCancel={goToImportConfigs} />
    </FormPage>
  );
}

EditImportConfiguration.propTypes = {
  addToast: PropTypes.func.isRequired,
  createImportConfiguration: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  updateImportConfiguration: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addToast,
  createImportConfiguration,
  fetchImportConfiguration,
  updateImportConfiguration,
};

export default connect(null, mapDispatchToProps)(EditImportConfiguration);
