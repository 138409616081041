import { createAsyncThunk } from '~/lib';
import adminUsersExportsApi from '~/services/api/adminUsersExportsApi';

const sliceName = 'admin/users/exports';

export const createAdminUserExport = createAsyncThunk(`${sliceName}/create`, async (params: Record<string, any>) => {
  const { data } = await adminUsersExportsApi.create.invoke(params);

  return data;
});
