import { IRequestOptions } from '~/services/api/core/IRequestOptions';

import ApiRequest from './ApiRequest';

const formatArgsForConfigDefault = (data: any) => ({ data });

export default class CreateRequest extends ApiRequest {
  constructor(options: IRequestOptions = {}) {
    const { url, config, formatArgsForConfig = formatArgsForConfigDefault } = options;

    super({ method: 'post', url, config, formatArgsForConfig });
  }
}
