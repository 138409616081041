import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getClientTypeName } from '~/components/clients/helpers';
import { Checkbox, CheckboxMultiSelect, InlineInputGroups, InputGroup, Select } from '~/components/shared/form';
import Search from '~/components/shared/Search';
import { CLIENT_TYPE_SELECT_OPTIONS } from '~/constants/clientTypes';
import {
  ACTIVE,
  ALL_OPTION,
  CLIENT,
  CLIENT_TYPE,
  ENABLED_PROVIDER_TYPE,
  GROUP,
  ROLE,
  SEARCH,
} from '~/constants/filterKeysConstants';
import { PROVIDER } from '~/constants/groupTypes';
import { fetchClients } from '~/ducks/admin/clients';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { fetchRoles } from '~/ducks/admin/roles';
import { getDisplayName, getId, getName } from '~/helpers';
import { useAsyncGroupOptions, useAsyncOptions, useDebounce } from '~/lib/hooks';

function UsersFilterBar({ usersFilters, ...props }) {
  const [search, setSearch] = useState(usersFilters[SEARCH]);
  const debouncedSearch = useDebounce(search);
  const showInactive = !usersFilters[ACTIVE];
  const updateFilter = (key, val) => props.setFilter({ filterType: key, value: val });
  const handleSearchChange = (e) => setSearch(e.currentTarget.value);
  const handleOptionChange = (value, filterType) => {
    if (filterType === ACTIVE) {
      updateFilter(filterType, !value);
    } else {
      updateFilter(filterType, value);
    }
  };
  const handleSearchClear = () => setSearch('');
  const isFiltered = Object.values(usersFilters).some((value) => value.id || value.kind);
  const showClearAll = isFiltered || showInactive;

  useEffect(() => {
    updateFilter(SEARCH, search);
  }, [debouncedSearch]);

  const clientsAsyncOptions = useAsyncOptions(fetchClients, {
    optionsToPrepend: [ALL_OPTION],
  });

  const rolesAsyncOptions = useAsyncOptions(fetchRoles, {
    optionsToPrepend: [ALL_OPTION],
  });

  const providerAsyncOptions = useAsyncOptions(fetchGroupTypes, {
    params: { type: PROVIDER, sortBy: 'displayName' },
  });

  const groupAsyncOptions = useAsyncGroupOptions({
    optionsToPrepend: [ALL_OPTION],
    params: { include: 'groupType' },
    showAddress: false,
  });

  return (
    <FiltersContainer>
      <StyledSearch
        value={search}
        placeholder='Search Users'
        onChange={handleSearchChange}
        onClear={handleSearchClear}
      />

      <InputGroup
        {...clientsAsyncOptions}
        label='Client'
        name={CLIENT}
        value={usersFilters[CLIENT]}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />

      <InputGroup
        component={Select}
        optionsToPrepend={ALL_OPTION}
        label='Client Type'
        name={CLIENT_TYPE}
        onChange={handleOptionChange}
        options={CLIENT_TYPE_SELECT_OPTIONS}
        value={usersFilters[CLIENT_TYPE]}
        getOptionLabel={getClientTypeName}
        getOptionValue={getName}
      />

      <InputGroup
        {...providerAsyncOptions}
        component={CheckboxMultiSelect}
        label='Enabled Care Options'
        name={ENABLED_PROVIDER_TYPE}
        onChange={handleOptionChange}
        value={usersFilters[ENABLED_PROVIDER_TYPE]}
        getOptionLabel={getDisplayName}
        getOptionValue={getId}
      />

      <InputGroup
        {...groupAsyncOptions}
        component={Select}
        label='Group'
        name={GROUP}
        onChange={handleOptionChange}
        value={usersFilters[GROUP]}
      />

      <InputGroup
        {...rolesAsyncOptions}
        label='Role'
        name={ROLE}
        value={usersFilters[ROLE]}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleOptionChange}
        component={Select}
      />

      <CheckboxContainer>
        <Checkbox
          label='Include deactivated users'
          name={ACTIVE}
          value={showInactive}
          size={18}
          labelSize='16px'
          onChange={handleOptionChange}
        />
      </CheckboxContainer>

      {showClearAll && (
        <ClearLinkContainer>
          <ClearLink onClick={props.clearFilters}>Clear Filters</ClearLink>
        </ClearLinkContainer>
      )}
    </FiltersContainer>
  );
}

UsersFilterBar.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  clients: PropTypes.instanceOf(Array),
  roles: PropTypes.instanceOf(Array),
  setFilter: PropTypes.func.isRequired,
  usersFilters: PropTypes.instanceOf(Object),
};

export default UsersFilterBar;

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 230px;
    margin-bottom: 24px;
  }
`;

const StyledSearch = styled(Search)`
  margin-right: 24px;
`;

const CheckboxContainer = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ClearLinkContainer = styled.div`
  height: 40px;
  display: flex;
  flex: 0;
  align-items: center;
`;

const ClearLink = styled.div`
  cursor: pointer;
  width: 76px;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
