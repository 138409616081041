import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import styled from 'styled-components';

function CheckboxMultiSelectPlaceholder({ children, ...props }) {
  const selectedValues = props.getValue();
  const valueText = useMemo(() => {
    if (selectedValues.length) {
      return `${selectedValues.length} selected`;
    }

    return children;
  }, [children, selectedValues]);

  return (
    <StyledPlaceholder { ...props }>
      { valueText }
    </StyledPlaceholder>
  );
}

CheckboxMultiSelectPlaceholder.propTypes = {
  getValue: PropTypes.func.isRequired
};

export default CheckboxMultiSelectPlaceholder;

const StyledPlaceholder = styled(components.Placeholder)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 85%;
`;
