import React from 'react';
import PropTypes from 'prop-types';

const CircleCancelIcon = () => {
  return (
    <svg width='16px' height='16px' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g
        id='circle-cancel-icon'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <g
          id='ImportHistory'
          transform='translate(-1017.000000, -463.000000)'
          stroke='#D10D44'
          strokeWidth='1.86666667'>
          <g id='ban' transform='translate(1018.000000, 464.000000)'>
            <circle id='Oval' cx='7' cy='7' r='7'></circle>
            <line x1='2.05053333' y1='11.9494667' x2='11.9494667' y2='2.05053333' id='Path'></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

CircleCancelIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default CircleCancelIcon;
