import { IRequestOptions } from '~/services/api/core/IRequestOptions';

import ApiRequest from './ApiRequest';

const formatArgsForConfigDefault = (url: string | undefined) => (id: any, data: any) => ({ data, url: `${url}/${id}` });

export default class UpdateRequest extends ApiRequest {
  constructor(options: IRequestOptions = {}) {
    const { url, config, formatArgsForConfig = formatArgsForConfigDefault(url) } = options;

    super({ method: 'put', url, config, formatArgsForConfig });
  }
}
