import React, { useState } from 'react';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import remove from '~/assets/images/Delete.svg';
import { Input, InputGroup } from '~/components/shared/form';

import ChartFields from './ChartFields';
import { InlineContainer } from './InlineContainer';
import RadioButtonGroup from './RadioButtonGroup';

const RangeOrNumericQuestion = ({ field: { name, value }, form, inputLabel, removeItem }) => {
  const [chart, setChart] = useState(_.isEmpty(value.config.chart) ? 'no' : 'yes');

  const handleRadioChange = (inputVal) => {
    setChart(inputVal);
    const chartObj = inputVal === 'yes' ? { chartName: '', yAxis: '' } : {};

    form.setFieldValue(name, { ...value, config: { chart: chartObj } });
  };

  return (
    <React.Fragment>
      <InlineContainer>
        <InputGroup label={inputLabel} name={`${name}.text`} placeholder='Type Question here...' component={Input} />
        <img src={remove} onClick={removeItem} />
        <RadioButtonGroup label='Chart' form={form} name={`${name}.radio`} value={chart} onChange={handleRadioChange} />
      </InlineContainer>
      <ChartSection>{chart === 'yes' && <ChartFields name={name} value={value.config.chart} />}</ChartSection>
    </React.Fragment>
  );
};

RangeOrNumericQuestion.defaultProps = {
  value: {
    text: '',
    config: {
      chart: {},
    },
  },
};

RangeOrNumericQuestion.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.instanceOf(Object),
  }),
  form: PropTypes.instanceOf(Object),
  inputLabel: PropTypes.string,
  removeItem: PropTypes.func,
};

export default RangeOrNumericQuestion;

export const ChartSection = styled.div`
  margin-left: 40px;
`;
