import admin from './admin';
import api from './api';
import classificationFilters from './classificationFilters';
import clientsFilters from './clientsFilters';
import dischargeTemplatesFilters from './dischargeTemplatesFilters';
import navigation from './navigation';
import profile from './profile';
import progressTemplatesFilters from './progressTemplatesFilters';
import questionTemplates from './questionTemplates';
import toasts from './toasts';

export default {
  ...admin,
  api,
  [classificationFilters.name]: classificationFilters.reducer,
  [clientsFilters.name]: clientsFilters.reducer,
  [dischargeTemplatesFilters.name]: dischargeTemplatesFilters.reducer,
  [navigation.name]: navigation.reducer,
  profile,
  questionTemplates,
  [progressTemplatesFilters.name]: progressTemplatesFilters.reducer,
  toasts,
};
