/* eslint-disable no-warning-comments */
import React, { useEffect } from 'react';

import { SortingState, TableOptions } from '@tanstack/react-table';

import { useDeepEffect } from '~/lib/hooks';
import { usePagination } from '~/lib/hooks';

import { Table } from '.';

type ControlledTableProps<T> = Partial<TableOptions<T>> &
  Required<Pick<TableOptions<T>, 'columns' | 'data'>> & {
    loading: boolean;
    defaultSortBy: string;
    filters: unknown;
    pageCount: number;
    pageSize?: number;
    onInitialize?: ({ setSorting }: { setSorting: React.Dispatch<React.SetStateAction<SortingState>> }) => void;
    onPagingFiltersChange(filters: unknown): void;
  };

function ControlledTable<T>(props: ControlledTableProps<T>) {
  const { defaultSortBy, filters, pageCount, pageSize = 10, onPagingFiltersChange, ...tableProps } = props;

  const pagination = usePagination({
    sortBy: defaultSortBy,
    pageSize: pageSize,
  });

  // Needs deep effect because pagination.filters is an object and want to compare values, not references
  useDeepEffect(() => {
    onPagingFiltersChange?.(pagination.filters);
  }, [pagination.filters]);

  useEffect(() => {
    pagination.setPageIndex(0);
  }, [filters]);

  return (
    <Table<T>
      initialState={{ pagination: { pageIndex: pagination.pageIndex }, sorting: pagination.defaultSortBy }}
      state={{ pagination: { pageIndex: pagination.pageIndex, pageSize: pagination.pageSize } }}
      onPageChange={pagination.setPageIndex}
      onSortChange={pagination.setSortBy}
      manualPagination
      manualSorting
      pageCount={pageCount}
      {...tableProps}
    />
  );
}

export default ControlledTable;
