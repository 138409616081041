import { DefaultTheme } from 'styled-components';

const dimensions: DefaultTheme['dimensions'] = {
  marginBigger: '48px',
  marginBig: '24px',
  marginMedium: '20px',
  marginSmallMedium: '16px',
  marginSmall: '12px',
  marginTiny: '5px',
  mainHeaderHeight: '48px',
  sideNavWidth: '200px'
};

export default dimensions;
