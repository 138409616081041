import React, { Fragment } from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import EditFlyoutMenu from '~/components/shared/FlyoutMenu/EditFlyoutMenu';
import { BodySmallBold } from '~/components/shared/typography';
import { simpleDateAtTime } from '~/lib/formatDate';
import { ImportConfiguration } from '~/models';
import { PERMISSIONS } from '~/services/profile';

const columnHelper = createColumnHelper<ImportConfiguration>();

type ColumnFnProps = {
  profileSvc: any;
} & React.ComponentProps<typeof EditFlyoutMenu>;

export default function importConfigurationsTableColumns({ profileSvc, onEdit }: ColumnFnProps) {
  const columns = [
    columnHelper.accessor((row) => row.clientName, {
      id: 'clientName',
      header: 'Client',
      cell: (info) => <BodySmallBold>{info.getValue()}</BodySmallBold>,
    }),
    columnHelper.accessor((row) => row.clientType, {
      id: 'clientType',
      header: 'Client Type',
    }),
    columnHelper.accessor((row) => row.updatedAt, {
      id: 'lastModified',
      header: 'Last Modified',
      cell: (info) => {
        const lastModified = info.getValue();

        return <Fragment>{lastModified ? simpleDateAtTime(lastModified) : ''}</Fragment>;
      },
    }),
  ];

  if (profileSvc.has(PERMISSIONS.adminImportConfigurationEdit)) {
    columns.push(
      columnHelper.display({
        id: 'menu',
        cell: (info) => (
          <EditFlyoutMenu
            row={info.row}
            onEdit={onEdit}
            width={'max-content'}
            menuText={'Edit Import Configuration'}
            permission={PERMISSIONS.adminImportConfigurationEdit}
          />
        ),
        meta: { style: { justifyContent: 'flex-end' } },
        size: 75,
      })
    );
  }

  return columns;
}
