import { createAsyncThunk } from '~/lib';
import { User } from '~/models';
import { usersApi } from '~/services/api';

const SLICE_NAME = 'users';

const cancellableFetchUsers = usersApi.fetch.cancellable();

export const fetchUsers = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (params: any) => {
    const res = await cancellableFetchUsers(params).then((arg) => arg as any);

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: User,
  } as any
);
