import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Permission, User } from '~/models';
import { useProfileContext } from '~/services/profile';

import { AdminSection, NonAdminSection } from './sections';

function PermissionsForm(props) {
  const {
    bindResetForm,
    bindSetSubmitting,
    bindSubmitForm,
    dirty,
    isSubmitting,
    isValid,
    onDirtyChanged,
    onIsSubmittingChanged,
    onIsValidChanged,
    permissions,
    resetForm,
    setStatus,
    setSubmitting,
    submitForm,
    values,
  } = props;

  const profileSvc = useProfileContext();
  const isDisabled = profileSvc.profile.id === values.user.id;

  useEffect(() => setStatus({ disabled: isDisabled }), [isDisabled]);
  useEffect(() => bindResetForm(resetForm), []);
  useEffect(() => bindSetSubmitting(setSubmitting), []);
  useEffect(() => bindSubmitForm(submitForm), []);
  useEffect(() => onDirtyChanged(dirty), [dirty]);
  useEffect(() => onIsSubmittingChanged(isSubmitting), [isSubmitting]);
  useEffect(() => onIsValidChanged(isValid), [isValid]);

  const nonAdminPermissions = useMemo(
    () => permissions.filter((permission) => !permission.name.startsWith('admin')),
    [permissions]
  );
  const adminPermissions = useMemo(
    () => permissions.filter((permission) => permission.name.startsWith('admin')),
    [permissions]
  );

  return (
    <StyledForm>
      <NonAdminSection permissions={nonAdminPermissions} />
      {values.user.client?.isAdmin && (
        <SectionContainer>
          <AdminSection permissions={adminPermissions} />
        </SectionContainer>
      )}
    </StyledForm>
  );
}

PermissionsForm.propTypes = {
  bindResetForm: PropTypes.func,
  bindSetSubmitting: PropTypes.func,
  bindSubmitForm: PropTypes.func,
  dirty: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  onDirtyChanged: PropTypes.func,
  onIsSubmittingChanged: PropTypes.func,
  onIsValidChanged: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.instanceOf(Permission)).isRequired,
  resetForm: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  values: PropTypes.shape({
    user: PropTypes.instanceOf(User).isRequired,
  }),
};

PermissionsForm.defaultProps = {
  bindResetForm: () => {},
  bindSetSubmitting: () => {},
  bindSubmitForm: () => {},
  onDirtyChanged: () => {},
  onIsSubmittingChanged: () => {},
  onIsValidChanged: () => {},
};

export default PermissionsForm;

const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
