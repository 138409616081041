import * as Yup from 'yup';

export const importConfigurationValidation = Yup.object().shape({
  client: Yup.object().shape({
    id: Yup.string(),
    name: Yup.string()
  }).nullable().test('client', 'Client is required', (obj) => Boolean(obj && obj.id)),
  config: Yup.object().shape({
    dateFormat: Yup.string().required('Date Format is required'),
    delimiter: Yup.object().shape({
      label: Yup.string(),
      value: Yup.string()
    }).test('delimiter', 'Delimiter is required', ({ value }) => !!value),
    properties: Yup.object().shape({
      dateOfBirth: Yup.string().required('Date of Birth is required'),
      externalId: Yup.string().required('Patient ID is required'),
      name: Yup.string().required('Name is required'),
      sex: Yup.string().required('Gender is required')
    })
  }).nullable()
});
