import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';

export function Toast({ id, onClick, text, timeout = 7000 }: ToastProps) {
  useEffect(() => {
    const clearTimer = setInterval((e: React.MouseEvent<HTMLElement>) => {
      handleClick(e);
    }, timeout);

    return () => clearInterval(clearTimer);
  }, []);

  const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => onClick(id, e), []);

  setTimeout(handleClick, timeout);

  return (
    <ListItem className='toast' onClick={ handleClick }>
      <Content className='toast__content'>
        {text.split('\n').map((fragment, i) => <Fragment key={ i }>{ fragment }</Fragment>) }
      </Content>
    </ListItem>
  );
}
export interface ToastProps {
  id: number;
  onClick: (id: number, event?: React.MouseEvent<HTMLElement>) => void;
  text: string;
  timeout?: number;
}

export default Toast;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding: 8px;
  border-radius: 4px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
`;

const Content = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
`;

const Fragment = styled.span`
  display: block;
`;
