import React, { useContext } from 'react';
import * as _ from 'lodash-es';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { ProfileContext } from '~/services/profile';

const ProtectedRoute = ({ component: Component, flag, permissions, shouldDeny, ...rest }) => {
  const permissionsService = useContext(ProfileContext);
  const permissionSet = _.castArray(permissions);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!permissionsService.hasAll(permissionSet)) {
          return null;
        }

        if (flag && !permissionsService.hasFlag(flag)) {
          return null;
        }

        if (shouldDeny) {
          return null;
        }

        return <Component {...props} />;
      }}
    />
  );
};

ProtectedRoute.defaultProps = {
  permissions: [],
};

ProtectedRoute.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
  flag: PropTypes.string,
  path: PropTypes.string,
  permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  shouldDeny: PropTypes.bool,
};

export default ProtectedRoute;
