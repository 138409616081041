import React, { useMemo } from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { unwrapResult } from '@reduxjs/toolkit';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { createUser, fetchUser, updateUser } from '~/ducks/admin/users';
import { addToast } from '~/ducks/toasts';
import { useThunk } from '~/lib/hooks';
import { User } from '~/models';

import { FormPage } from '../shared/pageLayout';

import UserForm from './UserForm';
import { userFormValidation } from './userFormValidation';

function EditUser(props) {
  const userId = props.match.params.id;
  const { data: user, loaded: userLoaded } = useThunk(fetchUser, [userId], {
    condition: Boolean(userId),
    params: {
      id: userId,
      include: 'acting_client,client,credential,enabled_provider_types,role,selected_groups,selected_provider_types',
    },
  });

  const goToUsers = () => props.history.push('/users');
  const handleSubmit = ({ shouldNavigate = true, ...userValues }, { setSubmitting }) => {
    const { name } = userValues;

    const updatedUser = new User(userValues);
    const userRequest = updatedUser.id ? props.updateUser : props.createUser;
    const tryNotifySuccess = () => !shouldNavigate && props.addToast({ text: `${name} successfully added!` });

    return userRequest(updatedUser.serialize())
      .then(unwrapResult)
      .then(() => shouldNavigate && goToUsers())
      .then(tryNotifySuccess)
      .finally(() => setSubmitting(false));
  };

  const formikOptions = useMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit,
      mapPropsToStatus: () => ({ isEdit: Boolean(userId) }),
      mapPropsToValues: () => user,
      validationSchema: userFormValidation,
    }),
    [user]
  );

  const FormikUserForm = useMemo(() => withFormik(formikOptions)(UserForm), [formikOptions]);

  if (Boolean(userId) && !userLoaded) {
    return <CircleSpinner centered />;
  }

  return (
    <FormPage>
      <FormikUserForm onCancel={goToUsers} />
    </FormPage>
  );
}

EditUser.propTypes = {
  addToast: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addToast,
  createUser,
  updateUser,
};

export default connect(null, mapDispatchToProps)(EditUser);
