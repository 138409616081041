import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { MainPage } from '~/components/shared/pageLayout';
import TabFilters from '~/components/shared/TabFilters';

import CollaborationsFilterBar from './CollaborationsFilterBar';
import CollaborationsTable from './CollaborationsTable';

function CollaborationsList({ onTabSelect, tabs, ...props }) {
  const activeTab = tabs.find((tab) => tab.label === 'Collaborations');

  return (
    <MainPage
      title='Clients'
      headerWhite
      headerContent={
        <Fragment>
          <CollaborationsFilterBar />
          <TabFilters tabs={tabs} onTabClick={onTabSelect} selectedTab={activeTab} />
        </Fragment>
      }>
      <CollaborationsTable {...props} />
    </MainPage>
  );
}

CollaborationsList.propTypes = {
  onTabSelect: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

export default CollaborationsList;
