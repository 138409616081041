import { useContext } from 'react';
import { FormikContext, useField as formikUseField } from 'formik';

const useField = (props) => {
  // This gives us the flexibility to use our form
  // components outside of the Formik form component
  const context = useContext(FormikContext);
  const isFormikField = context && !props.overrideFormik;

  const noop = () => {};

  return isFormikField ? formikUseField(props) :
    [props, {}, { setValue: noop, setTouched: noop }];
};

export default useField;
