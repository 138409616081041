import React from 'react';
import PropTypes from 'prop-types';

import { InlineInputGroups, Input, InputGroup } from '~/components/shared/form';

const ChartFields = ({ name }) => {
  return (
    <InlineInputGroups>
      <InputGroup
        label='Chart Name'
        name={`${name}.config.chart.chartName`}
        placeholder='Type here..'
        component={Input}
      />
      <InputGroup
        label='Measurement Value (y-axis label)'
        name={`${name}.config.chart.yAxis`}
        placeholder='Type here...'
        component={Input}
      />
    </InlineInputGroups>
  );
};

ChartFields.defaultProps = {
  value: {
    chartName: '',
    yAxis: '',
  },
};

ChartFields.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ChartFields;
