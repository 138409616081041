import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import * as _ from 'lodash-es';
import styled from 'styled-components';

import { NotificationPreferencesFormValues } from '~/components/preferences/notifications/NotificationPreferencesFormWrapper';
import { Checkbox } from '~/components/shared/form';
import { BodySmallBold, Label } from '~/components/shared/typography';
import { capitalize, humanize } from '~/helpers';
import { useDeepMemo } from '~/lib/hooks';
import { GroupType } from '~/models';
import { NotificationChannel } from '~/models/userPreferences/Notification';
import colors from '~/styles/theme/colors';

type Props = {
  category: string;
  providerType: GroupType;
  disabled: boolean;
};

export default function NotificationCategoryPreferences({ category, providerType, disabled = false }: Props) {
  const { setFieldValue, values } = useFormikContext<NotificationPreferencesFormValues>();

  const notificationSelections = values[providerType.apiName]?.notificationSelections ?? {};

  const categoryPreferences = useDeepMemo(() => {
    return notificationSelections?.[category] ?? {};
  }, [values, category]);

  const [allEmailChecked, setAllEmailChecked] = useState(false);
  const [allPushChecked, setAllPushChecked] = useState(false);

  const handleSelectAll = (val: boolean, channel: NotificationChannel) => {
    if (channel === NotificationChannel.EMAIL) setAllEmailChecked(val);
    if (channel === NotificationChannel.PUSH) setAllPushChecked(val);

    const notificationTypes = Object.keys(categoryPreferences);

    notificationTypes.forEach((type) => {
      const channelValue = _.get(notificationSelections, `${category}.${type}.${channel}`);

      if (channelValue !== undefined) {
        setFieldValue(`${providerType.apiName}.notificationSelections.${category}.${type}.${channel}`, val);
      }
    });
  };

  const areAllValuesTrue = (obj: typeof categoryPreferences, key: NotificationChannel) => {
    return Object.values(obj)
      .filter((v) => typeof v[key] !== 'undefined')
      .every((v) => v[key]);
  };

  useEffect(() => {
    setAllEmailChecked(areAllValuesTrue(categoryPreferences, NotificationChannel.EMAIL));
    setAllPushChecked(areAllValuesTrue(categoryPreferences, NotificationChannel.PUSH));
  }, [categoryPreferences]);

  return (
    <List>
      <Header>{capitalize(humanize(category))}</Header>
      <ListItem>
        <Cell></Cell>
        <CheckboxContainer>
          <HeaderLabel>Email</HeaderLabel>
          <Checkbox
            id={`${providerType.apiName}.notificationSelections.${category}.selectAll.${NotificationChannel.EMAIL}`}
            disabled={disabled}
            size={14}
            color={colors.black}
            checkedColor={colors.primaryBlue}
            labelMargin='0px'
            checked={allEmailChecked}
            overrideFormik
            onChange={(val) => handleSelectAll(val, NotificationChannel.EMAIL)}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <HeaderLabel>Mobile</HeaderLabel>
          <Checkbox
            id={`${providerType.apiName}.notificationSelections.${category}.selectAll.${NotificationChannel.PUSH}`}
            disabled={disabled}
            size={14}
            color={colors.black}
            checkedColor={colors.primaryBlue}
            labelMargin='0px'
            checked={allPushChecked}
            overrideFormik
            onChange={(val) => handleSelectAll(val, NotificationChannel.PUSH)}
          />
        </CheckboxContainer>
      </ListItem>

      {Object.entries(categoryPreferences)
        .sort()
        .map(([key, val]) => {
          return (
            <ListItem key={key}>
              <Cell>
                <NotificationLabel>{capitalize(humanize(key))}</NotificationLabel>
              </Cell>
              <CheckboxContainer>
                <Checkbox
                  name={`${providerType.apiName}.notificationSelections.${category}.${key}.${NotificationChannel.EMAIL}`}
                  disabled={disabled}
                  size={14}
                  color={colors.black}
                  checkedColor={colors.primaryBlue}
                  labelMargin='0px'
                />
              </CheckboxContainer>
              <CheckboxContainer>
                {typeof val.push === 'undefined' ? (
                  <>&mdash;</>
                ) : (
                  <Checkbox
                    name={`${providerType.apiName}.notificationSelections.${category}.${key}.${NotificationChannel.PUSH}`}
                    disabled={disabled}
                    size={14}
                    color={colors.black}
                    checkedColor={colors.primaryBlue}
                    labelMargin='0px'
                  />
                )}
              </CheckboxContainer>
            </ListItem>
          );
        })}
    </List>
  );
}

const Header = styled(BodySmallBold)``;

const HeaderLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.black75};
  margin-bottom: 10px;
`;

const NotificationLabel = styled(Label)`
  color: ${({ theme }) => theme.colors.black75};
`;

const List = styled.div`
  margin-top: 40px;
`;

const ListItem = styled.div`
  display: flex;
  padding: 16px 0px;
  border-bottom: 1px solid ${(props) => props.theme.colors.black25};
`;

const Cell = styled.div`
  min-width: 150px;
  flex: 1;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  align-items: center;
  text-align: center;
`;
