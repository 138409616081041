import React from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { InputGroup, Select } from '~/components/shared/form';
import { fetchClients } from '~/ducks/clients';
import { updateProfile } from '~/ducks/profile';
import { addToast } from '~/ducks/toasts';
import { getId, getName } from '~/helpers';
import { unwrapResult } from '~/lib';
import { useAsyncOptions } from '~/lib/hooks';
import { useProfileContext } from '~/services/profile';

function ClientSelectForm({ onCancel, onComplete, ...props }) {
  const { profile } = useProfileContext();
  const clientsAsyncOptions = useAsyncOptions(fetchClients, {
    params: { leaf: true, sortBy: 'name' },
  });

  const handleSubmit = ({ actingClient }, { setSubmitting }) => {
    if (profile.actingClientId !== actingClient.id) {
      props
        .updateProfile({
          actingClientId: actingClient?.id,
          include: 'acting_client.group_types,enabled_provider_types,preferences,selected_group_ids,allowed_group_ids',
        })
        .then(unwrapResult)
        .then(onComplete)
        .catch(() => setSubmitting(false));
    } else {
      onComplete();
    }
  };

  return (
    <FormContainer>
      <Formik initialValues={{ actingClient: profile.actingClient }} onSubmit={handleSubmit} enableReinitialize>
        {({ isSubmitting, isValid }) => (
          <Form>
            <InputGroup
              {...clientsAsyncOptions}
              name='actingClient'
              label='Select a view:'
              placeholder='Client'
              getOptionLabel={getName}
              getOptionValue={getId}
              component={Select}
              /* Set the portal so the Select menu can render outside the modal */
              menuPortalTarget={document.body}
              styles={{ menuPortal: { zIndex: 9999 } }}
            />
            <Actions>
              <Button color='transparent' onClick={onCancel} text='Cancel' />
              <Button type='submit' disabled={!isValid || isSubmitting} loading={isSubmitting} text='Submit' />
            </Actions>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
}

ClientSelectForm.propTypes = {
  addToast: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addToast,
  updateProfile,
};

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;

export default connect(null, mapDispatchToProps)(ClientSelectForm);
