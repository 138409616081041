/* eslint-disable @typescript-eslint/no-explicit-any */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '~/lib';
import { AttrValue } from '~/models';
import { adminAttrValuesApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from '../api';
import PaginationState from '../PaginationState';

const SLICE_NAME = 'admin/attrValues';

const cancellableFetchAttrs = adminAttrValuesApi.fetch.cancellable();

export const fetchAttrValues = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (params: any) => {
    const res = cancellableFetchAttrs(params).then((arg) => arg as any);

    return (await res).data;
  },
  {
    defaultValue: [],
    modelClass: AttrValue,
  } as any
);

export type FetchAttrValueAPIParams = {
  id: string;
  include: string;
};

export const fetchAttrValue = createAsyncThunk(
  `${SLICE_NAME}/fetchById`,
  async ({ id, ...params }: FetchAttrValueAPIParams) => {
    const res = await adminAttrValuesApi.fetchById.invoke(id, params);

    return res.data;
  },
  {
    modelClass: AttrValue,
  } as any
);

export type CreateAttrValueAPIParams = {
  clientId: string;
  attrId: string;
  name: string;
  displayName: string | null;
  visible: boolean;
  groupIds?: string[];
  associatedGroupIds?: string[];
  active: boolean;
};

export const createAttrValue = createAsyncThunk(`${SLICE_NAME}/create`, async (params: CreateAttrValueAPIParams) => {
  const res = await adminAttrValuesApi.create.invoke(params);

  return res.data;
});

export type UpdateAttrValueAPIParams = { id: string } & Omit<CreateAttrValueAPIParams, 'clientId' | 'name'>;

export const updateAttrValue = createAsyncThunk(`${SLICE_NAME}/create`, async (params: UpdateAttrValueAPIParams) => {
  const res = await adminAttrValuesApi.update.invoke(params.id, params);

  return res.data;
});
export const attrValuesAdapter = createEntityAdapter();

export const initialState = attrValuesAdapter.getInitialState({ pagination: new PaginationState() });

const attrValuesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearAttrValues: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAttrValues.fulfilled, (state: any, { payload: { links, meta, data } }) => {
      state.pagination = { links, meta };
      attrValuesAdapter.setAll(state, data);
    });
  },
});

export const { clearAttrValues } = attrValuesSlice.actions;

const getAttrValuesState = (state: { [x: string]: any }) => state[SLICE_NAME];

export const { selectAll: getAttrValues } = attrValuesAdapter.getSelectors(getAttrValuesState);

export const getAttrValuesLoaded = createApiHasStatusSelector(fetchAttrValues, [
  API_STATES.complete,
  API_STATES.failed,
]);

export const getAttrValuesPageCount = (state: { [x: string]: any }) =>
  getAttrValuesState(state).pagination.meta.totalPages;
export const getAttrValuesTotalRecords = (state: { [x: string]: any }) =>
  getAttrValuesState(state).pagination.meta.totalRecords;

export default attrValuesSlice;
