export enum ImportStepStatus {
  COMPLETED = 'completed',
  ERROR = 'error',
  PENDING = 'pending',
  RUNNING = 'running'
}

export enum ErrorType {
  ERROR = 'error',
  WARNING = 'warning'
}

export const IMPORT_ROWS = 'create_import_rows';
export const ATTRS_AND_VALUE = 'create_attrs_and_values';
export const IMPORTED_PATIENTS = 'create_imported_patients';
export const CLEANUP = 'cleanup';
export const VALIDATE = 'validate_file';

export const IMPORT_PROCESS_STEPS: { [key: string]: string } = {
  [IMPORT_ROWS]: 'Create Import Rows',
  [ATTRS_AND_VALUE]: 'Create Attributes and Values',
  [IMPORTED_PATIENTS]: 'Create Imported Patients',
  [CLEANUP]: 'Cleanup',
  [VALIDATE]: 'Validate File'
};
