import User from '~/models/User';

import { IAttachment } from './interfaces/IAttachment';
import AttachmentBlob from './AttachmentBlob';

export const ALLOWED_IMAGE_TYPES: string[] = ['.jpeg', '.jpg', '.png', '.gif', '.tiff'];
export const ALLOWED_DOCUMENT_TYPES: string[] = [
  '.doc',
  '.docx',
  '.odt',
  '.pdf',
  '.xls',
  '.xlsx',
  '.ods',
  '.ppt',
  '.pptx',
  '.txt',
  '.rtf',
  '.pages',
  '.csv',
  '.key',
  '.numbers',
  '.odp',
  '.gdoc',
  '.gslides',
  '.gsheet',
];

export const DOCUMENT_TYPES = [
  'Authorization Letter',
  'Care Plan',
  'CCD',
  'Denial Letter',
  'Discharge Summary (Acute)',
  'Discharge Summary (Post-Acute)',
  'ED Summary',
  'Medication List',
  'NOMNC',
  'Other',
  'Referral Resource',
  'Therapy Notes',
];

type AttachmentFile = {
  name: string;
  document: File | null;
};

const fileDefaults: AttachmentFile = { name: '', document: null };
const blobDefaults: AttachmentBlob = { id: '', signedId: '', filename: '', contentType: '', metadata: {} };

export default class Attachment implements IAttachment {
  id: string;
  file: AttachmentFile;
  key: string | number;
  thumbnailUrl: string;
  createdAt: string | null;
  createdByUser: User | null;
  docType: string | null;
  blob: AttachmentBlob;

  constructor(options: Partial<Attachment> = {}) {
    const opts = { ...options };

    this.id = opts.id || '';
    this.file = opts.file || fileDefaults;
    this.key = opts.id || opts.key || Date.now();
    this.thumbnailUrl = opts.thumbnailUrl || '';
    this.createdAt = opts.createdAt || null;
    this.createdByUser = opts.createdByUser ? new User(opts.createdByUser) : null;
    this.docType = opts.docType || null;

    const blobOpts = opts.blob || blobDefaults;

    this.blob = new AttachmentBlob(blobOpts);
  }

  get isImage() {
    return /image/.test(this.blob.contentType);
  }

  get signedId() {
    return this.blob.signedId;
  }

  get filename() {
    return this.blob.filename;
  }

  get metadata() {
    return this.blob.metadata;
  }

  get contentType() {
    return this.blob.contentType;
  }

  serialize() {
    return {
      id: this.id,
      docType: this.docType,
      filename: this.filename,
      signedId: this.signedId,
    };
  }
}
