import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

import useField from '~/lib/hooks/useField';

import InfiniteScrollWrapper from '../InfiniteScrollWrapper';
import { DropdownIndicator } from '../Select';
import { customTheme } from '../Select';

import { customStyles } from './customStyles';
import ValueContainer from './ValueContainer';

function MultiSelect(props) {
  const { components, disabled, hasError, menuPlacement, onBlur, onChange, styles } = props;

  const [field, meta, helpers] = useField(props);
  const { name } = field;

  const handleChange = (val) => {
    helpers.setValue(val);
    onChange(val, name, helpers.setValue);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
    onBlur(name);
  };

  const showError = hasError || Boolean(meta.error && typeof meta.error === 'string' && meta.touched);

  return (
    <InfiniteScrollWrapper
      {...field}
      {...props}
      component={ReactSelect}
      isMulti
      isClearable
      isDisabled={disabled}
      hasError={showError}
      onChange={handleChange}
      onBlur={handleBlur}
      theme={customTheme}
      styles={customStyles(styles)}
      classNamePrefix={'react-select'}
      components={{ DropdownIndicator, ValueContainer, ...components }}
      menuPlacement={menuPlacement}
    />
  );
}

MultiSelect.propTypes = {
  components: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  hasError: PropTypes.bool,
  menuPlacement: PropTypes.string,
  meta: PropTypes.instanceOf(Object),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string])).isRequired,
  styles: PropTypes.instanceOf(Object),
};

const noop = () => {};

MultiSelect.defaultProps = {
  components: {},
  disabled: false,
  getOptionLabel: ({ label }) => label,
  getOptionValue: ({ value }) => value,
  hasError: false,
  menuPlacement: 'auto',
  onBlur: noop,
  onChange: noop,
};

export default MultiSelect;
