import React, { useMemo, useState } from 'react';
import { withFormik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import { withSaveChangesModal } from '~/components/shared/withSaveChangesModal';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { useDeepMemo } from '~/lib/hooks';
import { Permission, User } from '~/models';

import { PermissionsForm } from './PermissionsForm';

function Permissions(props) {
  const { children, onFormSubmit, onFormSubmitSuccess, pageSubtitle, pageTitle, permissions, user } = props;

  const [resetFormFunc, setResetFormFunc] = useState(() => () => {});
  const [submitFormFunc, setSubmitFormFunc] = useState(() => () => {});
  const [showModalFunc, setShowModalFunc] = useState(() => () => {});
  const [setSubmitting, setSetSubmitting] = useState(() => () => {});
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const discardDisabled = useMemo(() => !isDirty || isSubmitting, [isDirty, isSubmitting]);
  const saveDisabled = useMemo(() => !isDirty || isSubmitting || !isValid, [isDirty, isSubmitting, isValid]);

  const handleDiscardClick = () => showModalFunc();
  const handleSaveClick = () => submitFormFunc();
  const handleFormSubmit = (formValues) => {
    onFormSubmit(formValues.user.permissions)
      .then(unwrapResult)
      .then(() => props.addToast({ text: 'Your changes have been saved!' }))
      .finally(() => {
        setSubmitting(false);
        onFormSubmitSuccess(formValues.user);
      });
  };
  const handleBindResetForm = (func) => {
    setResetFormFunc(() => func);
  };

  const handleBindShowModal = (func) => {
    setShowModalFunc(() => func);
  };

  const handleBindSubmitForm = (func) => {
    setSubmitFormFunc(() => func);
  };

  const handleBindSetSubmitting = (func) => {
    setSetSubmitting(() => func);
  };

  const buttonGroup = (
    <ButtonGroup>
      <Button color='transparent' disabled={discardDisabled} onClick={handleDiscardClick} text='Discard Changes' />
      <Button data-cy='savePreferencesButton' disabled={saveDisabled} onClick={handleSaveClick} text='Save Changes' />
    </ButtonGroup>
  );

  const formikOptions = useDeepMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit: handleFormSubmit,
      mapPropsToStatus: () => ({ disabled: false }),
      mapPropsToValues: () => ({ user }),
    }),
    [permissions, user]
  );

  const FormikPermissionsForm = useMemo(() => withFormik(formikOptions)(PermissionsForm), [formikOptions]);
  const MainPageWithSaveChangesModal = useMemo(() => withSaveChangesModal(MainPage), []);

  return (
    <MainPageWithSaveChangesModal
      bindShowModal={handleBindShowModal}
      headerContent={<Spacer />}
      headerSticky
      headerWhite
      historyBlockCondition={isDirty || isSubmitting}
      onSaveChangesConfirm={resetFormFunc}
      rightContent={buttonGroup}
      title={pageTitle}
      subtitle={pageSubtitle}>
      {children}
      <FormikPermissionsForm
        bindResetForm={handleBindResetForm}
        bindSetSubmitting={handleBindSetSubmitting}
        bindSubmitForm={handleBindSubmitForm}
        onDirtyChanged={setIsDirty}
        onIsSubmittingChanged={setIsSubmitting}
        onIsValidChanged={setIsValid}
        permissions={permissions}
      />
    </MainPageWithSaveChangesModal>
  );
}

Permissions.propTypes = {
  addToast: PropTypes.func.isRequired,
  children: PropTypes.element,
  onFormSubmit: PropTypes.func,
  onFormSubmitSuccess: PropTypes.func,
  pageSubtitle: PropTypes.string,
  pageTitle: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.instanceOf(Permission)).isRequired,
  user: PropTypes.instanceOf(User).isRequired,
};

Permissions.defaultProps = {
  children: null,
  pageSubtitle: '',
  pageTitle: 'Settings',
  permissions: {},
  user: new User(),
};

const mapDispatchToProps = {
  addToast,
};

export default connect(null, mapDispatchToProps)(Permissions);

const Spacer = styled.div`
  height: 8px;
`;
