import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ALL, INPATIENT, SEARCH, TYPE } from '~/constants/filterKeysConstants';

import { rootPathnameChanged } from '../navigation';

export type GroupTypeFiltersState = {
  [SEARCH]: string;
  [INPATIENT]: boolean;
  [TYPE]: { label: string; value: string };
};

export const initialState: GroupTypeFiltersState = {
  [SEARCH]: '',
  [INPATIENT]: false,
  [TYPE]: { label: ALL, value: ALL },
};

const sliceName = 'admin/groupTypes/filters';

const groupTypesFiltersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<GroupTypeFiltersState>>) => ({ ...state, ...action.payload }),
    clearFilters: (state) => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(rootPathnameChanged, () => initialState);
  },
});

export const getGroupTypesFilters = (state: any) => state[sliceName] as GroupTypeFiltersState;

export const getGroupTypesFiltersForRequest = createSelector(getGroupTypesFilters, (groupTypesFilters) => {
  return Object.entries(groupTypesFilters)
    .filter(([filterKey, filter]: any) => {
      switch (filterKey) {
        case TYPE:
          return filter?.value !== ALL;
        default:
          return filter;
      }
    })
    .reduce((acc, [filterKey, filter]: any) => {
      switch (filterKey) {
        case TYPE:
          return { ...acc, [filterKey]: filter.value };
        case INPATIENT:
          return { ...acc, [filterKey]: filter };
        default:
          return { ...acc, [filterKey]: `${filter}` };
      }
    }, {});
});

export const { clearFilters, setFilters } = groupTypesFiltersSlice.actions;

export default groupTypesFiltersSlice;
