import { createSelector, createSlice } from '@reduxjs/toolkit';

import { ALL_OPTION, CLIENT, SEARCH } from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

export const initialState = {
  [CLIENT]: ALL_OPTION,
  [SEARCH]: '',
};

const sliceName = 'classificationFilters';

const classificationFiltersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { filterType, value } = action.payload;

      state[filterType] = value;
    },
    clearFilters: (state) => ({ ...initialState, [SEARCH]: state[SEARCH] }),
  },
  extraReducers: {
    [rootPathnameChanged]: () => initialState,
  },
});

export const getClassificationFilters = (state) => state[sliceName];

export const getClassificationFiltersForRequest = createSelector(getClassificationFilters, (classificationFilters) => {
  return Object.entries(classificationFilters)
    .filter(([filterKey, filter]) => {
      switch (filterKey) {
        case SEARCH:
          return filter;
        default:
          return filter.id;
      }
    })
    .reduce((acc, [filterKey, filter]) => {
      switch (filterKey) {
        case SEARCH:
          return { ...acc, [filterKey]: `${filter}` };
        default:
          return { ...acc, [filterKey]: `${filter.id}` };
      }
    }, {});
});

export const { clearFilters, setFilter } = classificationFiltersSlice.actions;

export default classificationFiltersSlice;
