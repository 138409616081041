import { createAsyncThunk } from '~/lib';
import { Permission } from '~/models';
import { adminPermissionsApi } from '~/services/api';

const SLICE_NAME = 'admin/permissions';

export const fetchPermissions = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (payload) => {
    const res = await adminPermissionsApi.fetch.invoke(payload);

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Permission
  }
);
