import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from 'styled-components';

import { Footer } from '~/components/layouts';
import { horizontalCenter } from '~/styles/mixins';

import PageContainer from './PageContainer';

function FormPage({ children }: Props) {
  return (
    <FormPageWrapper>
      <Container>
        { children }
        <Footer />
      </Container>
    </FormPageWrapper>
  );
}

FormPage.propTypes = {
  children: PropTypes.element,
};

type Props= InferProps<typeof FormPage.propTypes>;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default FormPage;

const FormPageWrapper = styled(PageContainer)`
  background-color: ${({ theme }) => theme.colors.white};
  ${ horizontalCenter() };
  height: unset;
  min-height: 100%;
  padding: 24px 24px 0 24px;
`;
