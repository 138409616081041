import React, { useCallback } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, Input, InputGroup } from '~/components/shared/form';
import { createClassification, fetchClassification, updateClassification } from '~/ducks/admin/classifications';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { useThunk } from '~/lib/hooks';

import { classificationValidation } from './classificationValidation';

function ClassificationForm(props) {
  const { classificationId, onComplete, title, type } = props;
  const { data: classification } = useThunk(fetchClassification, [], {
    condition: !!classificationId,
    params: classificationId,
  });
  const errorMsg = `${title} with that name aleady exists. ${title} must be unique and not match any existing name.`;

  const handleSubmit = useCallback((values, { setErrors, setSubmitting }) => {
    if (!values.type) values.type = type;

    const saveFunc = values.id ? props.updateClassification : props.createClassification;
    const serializedClassification = values.serialize();

    saveFunc(serializedClassification)
      .then(unwrapResult)
      .then(() => {
        props.addToast({ text: `Classification successfully ${values.id ? 'updated' : 'created'}` });
      })
      .then(() => {
        setSubmitting(false);
        onComplete();
      })
      .catch((e) => {
        if (e?.response?.data?.errors?.name) {
          setErrors({ name: errorMsg });
        } else {
          props.addToast({ text: `There was an error ${values.id ? 'updating' : 'creating'} the classification` });
        }
        setSubmitting(false);
      });
  }, []);

  return (
    <FormContainer>
      <Formik
        initialValues={classification}
        validationSchema={() => classificationValidation(title)}
        onSubmit={handleSubmit}
        enableReinitialize>
        {({ isSubmitting, isValid }) => (
          <Form>
            <InputGroup name='name' label={title} placeholder={title} component={Input} />
            <Actions>
              <Button color='transparent' onClick={onComplete} text='Cancel' />
              <Button type='submit' disabled={!isValid || isSubmitting} loading={isSubmitting} text='Submit' />
            </Actions>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
}

ClassificationForm.propTypes = {
  addToast: PropTypes.func.isRequired,
  classificationId: PropTypes.string.isRequired,
  createClassification: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  updateClassification: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addToast,
  createClassification,
  updateClassification,
};

const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
`;

export default connect(null, mapDispatchToProps)(ClassificationForm);
