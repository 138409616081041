import { createSelector, createSlice } from '@reduxjs/toolkit';

import { ALL_OPTION, CLIENT_TYPE, SEARCH } from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

export const initialState = {
  [CLIENT_TYPE]: ALL_OPTION,
  [SEARCH]: '',
};

const sliceName = 'admin/importConfigurations/filters';

const importConfigurationsFiltersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { filterType, value } = action.payload;

      state[filterType] = value;
    },
    clearFilters: (state) => ({ ...initialState, [SEARCH]: state[SEARCH] }),
  },
  extraReducers: {
    [rootPathnameChanged]: () => initialState,
  },
});

export const getImportConfigurationsFilters = (state) => state[sliceName];

export const getImportConfigurationsFiltersForRequest = createSelector(
  getImportConfigurationsFilters,
  (importConfigurationsFilters) => {
    return Object.entries(importConfigurationsFilters)
      .filter(([filterKey, filter]) => {
        switch (filterKey) {
          case SEARCH:
            return filter;
          default:
            return filter.id;
        }
      })
      .reduce((acc, [filterKey, filter]) => {
        switch (filterKey) {
          case SEARCH:
            return { ...acc, [filterKey]: `${filter}` };
          default:
            return { ...acc, [filterKey]: `${filter.id}` };
        }
      }, {});
  }
);

export const { clearFilters, setFilter } = importConfigurationsFiltersSlice.actions;

export default importConfigurationsFiltersSlice;
