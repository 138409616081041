import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';
import { AFFILIATE, CLIENT_TYPES } from '~/constants/clientTypes';
import { ALL_OPTION } from '~/constants/filterKeysConstants';
import { fetchClients } from '~/ducks/admin/clients';
import { clearFilters, FILTER_KEYS, getFilters, getSearch, setFilter, setSearch } from '~/ducks/admin/groupsFilters';
import { getId, getName } from '~/helpers';
import { useAsyncOptions, useDebounce } from '~/lib/hooks';

import { InputGroup, Select } from '../shared/form';
import Search from '../shared/Search';

function GroupsFilterBar(props) {
  const { filters, locationTypes } = props;
  const [searchValue, setSearchValue] = useState(props.search);
  const debouncedSearch = useDebounce(searchValue);

  const clientsAsyncOptions = useAsyncOptions(fetchClients, {
    params: {
      clientType: CLIENT_TYPES.filter((type) => type !== AFFILIATE),
    },
    optionsToPrepend: [ALL_OPTION],
  });

  useEffect(() => {
    props.setSearch(debouncedSearch);
  }, [debouncedSearch]);

  const canClear = filters[FILTER_KEYS.LOCATION_TYPE] !== ALL_OPTION || filters[FILTER_KEYS.CLIENT] !== ALL_OPTION;
  const locationTypeOptions = locationTypes.map((type) => ({ ...type, value: type.kind }));
  const locationTypeFilterOptions = [ALL_OPTION, ...locationTypeOptions];

  const handleClientChange = (option) => props.setFilter({ key: FILTER_KEYS.CLIENT, value: option });
  const handleLocationTypeChange = (option) => props.setFilter({ key: FILTER_KEYS.LOCATION_TYPE, value: option });
  const handleSearchChange = (e) => setSearchValue(e.target.value);
  const handleSearchClear = () => setSearchValue('');

  return (
    <StyledFilterBar>
      <StyledSearch
        placeholder={'Search'}
        value={searchValue}
        onChange={handleSearchChange}
        onClear={handleSearchClear}
      />
      <StyledInputGroup
        {...clientsAsyncOptions}
        label='Client'
        name='client'
        component={Select}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={handleClientChange}
        value={filters[FILTER_KEYS.CLIENT]}
      />
      <StyledInputGroup
        label={'Group Type'}
        name='locationType'
        component={Select}
        onChange={handleLocationTypeChange}
        getOptionLabel={getName}
        options={locationTypeFilterOptions}
        value={filters[FILTER_KEYS.LOCATION_TYPE]}
      />
      {canClear && <Link onClick={props.clearFilters}>Clear Filters</Link>}
    </StyledFilterBar>
  );
}

GroupsFilterBar.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Object),
  locationTypes: PropTypes.instanceOf(Array),
  search: PropTypes.string,
  setFilter: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
};

GroupsFilterBar.defaultProps = {
  selectedFilters: {},
};

const mapStateToProps = (state) => ({
  filters: getFilters(state),
  search: getSearch(state),
});

const mapDispatchToProps = {
  clearFilters,
  setFilter,
  setSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsFilterBar);

const StyledSearch = styled(Search)`
  margin-right: 24px;
`;

const StyledInputGroup = styled(InputGroup)`
  max-width: 250px;
  min-width: 150px;
  margin-right: 8px;
`;

const StyledFilterBar = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 24px;
`;

const Link = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;
`;
