import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import { BodySmall, BodySmallBold } from '~/components/shared/typography';
import { Client, Flag } from '~/models';
import { PERMISSIONS } from '~/services/profile';
import { history } from '~/store';

import EditFlyoutMenu from '../shared/FlyoutMenu/EditFlyoutMenu';

const columnHelper = createColumnHelper<Flag>();

type ColumnFnProps = {
  profileSvc: any;
} & React.ComponentProps<typeof EditFlyoutMenu>;

const handleClick = (flag: Flag) => {
  history.push(`/feature-flags/${flag.name}/edit`);
};

const concatenatedNames = (objects: Client[]) => {
  const names = Object.values(objects).map((object) => object.name);
  const uniqueNames = [...new Set(names)];

  return uniqueNames.join(', ');
};

export default function featureFlagsTableColumns({ profileSvc, ...editFlyoutMenuProps }: ColumnFnProps) {
  const columns = [
    columnHelper.accessor((row) => row.title, {
      id: 'title',
      header: 'Feature Name',
      cell: (info) => <BodySmallBold>{info.getValue()}</BodySmallBold>,
    }),
    columnHelper.accessor((row) => row.clients, {
      id: 'clients',
      header: 'Clients',
      cell: (info) => {
        return <BodySmall>{concatenatedNames(info.getValue())}</BodySmall>;
      },
    }),
  ];

  if (profileSvc.has(PERMISSIONS.adminFeatureFlagEdit)) {
    columns.push(
      columnHelper.display({
        id: 'menu',
        cell: (info) => (
          <EditFlyoutMenu
            row={info.row}
            permission={PERMISSIONS.adminFeatureFlagEdit}
            onEdit={handleClick}
            {...editFlyoutMenuProps}
          />
        ),
        size: 75,
        meta: { style: { justifyContent: 'flex-end' } },
      })
    );
  }

  return columns;
}
