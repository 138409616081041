import React from 'react';
import styled from 'styled-components';

import { H3 } from '../typography';

function FormSubtitle({ children }) {
  return (
    <TitleContainer>
      <H3>{ children }</H3>
    </TitleContainer>
  );
}

export default FormSubtitle;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  margin-top: 12px;
`;
