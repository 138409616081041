import React, { useState } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';

import * as Sentry from '@sentry/react';

import { useSwitch } from '~/lib/hooks';
import { authService, SESSION_IDLE_TIMEOUT, SESSION_RENEWAL_TIMEOUT } from '~/services/auth';

import SessionTimeoutModal from './SessionTimeoutModal';

function SessionMonitor() {
  const [renewingSession, setRenewingSession] = useState(false);
  const [lastSessionRenewal, setLastSessionRenewal] = useState(new Date().getTime());
  const modalSwitch = useSwitch();

  const sessionRenewed = () => {
    setRenewingSession(false);
    setLastSessionRenewal(new Date().getTime());
  };

  // Upon user activity try and renew their access token based on the SESSION_RENEWAL_TIMEOUT
  // We need to do this periodically so the users access token doesnt timeout while
  // they are using the app
  const handleAction = async () => {
    if (renewingSession) return;

    const currentTime = new Date().getTime();
    const timeLapsed = currentTime - lastSessionRenewal;

    if (timeLapsed >= SESSION_RENEWAL_TIMEOUT) {
      // eslint-disable-next-line no-console
      console.log('SESSION MONITOR RENEW SESSION');
      setRenewingSession(true);

      try {
        // always bypass cache in this instance and go straight to the
        // authorization server for a new access token
        await authService.renewSession({ cacheMode: 'off' });
      } catch (e) {
        authService.logout();
      } finally {
        sessionRenewed();
      }
    }
  };

  // The user has chosen to continue their session so reset the
  // lastSessionRenewal time
  const handleActive = () => {
    sessionRenewed();
  };

  const formatTime = (millis) => {
    const date = new Date(millis);

    return `${date.getMinutes()}:${date.getSeconds()}`;
  };

  const captureIdleMessage = () => {
    Sentry.captureMessage(`
    ElapsedTime: ${formatTime(getElapsedTime())} 
    LastActiveTime: ${getLastActiveTime()} 
    `);
  };

  // When the user's idle timeout has been reached pop the modal forcing
  // them to either continue their session or logout
  const handleIdle = () => {
    captureIdleMessage();
    modalSwitch.turnOn();
  };

  const { activate, getElapsedTime, getLastActiveTime } = useIdleTimer({
    timeout: SESSION_IDLE_TIMEOUT,
    onIdle: handleIdle,
    onActive: handleActive,
    onAction: handleAction,
    debounce: 250,
    timers: workerTimers,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  // The user has chosen to logout or continue their session, either way reset
  // our idle timer component and close the modal
  const handleModalClose = () => {
    activate();
    modalSwitch.turnOff();
  };

  return <SessionTimeoutModal showModal={modalSwitch.state} onClose={handleModalClose} />;
}

export default SessionMonitor;
