import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getClientTypeName } from '~/components/clients/helpers';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import {
  Checkbox,
  Form,
  FormHeader,
  FormSection,
  Input,
  InputGroup,
  MultiSelect,
  MultiSelectLabel,
  SectionHeader,
  Select,
} from '~/components/shared/form';
import { EPISODE, PLAN_TYPE } from '~/constants/classifications';
import { CLIENT_TYPES } from '~/constants/clientTypes';
import { PROVIDER } from '~/constants/groupTypes';
import { fetchClassifications } from '~/ducks/admin/classifications';
import { fetchClients } from '~/ducks/admin/clients';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { getDisplayName, getId, getKind, getName } from '~/helpers';
import { sortBy } from '~/helpers/sortBy';
import { useAsyncOptions } from '~/lib/hooks';
import Client, { MFA_FACTORS, MfaConfig } from '~/models/Client';
import GroupType from '~/models/GroupType';

const filterMfaOptionsByIndependence = (val) =>
  Object.entries(MFA_FACTORS)
    .filter(([_value, { independent }]) => independent === val)
    .map(([value, { label }]) => ({ name: label, id: value }));

function ClientForm(props) {
  const { dirty, isValid, isSubmitting, onCancel, setValues, values } = props;
  const { id, clientType, children } = values;
  const clientTypes = CLIENT_TYPES.map((type) => ({ name: type, kind: type }));

  const episodesAsyncOptions = useAsyncOptions(fetchClassifications, { params: { type: EPISODE } });
  const planTypesAsyncOptions = useAsyncOptions(fetchClassifications, { params: { type: PLAN_TYPE } });
  const providerAsyncOptions = useAsyncOptions(fetchGroupTypes, { params: { sortBy: 'name asc', type: PROVIDER } });
  const mfaIndependentFactorOptions = filterMfaOptionsByIndependence(true);
  const mfaDependentFactorOptions = filterMfaOptionsByIndependence(false);
  const clientsAsyncOptions = useAsyncOptions(fetchClients, {
    condition: Boolean(clientType),
    params: { clientType: clientType?.name },
    optionsTransform: (clients) => {
      return clients.filter((clientOption) => clientOption.id !== id);
    },
  });

  const commonSelectProps = {
    autoSelectSingleOption: false,
    getOptionLabel: getName,
    getOptionValue: getId,
    isClearable: true,
    component: Select,
  };
  const commonMultiSelectProps = {
    ...commonSelectProps,
    component: MultiSelect,
    labelComponent: MultiSelectLabel,
  };

  const handleClientTypeChange = (val) => {
    setValues({
      ...values,
      clientType: val,
      parent: null,
    });
  };

  const handleSsoChange = (val) => {
    if (val) {
      setValues({
        ...values,
        ssoEnabled: true,
        mfaConfig: new MfaConfig().toFormValues(),
      });
    }
  };

  const handleMfaEnabledChange = (val) => {
    if (!val) {
      setValues({
        ...values,
        mfaConfig: new MfaConfig().toFormValues(),
      });
    }
  };

  const handleAllowedIndependentFactorsChange = (factors) => {
    if (factors.length === 0 || !factors.some((factor) => factor.id === values.mfaConfig.default?.id)) {
      setValues({
        ...values,
        mfaConfig: {
          ...values.mfaConfig,
          allowedIndependentFactors: factors,
          default: null,
        },
      });
    }
  };

  return (
    <Form>
      <FormHeader title={id ? 'Edit Client' : 'Add Client'} />
      <FormSection>
        <SectionHeader>Organization Setup</SectionHeader>
        <InputGroup name='name' label='Client Name' placeholder='Client Name' component={Input} />
        <InputGroup
          name='clientType'
          data-cy='clientType'
          label='Client Type'
          placeholder='Client Type'
          options={sortBy(clientTypes, 'name')}
          getOptionLabel={getClientTypeName}
          getOptionValue={getKind}
          onChange={handleClientTypeChange}
          component={Select}
          disabled={Boolean(children.length)}
        />
        <InputGroup
          {...commonSelectProps}
          {...clientsAsyncOptions}
          name='parent'
          data-cy='parent'
          label='Parent Client (optional)'
          placeholder='Parent Client'
          disabled={!clientType}
        />
        <InputGroup
          {...commonMultiSelectProps}
          {...providerAsyncOptions}
          name='groupTypes'
          data-cy='groupTypes'
          label='Enabled Care Options (optional)'
          placeholder='Enabled Care Options'
          getOptionLabel={getDisplayName}
          closeMenuOnSelect={false}
        />
        <InputGroup
          {...commonMultiSelectProps}
          {...episodesAsyncOptions}
          name='children'
          data-cy='children'
          label='Associated Clients (optional)'
          placeholder='Associated Clients'
          disabled
          visible={Boolean(children.length)}
        />
        <ClientCheckbox name='ssoEnabled' label='SSO Enabled' onChange={handleSsoChange} />
        <InputGroup
          name='fileRetentionDays'
          label='File Retention Days (optional)'
          placeholder='File Retention Days'
          component={Input}
        />
        <SectionHeader>Multi-factor Authentication (MFA) Configuration</SectionHeader>
        <ClientCheckbox
          name='mfaConfig.enabled'
          label='MFA Enabled'
          disabled={values.ssoEnabled}
          onChange={handleMfaEnabledChange}
        />
        <InputGroup
          {...commonMultiSelectProps}
          options={mfaIndependentFactorOptions}
          name='mfaConfig.allowedIndependentFactors'
          data-cy='allowedIndependentFactors'
          label='Enabled Independent Factors'
          placeholder='Enabled Independent Factors'
          onChange={handleAllowedIndependentFactorsChange}
          disabled={!values.mfaConfig.enabled || values.ssoEnabled}
        />
        <InputGroup
          {...commonSelectProps}
          options={values.mfaConfig.allowedIndependentFactors}
          name='mfaConfig.default'
          data-cy='defaultFactor'
          label='Default Factor'
          placeholder='Default Factor'
          disabled={!values.mfaConfig.enabled || values.ssoEnabled}
        />
        <InputGroup
          {...commonMultiSelectProps}
          options={mfaDependentFactorOptions}
          name='mfaConfig.allowedDependentFactors'
          data-cy='allowedDependentFactors'
          label='Enabled Dependent Factors (optional)'
          placeholder='Enabled Dependent Factors'
          disabled={!values.mfaConfig.enabled || values.ssoEnabled}
        />
        <SectionHeader>Episodes and Plan Types</SectionHeader>
        <InputGroup
          {...commonMultiSelectProps}
          {...episodesAsyncOptions}
          name='episodeClassifications'
          data-cy='episodeClassifications'
          label='Episode Types'
          placeholder='Episodes'
        />
        <InputGroup
          {...commonMultiSelectProps}
          {...planTypesAsyncOptions}
          name='planTypeClassifications'
          data-cy='planTypeClassifications'
          label='Plan Types'
          placeholder='Plan Types'
        />
      </FormSection>
      <ButtonGroup>
        <Button color='transparent' text='Cancel' onClick={onCancel} />
        <Button
          type='submit'
          disabled={!dirty || !isValid || isSubmitting}
          text={id ? 'Update Client' : 'Create Client'}
        />
      </ButtonGroup>
    </Form>
  );
}

ClientForm.propTypes = {
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  values: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.instanceOf(Client)),
    clientType: PropTypes.instanceOf(Object),
    groupTypes: PropTypes.arrayOf(PropTypes.instanceOf(GroupType)),
    id: PropTypes.string,
    leaf: PropTypes.bool,
    mfaConfig: PropTypes.shape({
      enabled: PropTypes.bool,
      allowedDependentFactors: PropTypes.arrayOf(PropTypes.object),
      allowedIndependentFactors: PropTypes.arrayOf(PropTypes.object),
      default: PropTypes.object,
    }),
    ssoEnabled: PropTypes.bool,
  }),
};

export default ClientForm;

const ClientCheckbox = styled(Checkbox)`
  margin: 8px 0px 24px 0px;
`;
