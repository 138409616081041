import React from 'react';
import PropTypes from 'prop-types';

import { FlyoutMenu, FlyoutMenuIcon, FlyoutMenuItem } from '~/components/shared/FlyoutMenu';
import { PencilIcon } from '~/components/shared/svg';
import { useProfileContext } from '~/services/profile';

function EditFlyoutMenu(props) {
  const { onEdit, menuText, permission, row, width } = props;
  const profileSvc = useProfileContext();

  return (
    <FlyoutMenu data={row.original}>
      <FlyoutMenuItem onClick={onEdit} width={width} visible={profileSvc.has(permission)}>
        <FlyoutMenuIcon>
          <PencilIcon size={18} />
        </FlyoutMenuIcon>
        {menuText}
      </FlyoutMenuItem>
    </FlyoutMenu>
  );
}

EditFlyoutMenu.defaultProps = {
  menuText: 'Edit',
};

EditFlyoutMenu.propTypes = {
  menuText: PropTypes.string,
  onEdit: PropTypes.func,
  permission: PropTypes.string,
  row: PropTypes.shape({
    original: PropTypes.instanceOf(Object),
  }),
  width: PropTypes.string,
};

export default EditFlyoutMenu;
