import React, { useEffect, useState } from 'react';
import * as _ from 'lodash-es';
import styled from 'styled-components';

import TabFilter, { TabType } from './TabFilter';

type Props = {
  onTabChanged: (selectedValue: string) => void;
  onTabClick: (selectedValue: string) => void;
  selectedTab?: TabType;
  tabs: TabType[];
};

function TabFilters({ onTabChanged = _.noop, onTabClick = _.noop, selectedTab, tabs = [], ...props }: Props) {
  const [activeTab, setActiveTab] = useState(selectedTab || tabs[0]);

  useEffect(() => {
    if (selectedTab) {
      setActiveTab(selectedTab);
      onTabChanged(selectedTab.value);
    }
  }, [selectedTab]);

  function handleTabClick(tab: TabType) {
    if (!selectedTab) {
      setActiveTab(tab);
      onTabChanged(tab.value);
    }
    onTabClick(tab.value);
  }

  return (
    <TabsContainer {...props}>
      {tabs.map((tab) => (
        <TabFilter key={tab.label} tab={tab} active={activeTab?.value === tab.value} onClick={handleTabClick} />
      ))}
    </TabsContainer>
  );
}

export default TabFilters;

const TabsContainer = styled.div`
  display: flex;
  width: calc(100% + 24px);
  margin-left: -24px;
  padding-left: 24px;
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-left: none;
  border-right: none;
  background-color: ${({ theme }) => theme.colors.white};
  > * {
    margin-right: 20px;
  }
`;
