import React, { useMemo } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from 'styled-components';

import TabFilters from '~/components/shared/TabFilters';
import { Client } from '~/models';

function CareOptionsTabs({ client, onTabChanged, onTabClick, selectedTabValue }: Props) {
  const tabs = useMemo(() => {
    return client.clientGroupTypes.map((type) => ({
      label: type.groupType.displayName,
      value: type.id,
    }));
  }, [client.clientGroupTypes]);

  const selectedTab = useMemo(() => {
    return tabs.find((tab) => tab.value === selectedTabValue) || tabs[0];
  }, [tabs, selectedTabValue]);

  const visible = !!tabs.length;

  return (
    <>
      <TabFiltersContainer>
        {visible && (
          <TabFilters
            tabs={tabs}
            dataCy='careOptionsTabs'
            onTabChanged={onTabChanged}
            onTabClick={onTabClick}
            selectedTab={selectedTab}
          />
        )}
      </TabFiltersContainer>
    </>
  );
}

CareOptionsTabs.propTypes = {
  client: PropTypes.instanceOf(Client).isRequired,
  onTabChanged: PropTypes.func,
  onTabClick: PropTypes.func,
  selectedTabValue: PropTypes.string,
};

type Props = InferProps<typeof CareOptionsTabs.propTypes>;

export default CareOptionsTabs;

const TabFiltersContainer = styled.div`
  margin-top: 24px;
`;
