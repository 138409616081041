import { createAsyncThunk } from '~/lib';
import { userPreferencesApi } from '~/services/api';

const cancellableUpdateUserPreference = userPreferencesApi.update.cancellable();
const SLICE_NAME = 'profile/preferences';

export const updateUserPreference = createAsyncThunk(
  `${SLICE_NAME}/update`,
  async (params) => {
    const { data } = await cancellableUpdateUserPreference(params);

    return data;
  }
);

