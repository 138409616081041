import { RANGE } from '~/constants/activities';
import { sortBy } from '~/helpers/sortBy';

export default class QuestionArray extends Array {
  sort(compareFn) {
    if (!compareFn) return this.defaultSort();

    return Array.prototype.sort.call(this, compareFn);
  }

  defaultSort() {
    const sortRangeAtBottom = (a, b) => {
      if (a.kind !== RANGE && b.kind === RANGE) return -1;
      if (a.kind === RANGE && b.kind !== RANGE) return 1;
      return 0;
    };

    const sortedByOrder = sortBy(this, 'order');

    return Array.prototype.sort.call(sortedByOrder, sortRangeAtBottom);
  }
}
