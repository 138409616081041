import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, FormHeader } from '~/components/shared/form';
import { TEMPLATE_TYPE_OPTIONS } from '~/constants/questionTemplateTypes';
import { Client } from '~/models';

import { formValidation } from './formValidation';
import InformationSection from './InformationSection';
import QuestionsSection from './QuestionsSection';

function TemplateForm(props) {
  const { clients, onCancel, onSubmit, questionTemplate } = props;

  const initialValues = {
    ...questionTemplate,
    doNotTrackLos: !questionTemplate.trackLos,
    type: TEMPLATE_TYPE_OPTIONS.find((type) => type.value === questionTemplate.type),
  };

  return (
    <Formik initialValues={initialValues} validationSchema={formValidation} onSubmit={onSubmit} enableReinitialize>
      {({ isSubmitting, isValid, values }) => (
        <Form>
          <FormHeader title={`${values.id ? 'Edit' : 'Add'} Template`} />
          <InformationSection clients={clients} questionTemplate={questionTemplate} />
          <QuestionsSection />
          <ButtonGroup>
            <Button color='transparent' text='Cancel' onClick={() => onCancel(values)} />
            <Button
              type='submit'
              disabled={!isValid || isSubmitting}
              text={`${values.id ? 'Update' : 'Create'} Template`}
            />
          </ButtonGroup>
        </Form>
      )}
    </Formik>
  );
}

TemplateForm.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.instanceOf(Client)),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  questionTemplate: PropTypes.instanceOf(Object),
};

export default TemplateForm;
