import styled from 'styled-components';

const FormPageContentContainer = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-end;
`;

export default FormPageContentContainer;
