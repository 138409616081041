import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import questionsConfigurations from './questionsConfigurations';

function Question({ question, index, onRemove }) {
  const fieldName = `questions.${index}`;
  const questionConfig = questionsConfigurations[question.kind];

  if (!questionConfig) {
    return null;
  }

  const handleRemove = () => onRemove(index);

  return (
    <Field
      name={fieldName}
      component={questionConfig.component}
      inputLabel={questionConfig.inputLabel}
      removeItem={handleRemove}
    />
  );
}

Question.propTypes = {
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  question: PropTypes.instanceOf(Object).isRequired,
};

export default Question;
