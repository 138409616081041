import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';

import { CircleXIcon } from '~/components/shared/svg';
import { verticalCenter } from '~/styles/mixins';
import { colors, textStyles } from '~/styles/theme';

function Search(props) {
  const { onChange, onClear, placeholder, value, ...rest } = props;

  const handleChange = (e) => onChange(e);

  const handleClear = (e) => onClear(e);

  return (
    <SearchContainer {...rest}>
      <FontAwesome name='search' style={{ color: colors.black25, marginLeft: 8, marginRight: 8 }} />
      <Input placeholder={placeholder} value={value} onChange={handleChange} />
      {value && (
        <ClearSearchContainer onClick={handleClear}>
          <CircleXIcon size={16} />
        </ClearSearchContainer>
      )}
    </SearchContainer>
  );
}

Search.propTypes = {
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

const noop = () => {};

Search.defaultProps = {
  onChange: noop,
  onClear: noop,
  placeholder: 'Search...',
  value: '',
};

export default Search;

const SearchContainer = styled.div`
  ${verticalCenter()};
  border-radius: 25px;
  padding: 8px 10px;
  background-color: ${colors.gray};
  max-width: 250px;
  min-width: 100px;
  width: 100%;
`;

const Input = styled.input`
  background-color: transparent;
  width: 100%;
  outline: none !important;
  border: none;
  color: ${textStyles.base.color};
  font-size: ${textStyles.base.fontSize};

  ::placeholder {
    color: ${colors.black25};
  }
`;

const ClearSearchContainer = styled.div`
  ${verticalCenter()};
  cursor: pointer;
  margin-left: 4px;
`;
