import { createAsyncThunk } from '~/lib';
import { Group } from '~/models';
import { groupsApi } from '~/services/api';

export const fetchGroups = createAsyncThunk(
  'groups/fetch',
  async (payload) => {
    const defaults = { pageSize: 5000, sortBy: 'name asc' };
    const res = await groupsApi.fetch.invoke({ ...defaults, ...payload });

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Group,
  }
);
