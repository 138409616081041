import { SKILLED_NURSING_FACILITY } from '~/constants/locationTypes';

const defaults = {
  kind: '',
  name: ''
};

export default class LocationType {
  constructor(opts = {}) {
    this.kind = opts.kind || defaults.kind;
    this.name = opts.name || defaults.name;
  }

  get isSNF() {
    return this.kind === SKILLED_NURSING_FACILITY;
  }
}
