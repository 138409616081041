import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BodySmallBold } from '~/components/shared/typography';
import { simpleDateAtTime } from '~/lib/formatDate';
import { Import } from '~/models';

import ImportStatus from './ImportStatus';

function StatusDetailsHeader({ importObj }: Props) {
  return (
    <StatusInfoBarContainer>
      <Row>
        <StyledBodySmallBold>Client:&nbsp;</StyledBodySmallBold>
        <BodySmallBold>{importObj.clientName}</BodySmallBold>
      </Row>
      <StyledRow>
        <StyledBodySmallBold>Status:&nbsp;</StyledBodySmallBold>
        <ImportStatus customStyles={ { margin: '0px 8px 0 4px' } } status={ importObj.status } />
      </StyledRow>
      <Row>
        <StyledBodySmallBold>Import Created On:&nbsp;</StyledBodySmallBold>
        <BodySmallBold>{simpleDateAtTime(importObj.createdAt)}</BodySmallBold>
      </Row>
    </StatusInfoBarContainer>
  );
}

StatusDetailsHeader.propTypes = {
  importObj: PropTypes.instanceOf(Import).isRequired,
};

type Props= PropTypes.InferProps<typeof StatusDetailsHeader.propTypes>;

export default StatusDetailsHeader;

const StatusInfoBarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
`;

const StyledBodySmallBold = styled(BodySmallBold)`
  color: ${({ theme }) => theme.colors.black50 };
`;

const Row = styled.div`
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
`;
