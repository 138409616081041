import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { unwrapResult } from '@reduxjs/toolkit';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, FormHeader, InputGroup, MultiSelect, MultiSelectLabel } from '~/components/shared/form';
import LoadingSpinner from '~/components/shared/loadingSpinner';
import { FormPage } from '~/components/shared/pageLayout';
import { ACUTE_CLIENT_TYPES } from '~/constants/clientTypes';
import { fetchClients } from '~/ducks/admin/clients';
import { updateFlag } from '~/ducks/admin/flags';
import { addToast } from '~/ducks/toasts';
import { useThunk } from '~/lib/hooks';
import adminFlagsAPI from '~/sources/api/adminFlagsAPI';

import { parseFlagData } from './flagsHelpers';

function EditFeatureFlag(props) {
  const [flag, setFlag] = useState(null);
  const { data: clients } = useThunk(fetchClients, [], {
    params: {
      clientType: ACUTE_CLIENT_TYPES.join(','),
    },
  });

  const fetchFlag = async () => {
    const { name } = props.match.params;
    const response = await adminFlagsAPI.fetchFlag(name);

    setFlag(response.data);
  };

  useEffect(() => {
    fetchFlag();
  }, []);

  const flagData = useMemo(() => {
    return parseFlagData({ flag, clients });
  }, [flag, clients]);

  const byName = ({ name }) => name;
  const byId = ({ id }) => id;

  const handleSubmit = async (updatedFlag) => {
    const updateObj = {
      name: updatedFlag.name,
      clientIds: updatedFlag.clients.map((client) => client.id),
    };

    try {
      const result = await props.updateFlag(updateObj);

      unwrapResult(result);

      props.addToast({ text: 'Feature flag successfully updated' });
      props.history.push('/feature-flags');
    } catch (e) {
      props.addToast({ text: 'Something went wrong.  Please try again.' });
    }
  };

  const handleCancel = () => {
    props.history.push('/feature-flags');
  };

  if (!flagData) {
    return <LoadingSpinner />;
  }

  return (
    <FormPage>
      <Formik initialValues={flagData} onSubmit={handleSubmit} enableReinitialize>
        <Form>
          <FormHeader title='Edit Feature Flag' subtitle={flagData.title} />
          <InputGroup
            name='clients'
            label='Clients'
            options={clients}
            getOptionLabel={byName}
            getOptionValue={byId}
            component={MultiSelect}
            labelComponent={MultiSelectLabel}
          />
          <ButtonGroup>
            <Button color='transparent' onClick={handleCancel} text='Cancel' />
            <Button type='submit' text='Update' />
          </ButtonGroup>
        </Form>
      </Formik>
    </FormPage>
  );
}

EditFeatureFlag.propTypes = {
  addToast: PropTypes.func.isRequired,
  groups: PropTypes.instanceOf(Array),
  updateFlag: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addToast,
  updateFlag,
};

export default connect(null, mapDispatchToProps)(EditFeatureFlag);
