/* eslint-disable react/display-name */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';

import EditFlyoutMenu from '~/components/shared/FlyoutMenu/EditFlyoutMenu';
import { BodySmallBold } from '~/components/shared/typography';
import { Collaboration } from '~/models';
import { PERMISSIONS } from '~/services/profile';

type ColumnFnProps = {
  profileSvc: any;
} & React.ComponentProps<typeof EditFlyoutMenu>;

const columnHelper = createColumnHelper<Collaboration>();

export default function collaborationsTableColumns({ profileSvc, onEdit }: ColumnFnProps) {
  const columns = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Primary Owner Client',
      cell: (info) => <BodySmallBold>{info.getValue()}</BodySmallBold>,
    }),
    columnHelper.accessor((row) => row.collaboratorNames, {
      id: 'collaboratorNames',
      header: 'Collaborating Clients',
      enableSorting: false,
    }),
  ];

  if (profileSvc.has(PERMISSIONS.adminCollaborationEdit)) {
    columns.push(
      columnHelper.display({
        id: 'menu',
        cell: (info) => (
          <EditFlyoutMenu row={info.row} onEdit={onEdit} permission={PERMISSIONS.adminCollaborationEdit} />
        ),
        size: 75,
        meta: { style: { justifyContent: 'flex-end' } },
      })
    );
  }

  return columns;
}
