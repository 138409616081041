import React, { useState } from 'react';
import { Route, Switch } from 'react-router';

import { DischargeTemplates } from './dischargeTemplates';
import { ProgressTemplates } from './progressTemplates';

function QuestionTemplates(props) {
  const [tabs] = useState([
    { label: 'Progress Templates', value: 'progress' },
    { label: 'Discharge Templates', value: 'discharge' },
  ]);

  const handleTabSelect = (val) => {
    const path = val === 'progress' ? '/question-templates/progress' : '/question-templates/discharge';

    props.history.push(path);
  };

  const allProps = {
    ...props,
    onTabSelect: handleTabSelect,
    tabs,
  };

  return (
    <Switch>
      <Route path='/question-templates/progress'>
        <ProgressTemplates {...allProps} />
      </Route>
      <Route path='/question-templates/discharge'>
        <DischargeTemplates {...allProps} />
      </Route>
    </Switch>
  );
}

export default QuestionTemplates;
