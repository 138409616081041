import * as _ from 'lodash-es';

const isDeepEqual = (a: unknown, b: unknown) => {
  let aa = a;
  let bb = b;

  if (typeof a === 'object') {
    aa = JSON.parse(JSON.stringify(a));
  }

  if (typeof b === 'object') {
    bb = JSON.parse(JSON.stringify(b));
  }

  return _.isEqual(aa, bb);
};

export default isDeepEqual;
