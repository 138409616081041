import { IRequestOptions } from '~/services/api/core/IRequestOptions';

import ApiRequest from './ApiRequest';

interface IArgs {
  url?: string;
  params?: object;
}
const formatArgsForConfigDefault = ({ url, ...params }: any = {}) => {
  const args: IArgs = { params };

  if (url) {
    args.url = url;
  }

  return args;
};

export default class FetchRequest extends ApiRequest {
  constructor(options: IRequestOptions = {}) {
    const { url, config, formatArgsForConfig = formatArgsForConfigDefault } = options;

    super({ method: 'get', url, config, formatArgsForConfig });
  }
}
