import { createSelector, createSlice } from '@reduxjs/toolkit';

import { ALL_OPTION, CLIENT, EPISODE, GROUP_TYPE, PLAN_TYPE } from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

export const initialState = {
  [CLIENT]: ALL_OPTION,
  [GROUP_TYPE]: ALL_OPTION,
  [EPISODE]: ALL_OPTION,
  [PLAN_TYPE]: ALL_OPTION,
};

const sliceName = 'dischargeTemplates/filters';

const dischargeTemplatesFiltersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { filterType, value } = action.payload;

      state[filterType] = value;
    },
    clearFilters: () => initialState,
  },
  extraReducers: {
    [rootPathnameChanged]: () => initialState,
  },
});

export const getDischargeTemplatesFilters = (state) => state[sliceName];

export const getDischargeTemplatesFiltersForRequest = createSelector(
  getDischargeTemplatesFilters,
  (dischargeTemplatesFilters) => {
    return Object.entries(dischargeTemplatesFilters)
      .filter(([_, filter]) => filter.id)
      .reduce((acc, [filterKey, filter]) => ({ ...acc, [filterKey]: `${filter.id}` }), {});
  }
);

export const { clearFilters, setFilter } = dischargeTemplatesFiltersSlice.actions;

export default dischargeTemplatesFiltersSlice;
