import React, { useContext } from 'react';
import { ErrorMessage, FormikContext } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Error from './ErrorMessage';
import FieldLabel from './FieldLabel';

function InputGroup(props) {
  const { children, loading, onChange, onBlur, ...rest } = props;
  const { label, name, component, labelComponent } = rest;

  let ComponentObject;

  if (component) {
    ComponentObject = component;
  }

  const LabelComponentObject = labelComponent || FieldLabel;
  const context = useContext(FormikContext);

  return (
    <InputGroupContainer {...rest}>
      {!!label && (
        <LabelComponentObject name={name} {...rest}>
          {label}
        </LabelComponentObject>
      )}

      {component && <ComponentObject loading={loading} onBlur={onBlur} onChange={onChange} {...rest} />}
      {React.Children.map(children, (child) => {
        if (typeof child === 'string') {
          return child;
        }
        return React.cloneElement(child, {
          loading,
          onBlur,
          onChange,
          ...rest,
          ...child.props,
        });
      })}

      {context && (
        <ErrorMessageContainer>
          <ErrorMessage name={name} component={Error} />
        </ErrorMessageContainer>
      )}
    </InputGroupContainer>
  );
}

InputGroup.propTypes = {
  autoSelectSingleOption: PropTypes.bool,
  clearable: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  component: PropTypes.any,
  disabled: PropTypes.bool,
  getOptionLabel: PropTypes.any,
  getOptionValue: PropTypes.any,
  isClearable: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  menuPortalTarget: PropTypes.any,
  minDate: PropTypes.instanceOf(Date),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onEndReached: PropTypes.func,
  onInputChange: PropTypes.func,
  options: PropTypes.any,
  placeholder: PropTypes.string,
  styles: PropTypes.any,
  value: PropTypes.any,
  visible: PropTypes.bool,
};

InputGroup.defaultProps = {
  label: '',
  visible: true,
};

export default InputGroup;

const InputGroupContainer = styled.div`
  display: ${({ visible }) => (visible ? 'initial' : 'none')};
  width: 100%;
`;

export const ErrorMessageContainer = styled.div`
  margin-top: 6px;
  min-height: 20px;
  white-space: pre-line;
`;
