import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '~/styles/theme';

import { BodySmall } from '../typography';

import { TabPropType } from './TabPropType';

export type TabType = {
  label: string;
  value: string;
};

type Props = {
  active: boolean;
  onClick: (tab: TabType) => void;
  tab: TabType;
};

function Tab({ tab, active, onClick }: Props) {
  const { label, ...rest } = tab;

  function handleClick() {
    onClick(tab);
  }

  return (
    <TabContainer onClick={handleClick} active={active} {...rest}>
      <BodySmall color={active ? colors.primaryBlue : colors.black} fontWeight={active ? 'bold' : 'inherit'}>
        {label}
      </BodySmall>
    </TabContainer>
  );
}

Tab.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  tab: TabPropType.isRequired,
};

Tab.defaultProps = {
  active: false,
};

export default Tab;

const TabContainer = styled.div<{ active: boolean }>`
  cursor: pointer;
  padding: 10px 10px ${({ active }) => (active ? '7px' : '10px')} 10px;
  border-bottom: ${({ active }) => (active ? `3px solid ${colors.primaryBlue}` : 'none')};
`;
