import { IRequestOptions } from '~/services/api/core/IRequestOptions';

import ApiRequest from './ApiRequest';

const formatArgsForConfigDefault = (url: string | undefined) => (id: any, params: any) => ({
  url: `${url}/${id}`,
  params,
});

export default class DeleteRequest extends ApiRequest {
  constructor(options: IRequestOptions = {}) {
    const { url, config, formatArgsForConfig = formatArgsForConfigDefault(url) } = options;

    super({ method: 'delete', url, config, formatArgsForConfig });
  }
}
