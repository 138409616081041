import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';

import { colors } from '~/styles/theme';

function InfiniteScroll(props) {
  const { className, element, hasMore, loading, onEndReached } = props;

  useEffect(() => {
    if (element) {
      element.onscroll = ({ target }) => {
        const distanceFromBottom = target.scrollHeight - (target.clientHeight + target.scrollTop);

        if (distanceFromBottom < 100 && hasMore && !loading) {
          onEndReached();
        }
      };
    }
  }, [element, loading, hasMore]);

  return (
    loading && (
      <LoadingContainer className={className}>
        <FontAwesome spin name='spinner' size='2x' style={{ color: colors.black25 }} />
      </LoadingContainer>
    )
  );
}

InfiniteScroll.propTypes = {
  element: PropTypes.instanceOf(Element),
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  onEndReached: PropTypes.func,
};

InfiniteScroll.defaultProps = {
  hasMore: false,
  loading: false,
  onEndReached: () => {},
};

export default InfiniteScroll;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  height: 50px;
  margin-bottom: 10px;
`;
