class ModelFactory {
  static classMap = {};

  static defaultClass = class Empty {};

  static get(options = {}) {
    const Klass = this.classMap[options.type] || this.defaultClass;

    return new Klass(options);
  }
}

export default ModelFactory;
