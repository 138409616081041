import client from '~/sources/api/client';

import { Api, CreateRequest, FetchRequest } from './core';

export const url = '/admin/users';

const api = new Api({ url });

export default {
  ...api,
  fetchNotificationPreferencesById: new FetchRequest({
    formatArgsForConfig: ({ id, ...params }) => ({
      url: `${url}/${id}/notification_preferences`,
      params,
    }),
  }),
  reactivate: new CreateRequest({
    formatArgsForConfig: ({ id, ...params }) => ({
      url: `${url}/${id}/reactivate`,
      params,
    }),
  }),
  resendInvitation(userId: string) {
    return client.post(`${url}/${userId}/resend_invite`);
  },
};
