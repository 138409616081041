import React from 'react';
import PropTypes from 'prop-types';

import colors from '~/styles/theme/colors';

const ResumeIcon = ({ fill = colors.black50, size = 24 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size * 0.75} height={size} viewBox='0 0 11 14'>
      <path
        fill={fill}
        fillRule='nonzero'
        d='M.914.102A.583.583 0 0 0 0 .583v12.834a.583.583 0 0 0 .914.48l9.333-6.416a.583.583 0 0 0 0-.962L.914.102Z'
      />
    </svg>
  );
};

ResumeIcon.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
};

export default ResumeIcon;
