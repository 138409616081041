import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { StandardModal } from '~/components/shared/modal';
import { Body } from '~/components/shared/typography';

function SaveChangesModal({ show, ...props }) {
  const handleOnCancel = () => {
    props.onCancel();
  };

  const handleOnConfirm = () => {
    props.onConfirm();
  };

  return (
    <StandardModal show={ show }
      title={ 'Save Changes?' }
      onCancel={ handleOnCancel }>
      <Content>
        <StyledBody>Are you sure you wish to continue without saving? Your changes will be lost.</StyledBody>
      </Content>
      <StyledButtonGroup>
        <Button color='transparent'
          onClick={ handleOnCancel }
          text='Go Back' />
        <Button onClick={ handleOnConfirm }
          text='Continue Without Saving' />
      </StyledButtonGroup>
    </StandardModal>
  );
}

SaveChangesModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool
};

SaveChangesModal.defaultProps = {
  onCancel: () => {},
  onConfirm: () => {},
  show: false
};

export default SaveChangesModal;

const Content = styled.div`
  margin: 26px 0 50px;
  text-align: center;
`;

const StyledBody = styled(Body)`
  display: block;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;
`;
