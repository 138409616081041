import { createAction, createSlice } from '@reduxjs/toolkit';

export const API_STATES = {
  complete: 'complete',
  failed: 'failed',
  idle: 'idle',
  pending: 'pending',
};

const sliceName = 'api';
const idleStatus = { status: API_STATES.idle };
const parseApiName = (action) => action.type.substring(0, action.type.lastIndexOf('/'));
const setApiStatus = (state, action, apiStatus, error = null) => {
  const apiName = parseApiName(action);

  state[apiName] = { status: apiStatus };
  if (error) {
    state[apiName].error = error;
  }
};

const slice = createSlice({
  name: sliceName,
  initialState: {},
  reducers: {
    resetApiStatuses: (state) => {
      return Object.keys(state).reduce((acc, apiName) => {
        acc[apiName] = idleStatus;
        return acc;
      }, {});
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        (action) => /.*pending$/.test(action.type),
        (state, action) => {
          setApiStatus(state, action, API_STATES.pending);
        }
      )
      .addMatcher(
        (action) => /.*fulfilled$/.test(action.type),
        (state, action) => {
          setApiStatus(state, action, API_STATES.complete);
        }
      )
      .addMatcher(
        (action) => /.*rejected$/.test(action.type),
        (state, action) => {
          setApiStatus(state, action, API_STATES.failed, action.payload);
        }
      )
      .addMatcher(
        (action) => /.*reset$/.test(action.type),
        (state, action) => {
          setApiStatus(state, action, API_STATES.idle);
        }
      ),
});

export const { resetApiStatuses } = slice.actions;

export default slice.reducer;

const convertToArray = (value) => (!(value instanceof Array) ? [value] : value);

const getThunkActionType = (thunk) => {
  const fulfilledActionType = thunk.fulfilled.type;

  return fulfilledActionType.substring(0, fulfilledActionType.lastIndexOf('/'));
};

export const getApiStatus = (state, thunk) => {
  const actionType = getThunkActionType(thunk);
  const apiState = state[sliceName];

  return apiState[actionType] || idleStatus;
};

export const createApiHasStatusSelector = (thunk, status) => {
  const statuses = convertToArray(status);

  return (state) => {
    const apiStatus = getApiStatus(state, thunk);

    return statuses.includes(apiStatus.status);
  };
};

export const createApiAllErrorsSelector = (thunk) => {
  const thunks = convertToArray(thunk);
  const actionTypes = thunks.map((t) => getThunkActionType(t));

  return (state) => {
    const apiState = state[sliceName];

    return actionTypes
      .filter((type) => apiState[type]?.status === API_STATES.failed && !!apiState[type]?.error)
      .map((type) => ({ type, error: apiState[type]?.error }));
  };
};

export const createApiErrorSelector = (thunk) => {
  return (state) => {
    const thunkState = getApiStatus(state, thunk);

    return thunkState.error;
  };
};

export const createApiResetStatus = (thunk) => {
  const thunkType = getThunkActionType(thunk);

  return createAction(`${thunkType}/reset`);
};
