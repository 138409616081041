import React, { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, FormHeader, InputGroup, MultiSelect, MultiSelectLabel } from '~/components/shared/form';
import { Client, Collaboration } from '~/models';

function CollaborationForm(props) {
  const { clients, collaboration, onCancel, onSubmit } = props;

  const byName = ({ name }) => name;
  const byId = ({ id }) => id;

  const initialValues = useMemo(() => collaboration.toFormValues(clients), [collaboration, clients]);

  return (
    <FormContainer>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        {({ isSubmitting }) => (
          <Form>
            <FormHeader subtitle={`Primary Owner: ${collaboration.name}`} />
            <InputGroup
              name='collaborators'
              label='Collaborating Clients'
              data-cy='collaboratingClients'
              options={clients}
              getOptionLabel={byName}
              getOptionValue={byId}
              component={MultiSelect}
              labelComponent={MultiSelectLabel}
              menuPortalTarget={document.body}
              styles={{ menuPortal: { zIndex: 9999 } }}
            />
            <Actions>
              <Button color='transparent' text='Cancel' onClick={onCancel} />
              <Button type='submit' disabled={isSubmitting} text='Save Collaborations' />
            </Actions>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
}

CollaborationForm.propTypes = {
  clients: PropTypes.arrayOf(PropTypes.instanceOf(Client)),
  collaboration: PropTypes.instanceOf(Collaboration),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CollaborationForm;

const Actions = styled(ButtonGroup)`
  margin-top: 16px;
  justify-content: flex-end;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
