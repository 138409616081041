import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';

import colors from '~/styles/theme/colors';

export type ModalProps = {
  disableBackdropClick?: boolean;
  children: React.ReactNode;
  show?: boolean;
  onCancel?: () => void;
  backdropStyles?: React.CSSProperties;
};

function Modal({
  disableBackdropClick = false,
  children,
  onCancel,
  backdropStyles,
  show = false,
  ...rest
}: ModalProps) {
  const modalRef = useRef(null);

  // The modal is a child of the backdrop. When close on backdrop click is
  // enabled clicking the modal itself also triggers the close. This method
  // prevents the event from bubbling up through the DOM so our modal clicks
  // do not trigger a close. Only clicking the backdrop should do that.
  const handleBackdropClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLDivElement).dataset.component !== 'modalBackdrop') {
      return;
    }

    if (disableBackdropClick) {
      return;
    }

    onCancel?.();
  }, []);

  if (!show) {
    return null;
  }

  return (
    <React.Fragment>
      <Backdrop data-component='modalBackdrop' onClick={handleBackdropClick} style={backdropStyles}>
        <StyledModal {...rest} ref={modalRef}>
          {children}
        </StyledModal>
      </Backdrop>
    </React.Fragment>
  );
}

export default Modal;

const StyledModal = styled.div`
  flex: 0;
  z-index: 2;
  background-color: ${colors.white};
  min-width: 480px;
  max-width: 560px;
  border-radius: 5px;
  margin-top: 80px;
`;

const Backdrop = styled.div`
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: ${colors.black50};
  overflow: auto;
`;
