import client from '~/sources/api/client';

import { Api } from './core';

const { fetch } = new Api({ url: '/clients' });

export default {
  fetch,
  fetchForResource(resource) {
    const params = {
      resourceId: resource.id,
      resourceType: resource.type,
    };

    return client.get('/clients/for_resource', { params });
  },
  getAgreements() {
    return client.get('/client_agreements');
  },
};
