import { createAsyncThunk } from '@reduxjs/toolkit';

// We're creating our own "createAsyncThunk" so that the raw error is always
// in the action.payload. By default, redux-toolkit will make the error fit
// to their own SerializedError interface which causes some key information
// to be left out, like the status code. This allows us to get the to raw
// error without doing the "rejectWithValue" call everytime we make a new thunk.

const createAsyncThunkWithRejectHandling = (type, payloadCreator, { modelClass = null, defaultValue = null, ...options } = {}) => {
  const wrappedPayloadCreator = async (payload, thunkApi) => {
    try {
      return await payloadCreator(payload, thunkApi);
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  };

  const thunk = createAsyncThunk(type, wrappedPayloadCreator, options);

  thunk.modelClass = modelClass;
  thunk.defaultValue = defaultValue;

  return thunk;
};

export default createAsyncThunkWithRejectHandling;
