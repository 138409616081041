import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Checkbox } from '~/components/shared/form';
import TOSLink from '~/components/shared/TOSLink';

import { Button } from '../shared/buttons';

const initialValues = { tos: false, privacy: false };

const AgreementForm = ({ clientAgreements, onSave }) => {
  const handleSave = (values) => {
    onSave(values);
  };

  const isDisabled = (data) => {
    return Object.values(data).filter((value) => !value).length > 0;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSave}
      render={({ handleSubmit, values }) => (
        <Form onSubmit={handleSubmit}>
          <Message>
            Welcome! Before you can continue, please review and agree to the terms of service and privacy policy.
          </Message>
          {clientAgreements.map((agreement, index) => (
            <ClientAgreement key={index}>{agreement}</ClientAgreement>
          ))}
          <CheckboxContainer>
            <Checkbox name='tos' checkedColor='rgb(50,83,239)' size={20} />
            <CheckboxLabel>
              I agree to Olio's&nbsp;
              <TOSLink>Terms of Service</TOSLink>
            </CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <Checkbox name='privacy' checkedColor='rgb(50,83,239)' size={20} />
            <CheckboxLabel>
              I agree to Olio's&nbsp;
              <a href='https://policy.olio.health/privacy-policy' target='_blank' rel='noopener noreferrer'>
                Privacy Policy
              </a>
            </CheckboxLabel>
          </CheckboxContainer>
          <FormActions>
            <Button type='submit' disabled={isDisabled(values)} text='Continue' />
          </FormActions>
        </Form>
      )}
    />
  );
};

const Form = styled.form`
  color: #0f1226;
  font-size: 16px;
  line-height: 1.5;
`;

const Message = styled.p`
  margin: 0px 0px 24px;
`;

const FormActions = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: none;
  margin-top: 24px;

  & > *:first-child {
    margin-right: 8px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const CheckboxLabel = styled.div`
  opacity: 0.5;

  & a {
    text-decoration: underline;
  }
`;

const ClientAgreement = styled.p`
  margin: 0px 0px 24px;
  padding: 8px;
  font-style: italic;
  background-color: rgba(15, 18, 38, 0.05);
  border: 1px solid rgba(15, 18, 38, 0.5);
  max-height: 350px;
  overflow-y: scroll;
`;

AgreementForm.defaultValues = {
  clientAgreements: [],
};

AgreementForm.propTypes = {
  clientAgreements: PropTypes.arrayOf(PropTypes.string),
  onSave: PropTypes.func,
};

export default AgreementForm;
