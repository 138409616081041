import styled from 'styled-components';

import { Button as BaseButton } from '~/components/shared/buttons';
import { Body as BaseBody } from '~/components/shared/typography';
import colors from '~/styles/theme/colors';

export const Body = styled(BaseBody).attrs({
  fontSize: '20px',
  lineHeight: '32px',
  textAlign: 'center'
})`
  margin: 40px 0px;
`;

export const Button = styled(BaseButton)`
  height: 64px;
  width: 100%
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0px 50px;
`;

export const Link = styled.a`
  color: ${ colors.primaryBlue };
  text-decoration: none;
`;
