import Classification from '~/models/Classification';
import Group from '~/models/Group';
import { RehabFacility } from '~/models/groups';
import User from '~/models/User';

import GroupType from '../GroupType';
import UserPreference, { UserPreferenceType } from '../UserPreference';

export enum NotificationChannel {
  EMAIL = 'email',
  PUSH = 'push',
}

type NotificationOptions = {
  id: string;
  clientId: string;
  type: UserPreferenceType;
  active: boolean;
  groupTypeApiName: string;
  notificationSelections: Record<string, string | { email: boolean; push: boolean }>;
  createdAt: string | null;
  updatedAt: string | null;
  userId: string;
  groupType: GroupType | null;
  scopes: {
    groups: Group[];
    planTypes: Classification[];
    episodes: Classification[];
    caseManagers: User[];
    utilizationManagers: User[];
  };
};

const defaults: NotificationOptions = {
  id: '',
  clientId: '',
  type: UserPreferenceType.NOTIFICATION,
  active: false,
  groupTypeApiName: '',
  notificationSelections: {},
  createdAt: null,
  updatedAt: null,
  userId: '',
  groupType: null,
  scopes: {
    groups: [],
    planTypes: [],
    episodes: [],
    caseManagers: [],
    utilizationManagers: [],
  },
};

export default class Notification extends UserPreference {
  active: boolean;
  groupTypeApiName: string;
  notificationSelections: Record<string, string | { email: boolean; push: boolean }>;
  createdAt: string | null;
  updatedAt: string | null;
  userId: string;
  groupType: GroupType | null;
  scopes: {
    groups: Group[];
    planTypes: Classification[];
    episodes: Classification[];
    caseManagers: User[];
    utilizationManagers: User[];
  };

  constructor(options: Partial<NotificationOptions> = defaults) {
    const opts = { ...defaults, ...options };

    super(opts);

    this.active = opts.active;
    this.groupTypeApiName = opts.groupTypeApiName;
    this.notificationSelections = opts.notificationSelections;
    this.createdAt = opts.createdAt;
    this.updatedAt = opts.updatedAt;
    this.userId = opts.userId;
    this.groupType = opts.groupType;
    this.scopes = {
      groups: opts.scopes.groups.map((group) => new RehabFacility(group)),
      planTypes: opts.scopes.planTypes.map((planType) => new Classification(planType)),
      episodes: opts.scopes.episodes.map((episodeType) => new Classification(episodeType)),
      caseManagers: opts.scopes.caseManagers.map((caseManager) => new User(caseManager)),
      utilizationManagers: opts.scopes.utilizationManagers.map((caseManager) => new User(caseManager)),
    };
  }

  serialize() {
    return {
      ...super.serialize(),
      active: this.active,
      groupTypeApiName: this.groupTypeApiName,
      notificationSelections: this.notificationSelections,
      userId: this.userId,
      scopes: {
        groups: this.scopes.groups.map((group) => group.id),
        planTypes: this.scopes.planTypes.map((planType) => planType.id),
        episodes: this.scopes.episodes.map((episodeType) => episodeType.id),
        caseManagers: this.scopes.caseManagers.map((caseManager) => caseManager.id),
        utilizationManagers: this.scopes.utilizationManagers.map((caseManager) => caseManager.id),
      },
    };
  }
}
