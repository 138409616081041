
import { createAsyncThunk } from '~/lib';
import { Classification } from '~/models';
import { classificationsApi } from '~/services/api';

export const sliceName = 'classifications';

export const fetchClassifications = createAsyncThunk(
  `${sliceName}/fetch`,
  async (params) => {
    const defaults = { pageSize: 1000, sortBy: 'name asc' };
    const res = await classificationsApi.fetch.invoke({ ...defaults, ...params });

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Classification
  }
);
