import React from 'react';
import styled from 'styled-components';

import { H2 } from '~/components/shared/typography';
import colors from '~/styles/theme/colors';

function FormTitle({ children }) {
  return (
    <TitleContainer>
      <H2 color={colors.primaryBlue}>{children}</H2>
    </TitleContainer>
  );
}

export default FormTitle;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-basis: 100%;
`;
