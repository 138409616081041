import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { getProfile, updateAgreements } from '~/ducks/profile';
import { clientsApi } from '~/services/api/';

import StandardModal from '../shared/modal/StandardModal';

import AgreementForm from './AgreementForm';

const Agreements = ({ action, history, profile }) => {
  const [clientAgreements, setClientAgreements] = useState([]);

  useEffect(() => {
    const fetchClientAgreements = async () => {
      const res = await clientsApi.getAgreements();

      setClientAgreements(res.data);
    };

    fetchClientAgreements();
  }, []);

  useEffect(() => {
    if (profile.acceptedTermsOfService) {
      history.push('/');
    }
  }, [profile]);

  function handleSave(data) {
    action(data);
  }

  return (
    <Container>
      <StandardModal
        show={true}
        showCloseButton={false}
        title='Review the Terms'
        disableBackdropClick
        style={{ margin: 0 }}
        backdropStyles={{ alignItems: 'center' }}>
        <AgreementForm clientAgreements={clientAgreements} onSave={handleSave} />
      </StandardModal>
    </Container>
  );
};

Agreements.propTypes = {
  action: PropTypes.func,
  history: PropTypes.instanceOf(Object),
  profile: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  profile: getProfile(state),
});

export default connect(mapStateToProps, { action: updateAgreements })(Agreements);

const Container = styled.div`
  background: url(https://static-assets.olio.health/images/login-page-background.svg);
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
