import { createBrowserHistory } from 'history';

import { configureStore } from '@reduxjs/toolkit';

import slices from '~/ducks';
import { updateNavigation } from '~/ducks/navigation';

const history = createBrowserHistory();

const store = configureStore({
  reducer: slices,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

let previousPathname = history.location.pathname;

store.dispatch(
  updateNavigation({
    current: history.location.pathname,
    previous: null,
  })
);

history.listen((location) => {
  store.dispatch(
    updateNavigation({
      current: location.pathname,
      previous: previousPathname,
    })
  );

  previousPathname = location.pathname;
});

export { history, store };
