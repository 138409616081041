/* eslint-disable no-nested-ternary */
const sortByKey = (key, order = 'asc') => {
  if (order === 'asc') {
    return (a, b) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0);
  }

  return (a, b) => (a[key] < b[key]) ? 1 : ((b[key] < a[key]) ? -1 : 0);
};

export const sortBy = (array, key, order) => {
  // The empty array concat is here to make sure we are not
  // mutating the array being passed in.
  return [...array].sort(sortByKey(key, order));
};
