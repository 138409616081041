import { Api, ApiRequest } from './core';

export const url = '/me/preferences';

const { fetch } = new Api({ url });

export default {
  fetch,
  update: new ApiRequest({
    formatArgsForConfig: (data) => {
      const { type, clientId } = data;

      return {
        url: `${url}/${type}/${clientId}`,
        data,
      };
    },
    method: 'put',
  }),
};
