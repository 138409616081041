import React from 'react';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';
import { colors } from '~/styles/theme';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <Container>
      <StyledBodySmall>
        &copy; { year } Olio Health | Confidential | &nbsp;
        <Link href='https://policy.olio.health/privacy-policy' target='_blank' rel='noopener noreferrer'>
          Privacy Policy
        </Link>
      </StyledBodySmall>
    </Container>
  );
}

export default Footer;

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: transparent;
  padding: 48px 0 24px 0;
`;

const StyledBodySmall = styled(BodySmall)`
  color: ${ colors.black50 };
`;

export const Link = styled.a`
  color: ${ colors.primaryBlue };
  text-decoration: none;
`;
