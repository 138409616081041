import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RadioButtonChecked, RadioButtonUnchecked } from '~/components/shared/svg';
import { verticalCenter } from '~/styles/mixins';

const RadioButton = ({
  name,
  onChange,
  radioLabel,
  radioValue,
  value
}) => {
  const handleClick = () => {
    if (radioValue !== value) {
      onChange(radioValue);
    }
  };

  return (
    <Root name={ name } onClick={ handleClick }>
      { value && value === radioValue ?
        (<RadioButtonChecked />) :
        (<RadioButtonUnchecked />)
      }
      { radioLabel && (
        <Label>{ radioLabel }</Label>
      )}
    </Root>
  );
};

RadioButton.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  radioLabel: PropTypes.string,
  radioValue: PropTypes.string,
  value: PropTypes.string
};

export default RadioButton;

const Root = styled.div`
  ${ verticalCenter() };
`;

const Label = styled.div`
  margin-left: 8px;
  font-size: 16px;
  color: ${ (props) => props.theme.colors.black50 };
`;
