import styled from 'styled-components';

export const InlineContainer = styled.div`
  display: flex;
  & > * {
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  }
`;
